/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import {
  ShazamBar,
  LoadingAnimation,
  LayoutsModal,
  StencilInfoModal,
  ContextMenu,
  BulkActions,
  PageFooter,
  AssetMapMember,
  AssetMapBox,
  ProposalsModal,
  ProposalBar,
  TraySidebar,
} from '../../components';
import {
  toggleShowStencilInfoModal,
  getMemberInfo,
  toggleShowEntityModal,
  toggleShowMemberModal,
  getInstrumentInfo,
  toggleShowCashFlowModal,
  toggleShowAssetModal,
  toggleShowLiabilityModal,
  toggleShowInsuranceModal,
  createAssetMapReport,
  toggleShowAmrHidden,
  toggleShowAmrLegend,
  toggleShowAmrKffs,
  toggleShowStencilModal,
  clearStencil,
  toggleShowBulkModal,
  toggleShowAssetMapLayoutsModal,
  toggleShowProposalsModal,
  toggleShowAmrMembers,
  toggleShowAmrEntity,
  toggleShowAmrCustodian,
  toggleShowMultiSelectAMR,
  toggleShowEntityRollup,
  toggleShowProposalMode,
  getProposalHouseholdInfo,
  getHouseholdInfo,
  getProposalInstrumentInfo,
  updateHouseholdStoreValue,
  getAssetMapProposals,
  syncHouseholdSummary,
  setBulkSync,
  toggleShowGlassTray,
  getGlassAppList,
  setGlassModalsDisable,
  setUserToggleModal,
  setModalState,
  closeAllInstrumentModals,
  closeAllMemberModals,
  clearInstrumentInfo,
  clearMemberInfo,
  toggleShowLegalModal,
  setMembersLegals,
} from '../../store/actions';
import {
  pdf,
  layouts,
  addStencil,
  removeStencil,
  lightBlueCirclePlus,
  pinkCirclePlus,
  orangeCirclePlus,
  blueCirclePlus,
  plusCircleDark,
  userAdd,
  addEntity,
  toggle,
  stencils,
  hide,
  trashDark,
  copy,
  eyeClosedDark,
  eyeDark,
  multiSelect,
  search,
  timesDarkGrey,
  proposals,
  link,
  sync,
  watermark,
} from '../../assets';
import {
  colors,
  messageColors,
  assetMapColors,
  maxDevice,
  fonts,
  glassTray,
  boxShadows,
} from '../../styles/variables';
import { PageContent } from '../../styles/library/layoutStyles';
import { IconContainer } from '../../styles/library/imageStyles';
import { ShazamContainer } from '../../components/ShazamBar';
import {
  UpdatePageTitle,
  FormatCurrency,
  IsNegative,
  DownloadReport,
  AddInstrumentPriority,
  HtmlToString,
  SetObjectFieldValue,
  InfoNotification,
  SendTrackingEvent,
  ErrorNotification,
  CreateWestLines,
  CreateEastLines,
  GenerateSouthItems,
  GenerateNorthEastItems,
  GenerateNorthItems,
  GenerateSouthEastItems,
  GenerateSouthWestItems,
  GenerateNorthWestItems,
  GenerateRegionTotal,
  FilterInstrumentsByType,
  integrationOptions,
  SubscribeEventListener,
  UnsubscribeEventListener,
  DispatchEventData,
} from '../../utils';
import { Legend } from '../../components';

const HouseholdAssetMap = ({ isConsumer, canEdit = true, showPDF }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const householdId = params.id;
  UpdatePageTitle('Asset-Map');
  const {
    instruments,
    members,
    currency,
    preferences,
    roundingValue,
    householdKffs,
    kff,
    kffFields,
    currentHousehold,
    layoutMembers,
    loadedHouseholdFields,
    entityCategories,
    hasContextMenuEnabled,
    generateAssetMapPDF,
    amrShowMembers,
    amrShowLegend,
    amrShowHidden,
    amrShowKffs,
    amrShowCustodian,
    amrShowEntity,
    userInfo,
    showEntityRollup,
    showGlassTray,
    customLDD,
    sideNavWidth,
    usingNewAvatar,
    showingLayoutsModal,
    showingProposalsModal,
    showingStencilInfoModal,
    showMultiSelectAMR,
    hasEntityRollup,
    hasGlass,
    glassApps,
    hasProposals,
    isPreview,
    showProposalMode,
    currentProposal,
    allInstrumentCategories,
    integrations,
    glassModalsDisabled,
    userToggleModal,
    modalState,
    invocationId,
    showingMemberModal,
    showingEntityModal,
    showingCashFlowModal,
    showingAssetModal,
    showingLiabilityModal,
    showingInsuranceModal,
    hasLegal,
    householdInfoLegals,
  } = useSelector((state) => ({
    instruments: state.households.currentHousehold.instruments,
    currency: state.households.householdCurrency,
    members: state.households.currentHousehold.householdmembers,
    preferences: state.user.user.preferences,
    roundingValue: state.user.user.preferences?.amr?.rounding,
    householdKffs: state.households.householdKffs,
    kff: state.user.user.kff,
    kffFields: state.configs.indexFields,
    currentHousehold: state.households.currentHousehold,
    layoutMembers: state.households.layoutMembers,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
    entityCategories: state.configs.entityCategories,
    hasContextMenuEnabled: state.user.hasContextMenuEnabled,
    generateAssetMapPDF: state.households.generateAssetMapPDF,
    amrShowMembers: state.user.amrShowMembers,
    amrShowLegend: state.user.amrShowLegend,
    amrShowHidden: state.user.amrShowHidden,
    amrShowKffs: state.user.amrShowKffs,
    amrShowCustodian: state.user.amrShowCustodian,
    amrShowEntity: state.user.amrShowEntity,
    userInfo: state.user.user,
    showEntityRollup: state.user?.user?.preferences?.amr?.show_entity_rollup,
    showGlassTray: state.user?.user?.preferences?.amr?.glass,
    customLDD: state.user.customLDD,
    sideNavWidth: state.user.sideNavWidth,
    usingNewAvatar: state.user.usingNewAvatar,
    showingLayoutsModal: state.households.showingLayoutsModal,
    showingProposalsModal: state.households.showingProposalsModal,
    showingStencilInfoModal: state.households.showingStencilInfoModal,
    showMultiSelectAMR: state.user.showMultiSelectAMR,
    hasEntityRollup: state.user.hasEntityRollup,
    hasGlass: state.user.hasGlass,
    glassApps: state.user.glassApps,
    hasProposals: state.user.hasProposals,
    isPreview: state.user.isPreview,
    showProposalMode: state.households.showProposalMode,
    currentProposal: state.households.currentProposal,
    allInstrumentCategories: state.configs.allInstrumentCategories,
    integrations: state.households.currentHousehold.integrations,
    glassModalsDisabled: state.user.glassModalsDisabled,
    userToggleModal: state.user.userToggleModal,
    modalState: state.households.modalState,
    invocationId: state.configs.invocationId,
    showingMemberModal: state.members.showingMemberModal,
    showingEntityModal: state.members.showingEntityModal,
    showingCashFlowModal: state.instruments.showingCashFlowModal,
    showingAssetModal: state.instruments.showingAssetModal,
    showingLiabilityModal: state.instruments.showingLiabilityModal,
    showingInsuranceModal: state.instruments.showingInsuranceModal,
    hasLegal: state.user.hasLegal,
    householdInfoLegals: state.households.householdlegals,
  }));
  const [membersList, setMembersList] = useState([]);
  const [modalInfoText, setModalInfoText] = useState('');
  const [entityList, setEntityList] = useState([]);
  const [loadedMembers, setLoadedMembers] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [instrumentsList, setInstrumentsList] = useState();
  const [visibleInstruments, setVisibleInstruments] = useState();
  const [incomes, setIncomes] = useState([]);
  const [loadedIncomes, setLoadedIncomes] = useState(false);
  const [assets, setAssets] = useState([]);
  const [loadedAssets, setLoadedAssets] = useState(false);
  const [liabilities, setLiabilities] = useState([]);
  const [loadedLiabilities, setLoadedLiabilities] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [loadedInsurances, setLoadedInsurances] = useState(false);
  const [loadedInstruments, setLoadedInstruments] = useState(false);
  const [northWestInstruments, setNorthWestInstruments] = useState([]);
  const [northWestTotal, setNorthWestTotal] = useState(0);
  const [southWestInstruments, setSouthWestInstruments] = useState([]);
  const [southWestTotal, setSouthWestTotal] = useState(0);
  const [northEastInstruments, setNorthEastInstruments] = useState([]);
  const [northEastTotal, setNorthEastTotal] = useState(0);
  const [southEastInstruments, setSouthEastInstruments] = useState([]);
  const [southEastTotal, setSouthEastTotal] = useState(0);
  const [northItems, setNorthItems] = useState([]);
  const [northJointItems, setNorthJointItems] = useState([]);
  const [northLeftItems, setNorthLeftItems] = useState([]);
  const [northRightItems, setNorthRightItems] = useState([]);
  const [southItems, setSouthItems] = useState([]);
  const [southTotal, setSouthTotal] = useState(0);
  const [amrKffs, setAmrKffs] = useState([]);
  const [northWestWide, setNorthWestWide] = useState(false);
  const [southWestWide, setSouthWestWide] = useState(false);
  const [northEastWide, setNorthEastWide] = useState(false);
  const [southEastWide, setSouthEastWide] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const [showSearchView, setShowSearchView] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [hasActiveIntegration, setHasActiveIntegration] = useState(false);
  const [hasReportSignposts, setHasReportSignposts] = useState(false);
  const [trayWidth, setTrayWidth] = useState('0px');
  const [showingAppBar, setShowingAppBar] = useState(false);
  const [glassTrayApps, setGlassTrayApps] = useState([]);
  const [showAppView, setShowAppView] = useState(false);
  const [selectedGlassApp, setSelectedGlassApp] = useState();
  const [activeModalMember, setActiveModalMember] = useState();
  const [selectedLegals, setSelectedLegals] = useState([]);

  const ref = useRef(null);
  const appRef = useCallback((node) => {
    const listenForAppletEvents = (ev) => {
      if (typeof ev.data !== 'object') return;
      if (!('payload' in ev.data)) return;
      if (!('event' in ev.data)) return;
      if (!ev.data?.event.startsWith('glass')) return;
      let sender = ev.data?.payload?.sender;
      console.log(
        `Asset-Map received ${ev.data.event} event from ${sender['name']}`
      );
      if (ev.data?.event === 'glass.assetmap.modals') {
        dispatch(setGlassModalsDisable(ev.data?.payload?.disable));
        bulkClearInstruments();
      }
      if (ev.data?.event === 'glass.assetmap.state') {
        let widget = document.getElementById(`${sender?.key}-widget`);
        if (widget) {
          emitGlassEvent(
            'glass.assetmap.state',
            {
              location: 'AMR',
              householdId: householdId,
            },
            widget.contentWindow
          );
        } else {
          console.warn(`Cannot find applet: ${JSON.stringify(sender)}`);
        }
      }
      if (ev.data?.event === 'glass.modal.open') {
        dispatch(setGlassModalsDisable(false));
        dispatch(setUserToggleModal(true));
        openEditModal(ev, ev.data.payload.item, ev.data.payload.type);
        let widget = document.getElementById(`${sender?.key}-widget`);
        emitGlassEvent('glass.modal.opened', {}, widget.contentWindow);
      }
    };

    if (ref.current) {
      ref.current.removeEventListener('message', listenForAppletEvents);
      dispatch(setGlassModalsDisable(false));
    }

    if (node) {
      window.addEventListener('message', listenForAppletEvents);
    }

    ref.current = node;
  }, []);

  useEffect(() => () => dispatch(setModalState('default')), []);

  const emitGlassEvent = (
    eventType,
    payload = {},
    from = window.parent,
    to = '*'
  ) => {
    from.postMessage(
      {
        event: eventType,
        payload: {
          ...payload,
          ...{
            sender: {
              key: 'assetmap',
              name: 'Asset-Map',
              isApplet: false,
            },
          },
        },
      },
      to
    );
  };

  const sendClicksToApplet = (
    e,
    type = 'household',
    id = householdId,
    stop_propagation = false
  ) => {
    if (modalState === 'docked') {
      return;
    }
    if (stop_propagation) {
      e.stopPropagation();
    }
    if (type === 'household' || type === 'householdmember') {
      bulkClearInstruments();
    }
    if (selectedGlassApp) {
      let applet = document.getElementById(`${selectedGlassApp['key']}-widget`);
      if (applet) {
        emitGlassEvent(
          'glass.assetmap.click',
          {
            item: {
              className: e.target.className,
              id: e.target.id,
              type: e.type,
              offsetY: e.offsetY,
              offsetX: e.offsetX,
            },
            type: type,
            id: id,
            householdId: householdId,
          },
          applet.contentWindow
        );
      }
    }
  };

  useEffect(() => {
    if (selectedGlassApp) {
      if (selectedGlassApp.key === 'assetmap') {
        dispatch(setModalState('docked'));
      } else {
        dispatch(setModalState('default'));
      }
    }
  }, [selectedGlassApp]);

  useEffect(() => {
    if (userToggleModal === false) {
      if (selectedGlassApp) {
        let widget = document.getElementById(
          `${selectedGlassApp['key']}-widget`
        );
        if (widget) {
          emitGlassEvent(
            'glass.modal.closed',
            { type: 'householdmember' },
            widget.contentWindow
          );
        }
      }
    }
    setModalInfoText(
      'Click a member or financial on the map for more detailed information.'
    );
  }, [userToggleModal]);

  const generateChildren = () => {
    let items = [];
    if (hasActiveIntegration) {
      const activeIntegrations = integrationOptions.reduce((acc, int) => {
        if (
          !int?.file_storage &&
          integrations?.partners?.[int.id]?.enabled &&
          integrations?.partners?.[int.id]?.sync?.available
        ) {
          return [
            ...acc,
            {
              label: `Sync ${int.name}`,
              image: sync,
              name: `sync-${int.id}`,
              onClick: () => syncIntegration(int.id, int.name),
            },
          ];
        }
        return acc;
      }, []);
      items = [...activeIntegrations];
      if (activeIntegrations.length) {
        items.unshift({
          label: `Sync All`,
          image: sync,
          isVisible: true,
          name: `sync-all`,
          onClick: () => syncAll(),
        });
      }
    }
    return items;
  };

  const toggleDisplayGlassTray = () => {
    dispatch(toggleShowGlassTray(!showGlassTray));
    DispatchEventData('amp-glass', {
      action: !showGlassTray ? 'show-tray-arrow' : 'hide-tray-arrow',
    });
    glassTrayArrowOnClick(!showGlassTray);
  };

  const contextMenu = {
    heading: 'Actions',
    items: [
      {
        label: 'Generate PDF',
        image: pdf,
        name: 'generate-pdf',
        onClick: () => closeContextMenu(dispatch(createAssetMapReport(true))),
      },
      {
        label: 'Create',
        image: plusCircleDark,
        name: 'create-parent',
        children: [
          {
            label: 'Cash Flow',
            image: lightBlueCirclePlus,
            name: 'create-cash-flow',
            onClick: () => {
              dispatch(clearInstrumentInfo());
              closeContextMenu(
                dispatch(toggleShowCashFlowModal(!glassModalsDisabled, true))
              );
            },
          },
          {
            label: 'Asset',
            image: pinkCirclePlus,
            name: 'create-asset',
            onClick: () => {
              dispatch(clearInstrumentInfo());
              closeContextMenu(
                dispatch(toggleShowAssetModal(!glassModalsDisabled, true))
              );
            },
          },
          {
            label: 'Liability',
            image: orangeCirclePlus,
            name: 'create-liability',
            isVisible: true,
            onClick: () => {
              dispatch(clearInstrumentInfo());
              closeContextMenu(
                dispatch(toggleShowLiabilityModal(!glassModalsDisabled, true))
              );
            },
          },
          {
            label: 'Insurance',
            image: blueCirclePlus,
            name: 'create-insurance',
            onClick: () => {
              dispatch(clearInstrumentInfo());
              closeContextMenu(
                dispatch(toggleShowInsuranceModal(!glassModalsDisabled, true))
              );
            },
          },
          {
            label: 'Member',
            image: userAdd,
            name: 'add-member',
            onClick: () => {
              dispatch(clearMemberInfo());
              closeContextMenu(
                dispatch(toggleShowMemberModal(!glassModalsDisabled))
              );
            },
          },
          {
            label: 'Entity',
            image: addEntity,
            name: 'add-entity',
            onClick: () => {
              dispatch(clearMemberInfo());
              closeContextMenu(
                dispatch(toggleShowEntityModal(!glassModalsDisabled))
              );
            },
          },
        ],
      },
      {
        label: 'Layouts',
        image: layouts,
        name: 'create-layouts',
        onClick: () =>
          closeContextMenu(dispatch(toggleShowAssetMapLayoutsModal(true))),
      },
      {
        label: 'Stencils',
        image: stencils,
        name: 'stencils-parent',
        children: [
          {
            label: 'Apply Stencil',
            image: addStencil,
            name: 'apply-stencil',
            onClick: () =>
              closeContextMenu(dispatch(toggleShowStencilModal(true))),
          },
          {
            label: 'Clear Stencil',
            image: removeStencil,
            name: 'clear-stencil',
            onClick: () =>
              closeContextMenu(dispatch(clearStencil(householdId, isConsumer))),
          },
        ],
      },
      {
        label: 'Drafts',
        image: proposals,
        name: 'open-proposals',
        isHidden: !hasProposals,
        onClick: () =>
          closeContextMenu(dispatch(toggleShowProposalsModal(true))),
      },
      {
        label: 'Toggle Visibility',
        image: toggle,
        name: 'toggle-parent',
        children: [
          {
            label: 'Members',
            hasToggle: true,
            checked: amrShowMembers,
            onClick: () => dispatch(toggleShowAmrMembers(amrShowMembers)),
          },
          {
            label: 'Legend',
            hasToggle: true,
            checked: amrShowLegend,
            name: 'show-legend',
            onClick: () => dispatch(toggleShowAmrLegend(amrShowLegend)),
          },
          {
            label: 'Sidebar',
            hasToggle: true,
            checked: showGlassTray,
            isHidden: !hasGlass,
            name: 'show-glass-tray',
            onClick: () => toggleDisplayGlassTray(),
          },
          {
            label: 'Hidden',
            hasToggle: true,
            checked: amrShowHidden,
            name: 'show-hidden',
            onClick: () => dispatch(toggleShowAmrHidden(amrShowHidden)),
          },
          {
            label: 'KFFs',
            hasToggle: true,
            checked: amrShowKffs,
            name: 'show-kffs',
            onClick: () => dispatch(toggleShowAmrKffs(amrShowKffs)),
          },
          {
            label: 'Entity',
            hasToggle: true,
            checked: amrShowEntity,
            onClick: () => checkCanToggleEntityDisplay(),
          },
          {
            label: 'Entity Ownership',
            hasToggle: true,
            isHidden: !hasEntityRollup,
            checked: showEntityRollup,
            onClick: () => toggleRollupDisplay(),
          },
          {
            label: 'Custodian',
            hasToggle: true,
            checked: amrShowCustodian,
            onClick: () => dispatch(toggleShowAmrCustodian(amrShowCustodian)),
          },
        ],
      },
      {
        label: 'Integrations',
        image: link,
        name: 'integrations-parent',
        isHidden: !hasActiveIntegration,
        children: generateChildren(),
      },
    ],
  };

  const selectedCategories = ['primary', 'spouse', 'partner'];

  useEffect(() => {
    if (preferences && integrations) {
      if (preferences?.amr?.signpost) {
        setHasReportSignposts(
          (preferences?.amr?.signpost.includes('riskalyze') &&
            integrations?.partners?.riskalyze?.enabled) ||
            (preferences?.amr?.signpost.includes('msaws') &&
              integrations?.partners?.msaws?.enabled)
        );
      } else {
        setHasReportSignposts(false);
      }
    }
  }, [preferences, integrations]);

  // TODO possibly remove this
  const handleGlassEvent = (e) => {
    // console.log('handle glass event', e);
  };

  // TODO possibly remove this
  //ADD/REMOVE GLASS EVENT LISTENER
  useEffect(() => {
    if (hasGlass && showGlassTray) {
      SubscribeEventListener('amp-glass', handleGlassEvent);
    } else if (showGlassTray === false) {
      UnsubscribeEventListener('amp-glass', handleGlassEvent);
    }
    return () => {
      UnsubscribeEventListener('amp-glass', handleGlassEvent);
      dispatch(closeAllInstrumentModals());
      dispatch(closeAllMemberModals());
    };
  }, [hasGlass, showGlassTray]);

  //HOOK TO CREATE WEST ASSET-MAP CONNECTOR LINES
  useEffect(() => {
    CreateWestLines(setNorthWestWide, setSouthWestWide);
  }, [
    northWestInstruments,
    southWestInstruments,
    northWestWide,
    southWestWide,
  ]);

  //HOOK TO CREATE EAST ASSET-MAP CONNECTOR LINES
  useEffect(() => {
    CreateEastLines(setNorthEastWide, setSouthEastWide);
  }, [
    northEastInstruments,
    southEastInstruments,
    northEastWide,
    southEastWide,
  ]);

  useEffect(() => {
    if (currentProposal?.id) {
      dispatch(
        getProposalHouseholdInfo(currentHousehold?.uuid, currentProposal?.id)
      );
    }
  }, [currentProposal]);

  useEffect(() => {
    if (showProposalMode) {
      dispatch(getAssetMapProposals(currentHousehold?.uuid, isConsumer));
    }
    return () => {
      if (showProposalMode) {
        dispatch(toggleShowProposalMode(false));
        dispatch(updateHouseholdStoreValue('currentProposal', null));
        dispatch(getHouseholdInfo(householdId));
      }
    };
  }, [showProposalMode]);

  //HOOK TO DETERMINE WHICH MEMBERS TO SELECT IN SHAZAM BAR
  useEffect(() => {
    if (members) {
      const savedMembers = localStorage.getItem('selectedMembers');
      //IF THERE ARE CACHED MEMBERS SET THOSE AS THE SELECTED
      if (savedMembers && !isPreview) {
        const selectedList = savedMembers.split(',');
        if (members.some((mem) => selectedList.includes(mem.id))) {
          setMembersList(
            members.map((member) => {
              member.name = HtmlToString(member.name);
              member.is_selected = selectedList.includes(member.id);
              return member;
            })
          );
          return setLoadedMembers(true);
        }
      }
      //IF NO CACHED MEMBERS SELECT THAT FIRST TWO THAT MEET THE CATEGORIES
      let count = 0;
      setMembersList(
        members.map((member) => {
          member.is_selected = false;
          member.name = HtmlToString(member.name);
          if (selectedCategories.includes(member.category) && count < 2) {
            member.is_selected = true;
            count++;
          }
          return member;
        })
      );
      return setLoadedMembers(true);
    }
  }, [members]);

  //IF APP BAR IS SHOWING - FETCH LIST OF APPS
  useEffect(() => {
    if (showingAppBar) {
      dispatch(getGlassAppList());
      DispatchEventData('amp-glass', { action: 'show-app-list' });
    }
  }, [showingAppBar]);

  //SET APPS TO LIST RETURNED FROM API
  useEffect(() => {
    if (glassApps?.apps?.length) {
      const apps = glassApps?.apps.map((app) => {
        app.value = app.key;
        return app;
      });
      setGlassTrayApps(apps);
    } else {
      setGlassTrayApps([]);
    }
  }, [glassApps]);

  //HOOK TO SET ENTITY LIST FROM HOUSEHOLD MEMBER LIST
  useEffect(() => {
    if (membersList && loadedMembers) {
      setEntityList(membersList.filter((mem) => mem.member_type === 'entity'));
    }
  }, [membersList, loadedMembers]);

  //HOOK TO CREATE LIST OF SELECTED MEMBERS
  useEffect(() => {
    if (membersList && loadedMembers && loadedHouseholdFields) {
      setSelectedMembers(membersList.filter((member) => member.is_selected));
    }
  }, [membersList, loadedMembers, loadedHouseholdFields]);

  useEffect(() => {
    if (showSearchView) {
      const searchQuery = searchValue.toLowerCase().trim();
      const updatedList = visibleInstruments.map((instrument) => {
        const name = instrument.name.toLowerCase();
        const source = instrument.source.toLowerCase();
        instrument.is_selected =
          (name.includes(searchQuery) || source.includes(searchQuery)) &&
          searchQuery !== '';
        return instrument;
      });

      setVisibleInstruments(updatedList);
    }
  }, [showSearchView, searchValue]);

  useEffect(() => {
    if (loadedHouseholdFields) {
      const combinedInstruments = [
        ...northWestInstruments,
        ...southWestInstruments,
        ...northEastInstruments,
        ...southEastInstruments,
        ...northItems,
        ...northJointItems,
        ...northLeftItems,
        ...northRightItems,
        ...southItems,
      ];
      const withChildren = combinedInstruments.reduce((acc, curr) => {
        if (curr.linked_items && curr.linked_items.length !== 0) {
          return [...acc, curr, ...curr.linked_items];
        }
        return [...acc, curr];
      }, []);
      setVisibleInstruments(withChildren);
    }
  }, [
    northWestInstruments,
    southWestInstruments,
    northEastInstruments,
    southEastInstruments,
    northItems,
    northJointItems,
    northLeftItems,
    northRightItems,
    southItems,
  ]);

  useEffect(() => {
    if (layoutMembers && loadedMembers) {
      const selectedMembers = membersList.map((member) => {
        member.name = HtmlToString(member.name);
        if (layoutMembers.includes(member.id)) {
          member.is_selected = true;
        } else {
          member.is_selected = false;
        }
        return member;
      });
      localStorage.setItem('selectedMembers', layoutMembers);
      setMembersList(selectedMembers);
    }
  }, [layoutMembers, loadedMembers]);

  useEffect(() => {
    if (generateAssetMapPDF) {
      let members = selectedMembers.map((member) => member.id);
      if (
        selectedMembers.length === membersList.length &&
        membersList.length > 2
      ) {
        members = 'all';
      }
      if (selectedMembers.length === 0) {
        members = 'none';
      }
      let rollupString = false;
      if (showEntityRollup) {
        const grouped = [
          ...northWestInstruments,
          ...southWestInstruments,
          ...northEastInstruments,
          ...southEastInstruments,
          ...southItems,
        ];

        const rollupValues = grouped.reduce((acc, curr) => {
          if (curr.is_rollup_parent && curr.is_expanded) {
            const entity_id = curr?.entity_id;
            const category = curr?.category;
            const region = curr?.group.toLowerCase();
            const string = `${region}|${entity_id}|${category}`;
            return [...acc, string];
          }
          return acc;
        }, []);
        if (rollupValues?.length !== 0) {
          rollupString = rollupValues.join();
        }
      }
      const toggleValues = {
        show_custodian: amrShowCustodian,
        show_rollup: showEntityRollup,
        show_entity: amrShowEntity,
        show_kff: amrShowKffs,
        show_hidden: amrShowHidden,
        show_propcas: preferences?.amr?.display_property_casualty,
        show_signpost: hasReportSignposts,
      };
      SendTrackingEvent(
        invocationId,
        'click',
        'create_pdf_report',
        'household_amr',
        {
          isConsumer: isConsumer,
        }
      );
      DownloadReport({
        value: 'assetmap',
        childValue: 'assetmap_choices',
        members,
        toggleValues,
        rollup_expand: rollupString,
        proposalId: showProposalMode ? currentProposal?.id : false,
      });
      return dispatch(createAssetMapReport(false));
    }
  }, [generateAssetMapPDF]);

  useEffect(() => {
    if (kff && kff.hasOwnProperty('amr') && kff.amr?.length !== 0) {
      if (householdKffs) {
        if (kffFields) {
          const matchedKffs = kff.amr.map((item) => {
            let obj = {};
            obj.field = item;
            obj.value = householdKffs[item];
            obj.details = kffFields.find((field) => field.fieldName === item);
            return obj;
          });
          setAmrKffs(matchedKffs);
        }
      }
    }
  }, [householdKffs, kff, kffFields]);

  // useEffect(() => {
  //   if (householdSignals) {
  //     console.log('householdSignals', householdSignals);
  //     const signalOptions = householdSignals?.signals.map((signal) => {
  //       const matching = signalLabels.find((ind) => ind.key === signal.name);
  //       return { ...signal, ...matching };
  //     });
  //     signalOptions.sort((a, b) => a.order - b.order);
  //     setSignals(signalOptions);
  //   }
  // }, [householdSignals]);

  useEffect(() => {
    if (instruments && loadedHouseholdFields) {
      setInstrumentsList(instruments);
      setSelectedInstruments([]);
      dispatch(toggleShowMultiSelectAMR(true));
      return () => {
        setSelectedInstruments([]);
        setInstrumentsList(SetObjectFieldValue(instruments, 'is_selected'));
      };
    }
  }, [instruments, loadedHouseholdFields]);

  useEffect(() => {
    if (loadedHouseholdFields) {
      if (showMultiSelectAMR) {
        dispatch(toggleShowAmrHidden(false));
        SendTrackingEvent(
          invocationId,
          'click',
          'multi_select_show_menu',
          'household_amr',
          {
            household: householdId,
          }
        );
      } else {
        dispatch(toggleShowAmrHidden(true));
      }
    }
  }, [loadedHouseholdFields, showMultiSelectAMR]);

  useEffect(() => {
    if (instrumentsList) {
      let filteredInstrumentList = [];
      if (instruments?.length) {
        filteredInstrumentList = [...instruments];
      }
      if (userInfo && userInfo?.preferences && userInfo?.preferences?.amr) {
        if (!userInfo.preferences.amr?.display_property_casualty) {
          filteredInstrumentList = filteredInstrumentList.filter(
            (instrument) => instrument.category !== 'propcas'
          );
        }
      }
      if (!amrShowHidden) {
        filteredInstrumentList = filteredInstrumentList.filter(
          (instrument) => !instrument.is_hidden
        );
      }
      filteredInstrumentList = filteredInstrumentList.map((instrument) => {
        instrument.priority = AddInstrumentPriority(instrument);
        return instrument;
      });
      const incomes = FilterInstrumentsByType(filteredInstrumentList, 'income');
      setIncomes(incomes);
      setLoadedIncomes(true);

      const assets = FilterInstrumentsByType(filteredInstrumentList, 'asset');
      setAssets(assets);
      setLoadedAssets(true);

      const liabilities = FilterInstrumentsByType(
        filteredInstrumentList,
        'liability'
      );
      setLiabilities(liabilities);
      setLoadedLiabilities(true);

      const insurances = FilterInstrumentsByType(
        filteredInstrumentList,
        'insurance'
      );
      setInsurances(insurances);
      setLoadedInsurances(true);
      setLoadedInstruments(true);
    }
  }, [instrumentsList, amrShowHidden, userInfo]);

  useEffect(() => {
    if (loadedInstruments) {
      setSelectedInstruments(
        visibleInstruments.filter((instrument) => instrument.is_selected)
      );
    }
  }, [visibleInstruments]);

  useEffect(() => {
    if (showProposalMode && showMultiSelectAMR) {
      toggleShowBulkActions(true);
    }
  }, [showProposalMode, showMultiSelectAMR]);

  useEffect(() => {
    if (selectedMembers) {
      const northWestItems = GenerateNorthWestItems(
        membersList,
        entityList,
        incomes,
        selectedMembers,
        amrShowEntity,
        showEntityRollup,
        allInstrumentCategories
      );
      setNorthWestInstruments(northWestItems);
      setNorthWestTotal(GenerateRegionTotal(northWestItems, roundingValue));

      const southWestItems = GenerateSouthWestItems(
        membersList,
        entityList,
        assets,
        liabilities,
        insurances,
        selectedMembers,
        amrShowEntity,
        amrShowCustodian,
        showEntityRollup,
        allInstrumentCategories
      );
      setSouthWestInstruments(southWestItems);
      setSouthWestTotal(GenerateRegionTotal(southWestItems, roundingValue));

      const northEastItems = GenerateNorthEastItems(
        entityList,
        incomes,
        selectedMembers,
        amrShowEntity,
        showEntityRollup,
        allInstrumentCategories
      );
      setNorthEastInstruments(northEastItems);
      setNorthEastTotal(GenerateRegionTotal(northEastItems, roundingValue));

      const southEastItems = GenerateSouthEastItems(
        membersList,
        entityList,
        assets,
        liabilities,
        insurances,
        selectedMembers,
        amrShowEntity,
        amrShowCustodian,
        showEntityRollup,
        allInstrumentCategories
      );
      setSouthEastInstruments(southEastItems);
      setSouthEastTotal(GenerateRegionTotal(southEastItems, roundingValue));

      const northItems = GenerateNorthItems(
        membersList,
        entityList,
        insurances,
        selectedMembers,
        amrShowEntity,
        showEntityRollup,
        allInstrumentCategories,
        setNorthJointItems,
        setNorthLeftItems,
        setNorthRightItems
      );
      setNorthItems(northItems);

      const southItems = GenerateSouthItems(
        membersList,
        entityList,
        liabilities,
        assets,
        insurances,
        incomes,
        selectedMembers,
        amrShowEntity,
        amrShowCustodian,
        showEntityRollup,
        allInstrumentCategories
      );
      setSouthItems(southItems);
      setSouthTotal(GenerateRegionTotal(southItems, roundingValue));
    }
  }, [
    incomes,
    assets,
    liabilities,
    insurances,
    selectedMembers,
    amrShowCustodian,
    amrShowEntity,
  ]);

  useEffect(() => {
    if (currentHousehold && integrations) {
      let hasIntegration = false;
      for (let partner in integrations?.partners) {
        let integration = integrations?.partners[partner];
        if (integration.enabled && integration?.sync?.available) {
          hasIntegration = true;
        }
      }
      setHasActiveIntegration(hasIntegration);
    }
  }, [currentHousehold?.id, integrations]);

  const generateSelectedLegals = (legals) => {
    const memberToLegalMap = {};
    Object.values(legals).forEach((legal) => {
      legal.applicable_members.forEach((applicable_member) => {
        if (applicable_member) {
          if (Object.keys(memberToLegalMap).includes(applicable_member.uuid)) {
            memberToLegalMap[applicable_member.uuid].push(legal);
          } else {
            memberToLegalMap[applicable_member.uuid] = [legal];
          }
        }
      });
    });
    let selectedLegals = [];
    selectedMembers.forEach((selectedMember) => {
      if (Object.keys(memberToLegalMap).includes(selectedMember.uuid)) {
        selectedLegals.push(...memberToLegalMap[selectedMember.uuid]);
      }
    });
    selectedLegals = [...new Set(selectedLegals)];
    setSelectedLegals(selectedLegals);
  };

  useEffect(() => {
    if (hasLegal && householdInfoLegals && selectedMembers) {
      generateSelectedLegals(householdInfoLegals);
    }
  }, [hasLegal, householdInfoLegals, selectedMembers]);

  useEffect(() => {
    if (selectedLegals) {
      dispatch(setMembersLegals(selectedLegals));
    }
  }, [selectedLegals]);

  const syncIntegration = (integration, label) => {
    InfoNotification(`Syncing ${label}`);
    SendTrackingEvent(
      invocationId,
      'action',
      'integration_sync',
      'household_amr',
      {
        household: householdId,
        integration: integration,
      }
    );
    return closeContextMenu(
      dispatch(syncHouseholdSummary(householdId, integration))
    );
  };

  const syncAll = () => {
    return closeContextMenu(dispatch(setBulkSync('household_amr')));
  };

  const selectMember = (clickedMember) => {
    toggleShowBulkActions(true);
    const selectedMembers = membersList.map((member) => {
      if (member.id === clickedMember.id) {
        member.is_selected = !member.is_selected;
      }
      return member;
    });
    const selectedIds = selectedMembers.reduce((acc, member) => {
      if (member.is_selected) {
        return [...acc, member.id];
      }
      return acc;
    }, []);
    localStorage.setItem('selectedMembers', selectedIds);
    setMembersList(selectedMembers);
  };

  const clearDockedModals = () => {
    if (showingMemberModal) {
      dispatch(toggleShowMemberModal(false));
    }
    if (showingCashFlowModal) {
      dispatch(toggleShowCashFlowModal(false));
    }
    if (showingAssetModal) {
      dispatch(toggleShowAssetModal(false));
    }
    if (showingLiabilityModal) {
      dispatch(toggleShowLiabilityModal(false));
    }
    if (showingInsuranceModal) {
      dispatch(toggleShowInsuranceModal(false));
    }
    if (showingEntityModal) {
      dispatch(toggleShowEntityModal(false));
    }
  };

  const openEditModal = (e, item, type) => {
    if (modalState === 'docked') {
      clearDockedModals();
    }
    if (instrumentsList && modalState !== 'docked') {
      bulkClearInstruments();
    }
    if (type === 'instrument') {
      if (hasGlass && selectedGlassApp && modalState === 'docked') {
        setActiveModalMember();
      }
      DispatchEventData('amp-glass', {
        action: 'open-instrument',
        instrument: item,
      });
      if (showProposalMode && item.is_proposal) {
        // TODO proposal mode and sidebar glass applet opening a modal use case?
        dispatch(
          getProposalInstrumentInfo(
            currentHousehold.uuid,
            currentProposal?.id,
            item?.proposal_action_id
          )
        );
      } else {
        dispatch(
          getInstrumentInfo(
            currentHousehold.id || householdId,
            item.id,
            isConsumer
          )
        );
      }
      if (item.instrument_type === 'income') {
        return dispatch(toggleShowCashFlowModal(!glassModalsDisabled));
      }
      if (item.instrument_type === 'asset') {
        return dispatch(toggleShowAssetModal(!glassModalsDisabled));
      }
      if (item.instrument_type === 'liability') {
        return dispatch(toggleShowLiabilityModal(!glassModalsDisabled));
      }
      if (item.instrument_type === 'insurance') {
        return dispatch(toggleShowInsuranceModal(!glassModalsDisabled));
      }
    }
    if (type === 'member') {
      if (showProposalMode) {
        ErrorNotification('Cannot edit member in draft mode');
      } else {
        DispatchEventData('amp-glass', {
          action: 'open-member',
          member: item,
        });
        sendClicksToApplet(e, 'householdmember', item.id, true);
        if (hasGlass && selectedGlassApp && modalState === 'docked') {
          setActiveModalMember(item.id);
          bulkClearInstruments(false);
        }
        dispatch(clearMemberInfo());
        dispatch(
          getMemberInfo(currentHousehold.id || householdId, item.id, isConsumer)
        );
        if (item.member_type === 'member') {
          return dispatch(toggleShowMemberModal(!glassModalsDisabled, true));
        }
        if (item.member_type === 'entity') {
          return dispatch(toggleShowEntityModal(!glassModalsDisabled, true));
        }
      }
    }
  };

  const bulkInstrumentsAction = (action) => {
    let instrumentIds = selectedInstruments.map((instrument) => instrument.id);
    instrumentIds = [...new Set(instrumentIds)];
    if (selectedInstruments && selectedInstruments.length > 0) {
      if (action) {
        return dispatch(
          toggleShowBulkModal(
            true,
            'instruments',
            action,
            householdId,
            null,
            instrumentIds
          )
        );
      }
    } else {
      return InfoNotification('No Financials Selected');
    }
  };

  const bulkClearInstruments = (resetMembers = true) => {
    if (instrumentsList) {
      setInstrumentsList(SetObjectFieldValue(instrumentsList, 'is_selected'));
    }
    if (visibleInstruments) {
      setVisibleInstruments(
        SetObjectFieldValue(visibleInstruments, 'is_selected')
      );
    }
    setSelectedInstruments([]);
    if (resetMembers) {
      setActiveModalMember();
    }
  };

  const toggleSelected = (instrument, solo = false) => {
    const updatedList = visibleInstruments.map((i) => {
      if (solo && i.id !== instrument.id) {
        i.is_selected = false;
      }
      if (i.id === instrument.id) {
        i.is_selected = !i.is_selected;
      }
      return i;
    });
    setVisibleInstruments(updatedList);
  };

  const formatItem = (item) => {
    if (item.details.format === 'currencyFormatter') {
      return FormatCurrency(item.value, currency);
    }
    if (item.details.format === 'ratioFormatter') {
      return `${item.value}%`;
    }
    return item.value;
  };

  const toggleRollupLinkedDisplay = (instrument, instrumentList, setFunc) => {
    const updatedInstruments = instrumentList.map((i) => {
      const copied = { ...i };
      if (i.id === instrument.id) {
        copied.is_expanded = !copied.is_expanded;
      }
      return copied;
    });
    return setFunc(updatedInstruments);
  };

  //Toggle open resets when data is changed - household info/instrument/member etc
  //How to know which group to update!
  const assetMapItemOnClick = (e, instrument) => {
    if (hasGlass && selectedGlassApp && glassModalsDisabled) {
      sendClicksToApplet(e, 'instrument', instrument.id, true);
      return toggleSelected(instrument, true);
    }
    if (modalState === 'docked') {
      if (!instrument.is_rollup_parent) {
        toggleSelected(instrument, true);
      }
    }
    if (instrument.is_rollup_parent) {
      if (instrument.group === 'northEast') {
        return toggleRollupLinkedDisplay(
          instrument,
          northEastInstruments,
          setNorthEastInstruments
        );
      }
      if (instrument.group === 'southEast') {
        return toggleRollupLinkedDisplay(
          instrument,
          southEastInstruments,
          setSouthEastInstruments
        );
      }
      if (instrument.group === 'northWest') {
        return toggleRollupLinkedDisplay(
          instrument,
          northWestInstruments,
          setNorthWestInstruments
        );
      }
      if (instrument.group === 'southWest') {
        return toggleRollupLinkedDisplay(
          instrument,
          southWestInstruments,
          setSouthWestInstruments
        );
      }
      if (instrument.group === 'south') {
        return toggleRollupLinkedDisplay(instrument, southItems, setSouthItems);
      }
      if (instrument.group === 'north') {
        return toggleRollupLinkedDisplay(instrument, northItems, setNorthItems);
      }
      if (instrument.group === 'northJoint') {
        return toggleRollupLinkedDisplay(
          instrument,
          northJointItems,
          setNorthJointItems
        );
      }
      if (instrument.group === 'northLeft') {
        return toggleRollupLinkedDisplay(
          instrument,
          northLeftItems,
          setNorthLeftItems
        );
      }
      if (instrument.group === 'northRight') {
        return toggleRollupLinkedDisplay(
          instrument,
          northRightItems,
          setNorthRightItems
        );
      }
    }
    if (showMultiSelectAMR) {
      return toggleSelected(instrument);
    } else {
      dispatch(clearInstrumentInfo());
      return openEditModal(e, instrument, 'instrument');
    }
  };

  const closeContextMenu = (func) => {
    setShowContextMenu(false);
    return func;
  };

  const checkCanToggleEntityDisplay = () => {
    if (hasEntityRollup && showEntityRollup) {
      if (amrShowEntity) {
        return ErrorNotification(
          'Cannot hide entities when showing Entity Ownership'
        );
      }
    }
    return dispatch(toggleShowAmrEntity(amrShowEntity));
  };

  const toggleRollupDisplay = () => {
    if (!showEntityRollup) {
      dispatch(toggleShowAmrEntity(false));
    }
    return dispatch(toggleShowEntityRollup(showEntityRollup));
  };

  const allowContextMenu = () => {
    if (isConsumer) {
      if (canEdit) {
        return hasContextMenuEnabled;
      }
      return false;
    }
    return hasContextMenuEnabled && !showProposalMode;
  };

  const showKffRow = () => {
    if (amrKffs.length !== 0) {
      if (isConsumer) {
        return canEdit && amrShowKffs;
      } else {
        return amrShowKffs;
      }
    }
    return false;
  };

  const bulkActions = [
    {
      value: 'clear',
      label: 'Clear',
      title: 'Clear Selected',
      icon: hide,
      onClick: bulkClearInstruments,
    },
    {
      value: 'trash',
      label: 'Delete',
      title: 'Delete Selected',
      icon: trashDark,
      onClick: () => bulkInstrumentsAction('delete'),
    },
    {
      value: 'copy',
      label: 'Clone',
      title: 'Clone selected',
      icon: copy,
      onClick: () => bulkInstrumentsAction('clone'),
    },
    {
      value: 'hide',
      label: 'Hide',
      title: 'Hide selected',
      icon: eyeClosedDark,
      iconWidth: '15px',
      onClick: () => bulkInstrumentsAction('hide'),
    },
    {
      value: 'show',
      label: 'Unhide',
      title: 'Unhide selected',
      icon: eyeDark,
      iconWidth: '15px',
      onClick: () => bulkInstrumentsAction('show'),
    },
    {
      value: 'search',
      label: 'Search',
      title: 'Search Financials',
      icon: search,
      iconWidth: '15px',
      onClick: () => toggleShowSearchFinancials(),
    },
  ];

  const toggleShowSearchFinancials = () => {
    bulkClearInstruments();
    setShowSearchView(true);
    SendTrackingEvent(
      invocationId,
      'click',
      'multi_select_search',
      'household_amr',
      {
        household: householdId,
      }
    );
  };

  const BulkActionHotkeys = () => {
    useHotkeys('ctrl+shift+s', () => toggleShowBulkActions());
    useHotkeys('esc', () => toggleShowBulkActions(true));
    return <></>;
  };

  const toggleShowBulkActions = (isClose = false) => {
    bulkClearInstruments();
    setShowSearchView(false);
    setSearchValue('');
    if (isClose) {
      dispatch(toggleShowMultiSelectAMR(true));
    } else {
      dispatch(toggleShowMultiSelectAMR(showMultiSelectAMR));
    }
  };

  const closeProposalMode = () => {
    dispatch(toggleShowProposalMode(false));
    dispatch(getHouseholdInfo(householdId));
  };

  const glassTrayArrowOnClick = (isExpand = true) => {
    setShowingAppBar(isExpand);
    setTrayWidth(isExpand ? glassTray.appBarWidth : '0px');
  };

  const glassTrayCloseOnClick = () => {
    if (modalState === 'docked') {
      clearDockedModals();
    }
    dispatch(setModalState('default'));
    bulkClearInstruments();
    setShowAppView(false);
    SendTrackingEvent(
      invocationId,
      'click',
      'sidebar_applet_close',
      'household_amr',
      selectedGlassApp
    );
    setSelectedGlassApp();
    setTrayWidth(glassTray.appBarWidth);
    let eventPayload = {
      sender: {
        name: 'Asset-Map',
        isApplet: false,
      },
      applet: selectedGlassApp['key'],
      disable: false,
      message: 'Yes I will open modals',
    };
    window.parent.postMessage(
      {
        event: 'glass.assetmap.modals',
        payload: eventPayload,
      },
      'http://localhost:8000/ampux/household'
    );
  };

  const glassAppOnClick = (app) => {
    if (instrumentsList && app.key !== 'assetmap') {
      bulkClearInstruments();
      if (modalState === 'docked') {
        clearDockedModals();
      }
    }
    setShowingAppBar(true);

    if (app.key === 'assetmap') {
      setTrayWidth(
        parseInt(glassTray.appBarWidth, 10) +
          parseInt(glassTray.appContentWidthWide, 10) +
          'px'
      );
      app.width = glassTray.appContentWidthWide;
    } else {
      setTrayWidth(
        parseInt(glassTray.appBarWidth, 10) +
          parseInt(glassTray.appContentWidth, 10) +
          'px'
      );
      app.width = glassTray.appContentWidth;
    }

    setShowAppView(true);
    toggleShowBulkActions(true);

    if (app.key !== 'assetmap') {
      let eventPayload = {
        sender: {
          name: 'Asset-Map',
          isApplet: false,
        },
        applet: app.key,
        disable: false,
        message: 'Yes I will open modals',
      };
      window.parent.postMessage(
        {
          event: 'glass.assetmap.modals',
          payload: eventPayload,
        },
        'http://localhost:8000/ampux/household'
      );
    }

    setSelectedGlassApp(app);

    SendTrackingEvent(
      invocationId,
      'click',
      'sidebar_applet_open',
      'household_amr',
      app
    );

    emitGlassEvent('glass.sidebar.open', {
      app: app,
    });
  };

  return (
    <ThemeProvider
      theme={{
        memberLength: selectedMembers?.length,
        northLength: northItems?.length,
        southLength: southItems?.length,
      }}
    >
      {showingStencilInfoModal && (
        <StencilInfoModal
          hide={() =>
            dispatch(toggleShowStencilInfoModal(!showingStencilInfoModal))
          }
        />
      )}
      {showingLayoutsModal && (
        <LayoutsModal
          hide={() =>
            dispatch(toggleShowAssetMapLayoutsModal(!showingLayoutsModal))
          }
          householdId={householdId}
          isConsumer={isConsumer}
          selectedMembers={selectedMembers}
        />
      )}
      {showingProposalsModal && (
        <ProposalsModal
          hide={() =>
            dispatch(toggleShowProposalsModal(!showingProposalsModal))
          }
          householdId={currentHousehold?.uuid}
          isConsumer={isConsumer}
        />
      )}
      {allowContextMenu() && (
        <ContextMenu
          menu={contextMenu}
          sideNavWidth={sideNavWidth}
          containerId={'asset-map-container'}
          showMenu={showContextMenu}
          setShowMenu={setShowContextMenu}
          subMenuWidth={143}
        />
      )}
      <PageContainer
        id="asset-map-container"
        isProposal={showProposalMode}
        trayWidth={trayWidth}
        ref={appRef}
      >
        <PageContent>
          {!showProposalMode && !showAppView && <BulkActionHotkeys />}
          <BulkActions
            show={showMultiSelectAMR}
            count={selectedInstruments.length}
            actions={bulkActions}
            closeMenu={() => toggleShowBulkActions(true)}
            right={trayWidth}
            search={{
              showingSearch: showSearchView,
              goBack: () => setShowSearchView(false),
              searchValue: searchValue,
              searchOnChange: (e) => setSearchValue(e.currentTarget.value),
            }}
          />
          <TopRow>
            {!isConsumer && <OptionPlaceholder />}
            {isConsumer && currentHousehold?.id && <OptionPlaceholder />}
            {amrShowMembers ? (
              <ShazamBar
                items={membersList}
                onClick={selectMember}
                loaded={loadedMembers}
              />
            ) : (
              <ShazamPlaceholder />
            )}
            {currentHousehold?.id && showProposalMode && (
              <OptionContainer>
                <IconContainer
                  onClick={() => dispatch(createAssetMapReport(true))}
                >
                  <img
                    src={pdf}
                    alt="PDF"
                    title="Download Asset-Map as PDF"
                    data-image="amr-pdf"
                  />
                </IconContainer>
              </OptionContainer>
            )}
            {currentHousehold?.id && showPDF && (
              <OptionContainer>
                <IconContainer
                  onClick={() => dispatch(createAssetMapReport(true))}
                >
                  <img
                    src={pdf}
                    alt="PDF"
                    title="Download Asset-Map as PDF"
                    data-image="amr-pdf"
                  />
                </IconContainer>
              </OptionContainer>
            )}
            {!isConsumer && !showProposalMode && !showAppView && (
              <OptionContainer>
                <IconContainer onClick={() => toggleShowBulkActions()}>
                  <img
                    src={showMultiSelectAMR ? timesDarkGrey : multiSelect}
                    alt="select"
                    title={
                      showMultiSelectAMR
                        ? 'Close Search & Select (esc)'
                        : 'Search & Select (ctrl+shift+s)'
                    }
                    data-image={
                      showMultiSelectAMR
                        ? 'heading-select-close'
                        : 'heading-select'
                    }
                    style={
                      showMultiSelectAMR
                        ? { width: '12px', height: '12px' }
                        : null
                    }
                  />
                </IconContainer>
              </OptionContainer>
            )}
          </TopRow>
          {hasLegal && (
            <Legals>
              <LegalsContainer
                onClick={() =>
                  selectedLegals.length > 0 &&
                  dispatch(toggleShowLegalModal(true, false, true))
                }
              >
                <LegalItem>{'Legal'}</LegalItem>
                <LegalItem>
                  {'('}
                  {selectedLegals.length}
                  {')'}
                </LegalItem>
              </LegalsContainer>
            </Legals>
          )}
          {loadedAssets &&
          loadedIncomes &&
          loadedLiabilities &&
          loadedInsurances ? (
            <>
              <AssetMapContainer
                id="asset-map-container"
                onClick={selectedGlassApp ? sendClicksToApplet : undefined}
              >
                <LeftColumnContainer>
                  <LeftInstrumentsContainer>
                    {northWestInstruments &&
                      northWestInstruments.length !== 0 && (
                        <VerticalListContainerLeft
                          id="north-west-container"
                          style={{
                            marginBottom:
                              southWestInstruments &&
                              southWestInstruments.length !== 0
                                ? '20px'
                                : null,
                          }}
                        >
                          {northWestInstruments.map((instrument, index) => {
                            return (
                              <AssetMapBox
                                key={index}
                                index={index}
                                instrument={instrument}
                                direction="vertical"
                                fullWidth={false}
                                side="west"
                                onClick={assetMapItemOnClick}
                                multiSelectOnClick={toggleSelected}
                                currency={currency}
                                preferences={preferences}
                                modalDisabled={glassModalsDisabled}
                              />
                            );
                          })}
                        </VerticalListContainerLeft>
                      )}
                    {southWestInstruments &&
                      southWestInstruments.length !== 0 && (
                        <VerticalListContainerLeft
                          style={{
                            marginTop:
                              northWestInstruments &&
                              northWestInstruments.length !== 0
                                ? '20px'
                                : null,
                          }}
                          id="south-west-container"
                        >
                          {southWestInstruments.map((instrument, index) => {
                            return (
                              <AssetMapBox
                                key={index}
                                index={index}
                                instrument={instrument}
                                direction="vertical"
                                fullWidth={false}
                                side="west"
                                onClick={assetMapItemOnClick}
                                multiSelectOnClick={toggleSelected}
                                currency={currency}
                                preferences={preferences}
                                modalDisabled={glassModalsDisabled}
                              />
                            );
                          })}
                        </VerticalListContainerLeft>
                      )}
                  </LeftInstrumentsContainer>
                  <LinesColumnContainer>
                    <ConnectingLines>
                      {northWestInstruments &&
                        northWestInstruments.length !== 0 && (
                          <NorthWestLineContainer id="north-west-line-container">
                            <NorthWestLine id="north-west-line" />
                          </NorthWestLineContainer>
                        )}
                      {southWestInstruments &&
                        southWestInstruments.length !== 0 && (
                          <SouthWestLineContainer
                            id="south-west-line-container"
                            style={{
                              marginTop:
                                northWestInstruments.length !== 0
                                  ? '20px'
                                  : null,
                            }}
                          >
                            <SouthWestLine id="south-west-line" />
                          </SouthWestLineContainer>
                        )}
                    </ConnectingLines>
                  </LinesColumnContainer>
                </LeftColumnContainer>
                <CenterColumnContainer>
                  <TopContent>
                    {selectedMembers && selectedMembers.length === 2 ? (
                      <NorthItemsContainer>
                        <JointItemsContainer>
                          {northJointItems &&
                            northJointItems.length !== 0 &&
                            northJointItems.map((instrument, index) => {
                              return (
                                <AssetMapBox
                                  key={index}
                                  index={index}
                                  instrument={instrument}
                                  direction="vertical"
                                  fullWidth={true}
                                  side="west"
                                  onClick={assetMapItemOnClick}
                                  multiSelectOnClick={toggleSelected}
                                  currency={currency}
                                  preferences={preferences}
                                  modalDisabled={glassModalsDisabled}
                                />
                              );
                            })}
                        </JointItemsContainer>
                        <SeparateItemsContainer>
                          <LeftItemsContainer>
                            {northLeftItems &&
                              northLeftItems.length !== 0 &&
                              northLeftItems.map((instrument, index) => {
                                return (
                                  <AssetMapBox
                                    key={index}
                                    index={index}
                                    instrument={instrument}
                                    direction="vertical"
                                    fullWidth={false}
                                    side="west"
                                    onClick={assetMapItemOnClick}
                                    multiSelectOnClick={toggleSelected}
                                    currency={currency}
                                    preferences={preferences}
                                    modalDisabled={glassModalsDisabled}
                                  />
                                );
                              })}
                            {(northLeftItems.length !== 0 ||
                              northJointItems.length !== 0) && (
                              <NorthVerticalBar>{''}</NorthVerticalBar>
                            )}
                          </LeftItemsContainer>
                          <RightItemsContainer>
                            {northRightItems &&
                              northRightItems.length !== 0 &&
                              northRightItems.map((instrument, index) => {
                                return (
                                  <AssetMapBox
                                    key={index}
                                    index={index}
                                    instrument={instrument}
                                    direction="vertical"
                                    fullWidth={false}
                                    side="east"
                                    onClick={assetMapItemOnClick}
                                    multiSelectOnClick={toggleSelected}
                                    currency={currency}
                                    preferences={preferences}
                                    modalDisabled={glassModalsDisabled}
                                  />
                                );
                              })}
                            {(northRightItems.length !== 0 ||
                              northJointItems.length !== 0) && (
                              <NorthVerticalBar>{''}</NorthVerticalBar>
                            )}
                          </RightItemsContainer>
                        </SeparateItemsContainer>
                      </NorthItemsContainer>
                    ) : (
                      <>
                        {northItems && northItems.length !== 0 && (
                          <>
                            <HorizontalListContainerTop>
                              {northItems.map((instrument, index) => {
                                return (
                                  <AssetMapBox
                                    key={index}
                                    index={index}
                                    instrument={instrument}
                                    direction="horizontal"
                                    fullWidth={false}
                                    side="west"
                                    onClick={assetMapItemOnClick}
                                    multiSelectOnClick={toggleSelected}
                                    currency={currency}
                                    preferences={preferences}
                                    modalDisabled={glassModalsDisabled}
                                  />
                                );
                              })}
                            </HorizontalListContainerTop>
                            {northItems.length !== 1 &&
                              northItems.length % 3 !== 0 && (
                                <HorizontalTopBracket>
                                  {''}
                                </HorizontalTopBracket>
                              )}
                          </>
                        )}
                      </>
                    )}
                  </TopContent>
                  {/* {northItems &&
                    northItems.length !== 0 &&
                    (northItems.length === 1 ? (
                      <>
                        <VerticalConnectorBottom>{''}</VerticalConnectorBottom>
                        <VerticalConnectorBottom>{''}</VerticalConnectorBottom>
                      </>
                    ) : (
                      <>
                        <VerticalConnectorBottom>{''}</VerticalConnectorBottom>
                        <BottomBracket>{''}</BottomBracket>
                      </>
                    ))} */}
                  {northItems && northItems.length !== 0 && (
                    <>
                      <VerticalConnectorBottom>{''}</VerticalConnectorBottom>
                      <VerticalConnectorBottom>{''}</VerticalConnectorBottom>
                    </>
                  )}
                  <MiddleContent>
                    <LeftLinesContainer>
                      {northWestInstruments &&
                        northWestInstruments.length !== 0 && (
                          <NorthWestTotalLine
                            id="north-west-total-line"
                            style={{
                              width: northWestWide
                                ? '100%'
                                : `calc(100% + 30px)`,
                            }}
                          >
                            <TotalValueLeft
                              style={{
                                color: IsNegative(northWestTotal)
                                  ? messageColors.error
                                  : null,
                              }}
                            >
                              {FormatCurrency(northWestTotal, currency)}/yr
                            </TotalValueLeft>
                            <ArrowIcon />
                          </NorthWestTotalLine>
                        )}
                      {southWestInstruments &&
                        southWestInstruments.length !== 0 && (
                          <SouthWestTotalLine
                            id="south-west-total-line"
                            style={{
                              width: southWestWide
                                ? '100%'
                                : `calc(100% + 30px)`,
                            }}
                          >
                            <TotalValueLeft
                              style={{
                                color: IsNegative(southWestTotal)
                                  ? messageColors.error
                                  : null,
                              }}
                            >
                              {FormatCurrency(southWestTotal, currency)}
                            </TotalValueLeft>
                            <CircleIcon />
                          </SouthWestTotalLine>
                        )}
                    </LeftLinesContainer>
                    <CenterContent>
                      <HorizontalListContainer>
                        {selectedMembers && selectedMembers.length === 2 ? (
                          selectedMembers.map((member, index) => {
                            return (
                              <AssetMapMember
                                key={index}
                                member={member}
                                index={index}
                                preferences={preferences}
                                onClick={openEditModal}
                                categories={entityCategories}
                                usingNewAvatar={usingNewAvatar}
                                activeModalMember={activeModalMember}
                              />
                            );
                          })
                        ) : selectedMembers && selectedMembers.length === 0 ? (
                          <AssetMapMember
                            groupedDisplay={true}
                            groupedType="none"
                            householdName={currentHousehold?.name}
                          />
                        ) : selectedMembers.length === 1 ? (
                          selectedMembers.map((member, index) => {
                            return (
                              <AssetMapMember
                                key={index}
                                member={member}
                                index={index}
                                preferences={preferences}
                                onClick={openEditModal}
                                categories={entityCategories}
                                usingNewAvatar={usingNewAvatar}
                                activeModalMember={activeModalMember}
                              />
                            );
                          })
                        ) : selectedMembers &&
                          selectedMembers.length === membersList.length ? (
                          <AssetMapMember
                            groupedDisplay={true}
                            groupedType="all"
                            householdName={currentHousehold?.name}
                          />
                        ) : (
                          <AssetMapMember
                            groupedDisplay={true}
                            selectedMembers={selectedMembers}
                          />
                        )}
                      </HorizontalListContainer>
                    </CenterContent>
                    <RightLinesContainer>
                      {northEastInstruments &&
                        northEastInstruments.length !== 0 && (
                          <NorthEastTotalLine
                            id="north-east-total-line"
                            style={{
                              width: northEastWide
                                ? '100%'
                                : `calc(100% + 30px)`,
                            }}
                          >
                            <TotalValueRight
                              style={{
                                color: IsNegative(northEastTotal)
                                  ? messageColors.error
                                  : null,
                              }}
                            >
                              {FormatCurrency(northEastTotal, currency)}/yr
                            </TotalValueRight>
                            <ArrowIcon />
                          </NorthEastTotalLine>
                        )}
                      {southEastInstruments &&
                        southEastInstruments.length !== 0 && (
                          <SouthEastTotalLine
                            id="south-east-total-line"
                            style={{
                              width: southEastWide
                                ? '100%'
                                : `calc(100% + 30px)`,
                            }}
                          >
                            <CircleIcon />
                            <TotalValueRight
                              style={{
                                color: IsNegative(southEastTotal)
                                  ? messageColors.error
                                  : null,
                              }}
                            >
                              {FormatCurrency(southEastTotal, currency)}
                            </TotalValueRight>
                          </SouthEastTotalLine>
                        )}
                    </RightLinesContainer>
                  </MiddleContent>
                  {southItems && southItems.length !== 0 && (
                    <>
                      {/* <BottomBracket>{''}</BottomBracket> */}
                      <VerticalConnector>{''}</VerticalConnector>
                      <TotalValueTop>
                        {selectedMembers.length === 2 && (
                          <JointText>JOINT</JointText>
                        )}
                        <p
                          style={{
                            color: IsNegative(southTotal)
                              ? messageColors.error
                              : null,
                          }}
                        >
                          {FormatCurrency(southTotal, currency)}
                        </p>
                      </TotalValueTop>
                      <VerticalConnectorBottom>{''}</VerticalConnectorBottom>
                    </>
                  )}
                  <BottomContent>
                    {southItems && southItems.length !== 0 && (
                      <HorizontalListContainerBottom>
                        {southItems.map((instrument, index) => {
                          return (
                            <AssetMapBox
                              key={index}
                              index={index}
                              instrument={instrument}
                              direction="horizontal"
                              fullWidth={false}
                              side="west"
                              onClick={assetMapItemOnClick}
                              multiSelectOnClick={toggleSelected}
                              currency={currency}
                              preferences={preferences}
                              modalDisabled={glassModalsDisabled}
                            />
                          );
                        })}
                      </HorizontalListContainerBottom>
                    )}
                  </BottomContent>
                </CenterColumnContainer>
                <RightColumnContainer>
                  <LinesColumnContainer>
                    <ConnectingLines style={{ float: 'right' }}>
                      {northEastInstruments &&
                        northEastInstruments.length !== 0 && (
                          <NorthEastLineContainer id="north-east-line-container">
                            <NorthEastLine id="north-east-line" />
                          </NorthEastLineContainer>
                        )}
                      {southEastInstruments &&
                        southEastInstruments.length !== 0 && (
                          <SouthEastLineContainer
                            id="south-east-line-container"
                            style={{
                              marginTop:
                                northEastInstruments.length !== 0
                                  ? '20px'
                                  : null,
                            }}
                          >
                            <SouthEastLine id="south-east-line" />
                          </SouthEastLineContainer>
                        )}
                    </ConnectingLines>
                  </LinesColumnContainer>
                  <RightInstrumentsContainer>
                    {northEastInstruments &&
                      northEastInstruments.length !== 0 && (
                        <VerticalListContainerRight
                          id="north-east-container"
                          style={{
                            marginBottom:
                              southEastInstruments &&
                              southEastInstruments.length !== 0
                                ? '20px'
                                : null,
                          }}
                        >
                          {northEastInstruments.map((instrument, index) => {
                            return (
                              <AssetMapBox
                                key={index}
                                index={index}
                                instrument={instrument}
                                direction="vertical"
                                fullWidth={false}
                                side="east"
                                onClick={assetMapItemOnClick}
                                multiSelectOnClick={toggleSelected}
                                currency={currency}
                                preferences={preferences}
                                modalDisabled={glassModalsDisabled}
                              />
                            );
                          })}
                        </VerticalListContainerRight>
                      )}
                    {southEastInstruments &&
                      southEastInstruments.length !== 0 && (
                        <VerticalListContainerRight
                          id="south-east-container"
                          style={{
                            marginTop:
                              northEastInstruments &&
                              northEastInstruments.length !== 0
                                ? '20px'
                                : null,
                          }}
                        >
                          {southEastInstruments.map((instrument, index) => {
                            return (
                              <AssetMapBox
                                key={index}
                                index={index}
                                instrument={instrument}
                                direction="vertical"
                                fullWidth={false}
                                side="east"
                                onClick={assetMapItemOnClick}
                                multiSelectOnClick={toggleSelected}
                                currency={currency}
                                preferences={preferences}
                                modalDisabled={glassModalsDisabled}
                              />
                            );
                          })}
                        </VerticalListContainerRight>
                      )}
                  </RightInstrumentsContainer>
                </RightColumnContainer>
              </AssetMapContainer>
              {/* {showHouseholdSignals && signals.length !== 0 && (
                <HouseholdSignalsContainer>
                  {signals.map((signal, index) => {
                    return (
                      <SignalContainer key={index}>
                        <Tooltip
                          title={`${GenerateRiskText(signal?.calculated)}: ${
                            signal?.score
                          }`}
                          position="top"
                          trigger="mouseenter click"
                          followCursor="true"
                          delay={0}
                          arrow={true}
                          arrowSize={'small'}
                          animation={'fade'}
                          animateFill={false}
                          transitionFlip={false}
                          size={'small'}
                        >
                          <IconContainer
                            style={{
                              background: DetermineSignalColor(signal?.state),
                            }}
                          >
                            {GenerateSignalIcon(signal?.name, colors.white, {
                              width: '22px',
                              height: '22px',
                            })}
                          </IconContainer>
                        </Tooltip>
                        <SignalName>{signal?.label}</SignalName>
                      </SignalContainer>
                    );
                  })}
                </HouseholdSignalsContainer>
              )} */}
              {showKffRow() && (
                <KFFContainer>
                  <KFFValuesContainer>
                    {amrKffs.map((figure, index) => {
                      return (
                        <FigureContainer
                          key={index}
                          style={
                            index === amrKffs.length - 1
                              ? {
                                  borderRight: 'none',
                                }
                              : null
                          }
                        >
                          <FigureLabel>{figure.details.label}: </FigureLabel>
                          <FigureValue
                            style={{
                              color: IsNegative(figure.value)
                                ? messageColors.error
                                : null,
                            }}
                          >
                            {formatItem(figure)}
                          </FigureValue>
                        </FigureContainer>
                      );
                    })}
                  </KFFValuesContainer>
                  <KFFDisclaimer>
                    These values reflect the entire household&apos;s key
                    financial figures not the selected members.
                  </KFFDisclaimer>
                </KFFContainer>
              )}
              {isConsumer ? (
                <PageFooter
                  footerValue={currentHousehold?.disclaimer}
                  isAMR={true}
                />
              ) : customLDD?.disclaimer ? (
                <PageFooter footerValue={customLDD?.disclaimer} isAMR={true} />
              ) : (
                <PageFooter footerValue={''} isAMR={true} />
              )}
              {showProposalMode && (
                <ProposalBar
                  sideNavWidth={sideNavWidth}
                  amrShowLegend={amrShowLegend}
                  proposal={currentProposal}
                  hide={closeProposalMode}
                  householdId={currentHousehold?.uuid}
                />
              )}
              {amrShowLegend && (
                <Legend trayWidth={trayWidth} context={'amr'} />
              )}
            </>
          ) : (
            <LoadingContainer>
              <LoadingAnimation />
            </LoadingContainer>
          )}
        </PageContent>
      </PageContainer>
      <TraySidebarContainer id="tray-sidebar-container">
        {showGlassTray && hasGlass && !isConsumer && (
          <TraySidebar
            trayWidth={trayWidth}
            showingAppBar={showingAppBar}
            glassTrayApps={glassTrayApps}
            glassAppOnClick={glassAppOnClick}
            selectedGlassApp={selectedGlassApp}
            showAppView={showAppView}
            glassTrayArrowOnClick={glassTrayArrowOnClick}
            glassTrayCloseOnClick={glassTrayCloseOnClick}
            modalState={modalState}
            modalInfoText={modalInfoText}
          />
        )}
      </TraySidebarContainer>
    </ThemeProvider>
  );
};

const TopRow = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  ${ShazamContainer} {
    flex: 1 1 auto;
  }
`;

const ShazamPlaceholder = styled.div`
  flex: 1 1 auto;
`;

const Legals = styled.div`
  display: flex;
  place-content: center;
  margin-top: 20px;
  cursor: pointer;
`;

const LegalsContainer = styled.div`
  border: 1px solid ${colors.lightGrey};
  display: flex;
  border-radius: 30px;
  padding: 7px;
  box-shadow: ${boxShadows.boxShadowBottom};
`;

const LegalItem = styled.div`
  width: 50px;
  font-size: 12px;
  &:first-child {
    text-align: left;
    margin-left: 20px;
  }
  &:last-child {
    text-align: right;
    margin-right: 20px;
  }
`;

const OptionContainer = styled.div`
  flex: 0 0 auto;
  margin-left: 15px;
`;

const OptionPlaceholder = styled.div`
  flex: 0 0 auto;
  width: 35px;
  height: 35px;
  margin: ${(props) => (props.margin ? props.margin : '0 15px 0 0')};
`;

const LeftInstrumentsContainer = styled.div`
  flex: 1 1 180px;
  width: 180px;
  max-width: 180px;
  text-align: left;
  padding-top: 40px;
`;

const RightInstrumentsContainer = styled.div`
  flex: 1 1 180px;
  width: 180px;
  max-width: 180px;
  text-align: right;
  padding-top: 40px;
  /* border: 1px dotted red; */
`;

const LinesColumnContainer = styled.div`
  /* border: 1px dotted cyan; */
  width: 60px;
  position: relative;
  padding-top: 40px;
`;

const ConnectingLines = styled.div`
  width: 30px;
  height: 100%;
  position: relative;
  /* border: 1px dotted purple; */
`;

const NorthWestLineContainer = styled.div`
  position: relative;
  left: 0;
`;

const NorthWestLine = styled.div`
  width: 30px;
  border-top: thin solid ${assetMapColors.connector};
  border-right: thin solid ${assetMapColors.connector};
  position: absolute;
  left: 0;
`;

const SouthWestLineContainer = styled.div`
  position: relative;
  left: 0;
`;

const SouthWestLine = styled.div`
  width: 30px;
  border-bottom: thin solid ${assetMapColors.connector};
  border-right: thin solid ${assetMapColors.connector};
  position: absolute;
  left: 0;
`;

const NorthEastLineContainer = styled.div`
  position: relative;
  right: 0;
`;

const NorthEastLine = styled.div`
  width: 30px;
  border-top: thin solid ${assetMapColors.connector};
  border-left: thin solid ${assetMapColors.connector};
  position: absolute;
  right: 0;
`;

const SouthEastLineContainer = styled.div`
  position: relative;
  right: 0;
`;

const SouthEastLine = styled.div`
  width: 30px;
  border-bottom: thin solid ${assetMapColors.connector};
  border-left: thin solid ${assetMapColors.connector};
  position: absolute;
  right: 0;
`;

// const BottomBracket = styled.div`
//   width: ${(props) => (props.theme.memberLength === 2 ? '150px' : '100px')};
//   /* width: 150px; */
//   height: 1px;
//   border-top: thin solid ${assetMapColors.connector};
//   margin: 0 auto;
// `;

const VerticalConnector = styled.div`
  height: 15px;
  margin: 0 auto;
  position: relative;
  top: -1px;
  left: -1px;
  border-right: thin solid ${assetMapColors.connector};
`;

const TotalValue = styled.div`
  font-weight: ${fonts.semiBold};
  font-size: 13px;
  position: absolute;
`;

const ArrowIcon = styled.span`
  width: 0;
  height: 0;
  position: absolute;
  top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
`;

const CircleIcon = styled.span`
  background: ${assetMapColors.connector};
  position: absolute;
  bottom: -4px;
  height: 8px;
  width: 8px;
  border-radius: 100px;
`;

const TotalValueLeft = styled(TotalValue)`
  right: 10px;
`;

const TotalValueRight = styled(TotalValue)`
  left: 10px;
`;

const LeftLinesContainer = styled.div`
  /* border: 1px dotted pink; */
  height: 100%;
  position: relative;
  flex: 1 1 auto;
`;

const NorthWestTotalLine = styled.div`
  border-bottom: thin solid ${assetMapColors.connector};
  position: absolute;
  right: 0;
  width: calc(100% + 30px);
  transform: translateY(-40%);
  top: 40%;
  ${TotalValueLeft} {
    bottom: 0;
  }
  ${ArrowIcon} {
    border-left: 8px solid ${assetMapColors.connector};
    right: -4px;
  }
`;

const SouthWestTotalLine = styled.div`
  border-top: thin solid ${assetMapColors.connector};
  position: absolute;
  width: calc(100% + 30px);
  right: 0;
  transform: translateY(40%);
  bottom: 40%;
  ${CircleIcon} {
    right: -4px;
  }
`;

const RightLinesContainer = styled.div`
  /* border: 1px dotted blue; */
  height: 100%;
  position: relative;
  flex: 1 1 auto;
`;

const NorthEastTotalLine = styled.div`
  border-bottom: thin solid ${assetMapColors.connector};
  position: absolute;
  width: calc(100% + 30px);
  left: 0;
  transform: translateY(-40%);
  top: 40%;
  ${TotalValueRight} {
    bottom: 0;
  }
  ${ArrowIcon} {
    border-right: 8px solid ${assetMapColors.connector};
    left: -4px;
  }
`;

const SouthEastTotalLine = styled.div`
  border-top: thin solid ${assetMapColors.connector};
  position: absolute;
  width: calc(100% + 30px);
  left: 0;
  transform: translateY(40%);
  bottom: 40%;
  ${CircleIcon} {
    left: -4px;
  }
`;

const NorthVerticalBar = styled.div`
  min-height: 15px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  flex: 1 1 auto;
  /* top: -1px;
  left: -1px; */
  border-right: thin solid ${assetMapColors.connector};
`;

const VerticalConnectorBottom = styled(VerticalConnector)`
  height: 15px;
  margin: 0 auto;
  top: 0px;
`;

const HorizontalTopBracket = styled.div`
  width: ${(props) =>
    props.theme.northLength % 3 === 1
      ? '170px'
      : props.theme.northLength % 3 === 2
        ? '325px'
        : null};
  height: 0px;
  margin: 0 auto;
  border-top: thin solid ${assetMapColors.connector};
  top: 0px;
  position: relative;
  &:before {
    border-right: thin solid ${assetMapColors.connector};
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 15px;
  }
  &:after {
    border-left: thin solid ${assetMapColors.connector};
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 15px;
  }
`;

const TotalValueTop = styled.div`
  font-weight: ${fonts.semiBold};
  font-size: 13px;
  padding: 5px 0;
  text-align: center;
`;

const JointText = styled.p`
  font-weight: ${fonts.regular};
`;

const KFFContainer = styled.div`
  /* @media ${maxDevice.laptopAlt} {
    margin-bottom: 120px;
  }
  @media ${maxDevice.tablet} {
    margin-bottom: 130px;
  }
  @media ${maxDevice.mobileL} {
    margin-bottom: 180px;
  } */
  margin-bottom: 50px;
`;

const KFFValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const KFFDisclaimer = styled.p`
  font-size: 11px;
  color: ${colors.paleGrey};
  text-align: center;
`;

const FigureContainer = styled.div`
  margin: 5px 0;
  padding: 0 12px;
  font-size: 12px;
  border-right: thin solid ${colors.paleGrey};
`;

const FigureLabel = styled.span`
  font-weight: ${fonts.semiBold};
`;

const FigureValue = styled.span`
  font-weight: ${fonts.regular};
`;

const NorthItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 300px;
`;

const JointItemsContainer = styled.div`
  width: 100%;
  /* margin-bottom: 5px; */
  flex: 0 0 auto;
`;

const SeparateItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  /* align-content: center;
  align-items: flex-start;
  justify-content: center; */
`;

const LeftItemsContainer = styled.div`
  flex: 1 1 auto;
  width: 152px;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
`;

const RightItemsContainer = styled.div`
  flex: 1 1 auto;
  width: 152px;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-start;
`;

// const MembersContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   align-content: center;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   margin-top: 5px;
// `;

// const MemberItem = styled.div`
//   width: 132px;
//   min-width: 132px;
//   max-width: 132px;
//   margin: 5px;
// `;

const HorizontalListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
`;

const HorizontalListContainerBottom = styled(HorizontalListContainer)`
  padding: ${(props) =>
    props.theme.southLength === 1
      ? '0 10px 0 10px'
      : props.theme.southLength === 2
        ? '15px 10px 0 10px'
        : '15px 0 0 0'};
  border-top: ${(props) =>
    props.theme.southLength === 1
      ? null
      : `thin solid ${assetMapColors.connector}`};
  position: relative;
  &:before {
    border-right: ${(props) =>
      props.theme.southLength === 1
        ? null
        : `thin solid ${assetMapColors.connector}`};
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
  }
  &:after {
    border-left: ${(props) =>
      props.theme.southLength === 1
        ? null
        : `thin solid ${assetMapColors.connector}`};
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 15px;
  }
`;

const HorizontalListContainerTop = styled(HorizontalListContainer)`
  border-bottom: ${(props) =>
    props.theme.northLength === 1 || props.theme.northLength % 3 !== 0
      ? null
      : `thin solid ${assetMapColors.connector}`};
  padding: ${(props) =>
    props.theme.northLength === 1
      ? '0 10px 0 10px'
      : props.theme.northLength === 2
        ? '0 10px 15px 10px'
        : '0 0 15px 0'};
  /* padding: 0 10px 20px 10px; */
  position: relative;
  &:before {
    border-right: ${(props) =>
      props.theme.northLength === 1 || props.theme.northLength % 3 !== 0
        ? null
        : `thin solid ${assetMapColors.connector}`};
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 15px;
  }
  &:after {
    border-left: ${(props) =>
      props.theme.northLength === 1 || props.theme.northLength % 3 !== 0
        ? null
        : `thin solid ${assetMapColors.connector}`};
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 15px;
  }
`;

const VerticalListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
`;

const VerticalListContainerLeft = styled(VerticalListContainer)`
  border-right: thin solid ${assetMapColors.connector};
  padding: 15px 0;
  position: relative;
  /* padding-right: 0px; */
  &:before {
    border-bottom: thin solid ${assetMapColors.connector};
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
  }
  &:after {
    border-bottom: thin solid ${assetMapColors.connector};
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 15px;
  }
`;

const VerticalListContainerRight = styled(VerticalListContainer)`
  border-left: thin solid ${assetMapColors.connector};
  padding: 15px 0;
  position: relative;
  /* padding-left: 25px; */
  &:before {
    border-bottom: thin solid ${assetMapColors.connector};
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
  }
  &:after {
    border-bottom: thin solid ${assetMapColors.connector};
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 15px;
  }
`;

// const AssetMapItemRealEstateInside = styled.div`
//   position: absolute;
//   padding: 7px 1px 2px 3px;
//   top: 1.5px;
//   left: 1.5px;
//   right: 1.5px;
//   bottom: 1.5px;
//   background: white;
//   clip-path: polygon(50% 0%, 100% 20%, 100% 100%, 0 100%, 0 20%);
// `;

const AssetMapContainer = styled.div`
  @media ${maxDevice.laptopAlt} {
    padding-bottom: 100px;
  }
  @media ${maxDevice.tablet} {
    padding-bottom: 120px;
  }
  @media ${maxDevice.mobileL} {
    padding-bottom: 130px;
  }
  margin-top: 15px;
  display: flex;
  /* align-content: center;
  align-items: stretch;
  justify-content: center; */
  padding-bottom: 80px;
  min-width: 1050px;
  /* border: 1px dotted red; */
`;

const LeftColumnContainer = styled.div`
  display: flex;
  /* flex: 1 1 180px;
  width: 180px;
  max-width: 180px;
  text-align: left;
  padding-top: 40px;
  height: 100%; */
  /* border: 1px dotted green; */
  height: 100%;
`;

const CenterColumnContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  /* border: 1px dotted blue; */
`;

const RightColumnContainer = styled.div`
  /* flex: 1 1 180px;
  width: 180px;
  max-width: 180px;
  text-align: right;
  padding-top: 40px; */
  height: 100%;
  display: flex;
  /* border: 1px dotted orange; */
`;

const TopContent = styled.div`
  max-width: 490px;
  margin: 0 30px 0 30px;
  /* border: 1px dotted pink; */
`;

const MiddleContent = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* border: 1px dotted brown; */
  height: 170px;
  padding: 10px 0;
  margin: 10px 0;
`;

const CenterContent = styled.div`
  flex: 0 1 auto;
  /* border: 1px dotted black; */
  margin: 0 30px;
  height: 100%;
`;

const BottomContent = styled.div`
  /* border: 1px dotted gold; */
  /* max-width: 475px; */
  max-width: 490px;
  margin: 0 30px 0 30px;
`;

// const HouseholdSignalsContainer = styled.div`
//   display: flex;
//   align-content: center;
//   align-items: flex-start;
//   justify-content: center;
//   margin-bottom: 45px;
// `;

// const SignalContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-content: center;
//   align-items: center;
//   justify-content: center;
//   margin: 0 10px;
//   width: 65px;
// `;

// const IconContainer = styled.div`
//   display: flex;
//   align-content: center;
//   align-items: center;
//   justify-content: center;
//   border-radius: 50px;
//   width: 45px;
//   height: 45px;
//   flex: 0 0 auto;
// `;

// const SignalName = styled.p`
//   margin-top: 5px;
//   font-size: 12px;
//   text-align: center;
//   max-width: 75px;
//   color: ${colors.paleGrey};
// `;

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  height: 100%;
  min-height: 500px;
  flex: 1 1 auto;
  width: ${(props) => `calc(100% - ${props.trayWidth})`};
  background-repeat: ${(props) => (props.isProposal ? 'repeat' : null)};
  background-image: ${(props) =>
    props.isProposal ? `url(${watermark})` : null};
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const TraySidebarContainer = styled.div``;

HouseholdAssetMap.propTypes = {
  isConsumer: PropTypes.bool,
  canEdit: PropTypes.bool,
  showPDF: PropTypes.bool,
};

export default HouseholdAssetMap;
