/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PageHeader, EmptyImage } from '../../components';
import { notePad, noReports } from '../../assets';
import {
  getMemberInfo,
  toggleShowEntityModal,
  toggleShowMemberModal,
  getInstrumentInfo,
  toggleShowCashFlowModal,
  toggleShowAssetModal,
  toggleShowLiabilityModal,
  toggleShowInsuranceModal,
  getHouseholdNotes,
  getTargetMapInfo,
  toggleShowTargetMapNotes,
  toggleShowProfessionalModal,
  getLegal,
  toggleShowLegalModal,
} from '../../store/actions';
import { getProfessionalInfo } from '../../store/actions/members';
import { maxDevice, colors, fonts } from '../../styles/variables';
import { PageContent } from '../../styles/library/layoutStyles';
import {
  UpdatePageTitle,
  headerContent,
  DownloadReport,
  HtmlToString,
  DateDiff,
} from '../../utils';

const HouseholdNotes = ({ isConsumer }) => {
  UpdatePageTitle('Notes');
  const dispatch = useDispatch();
  const {
    currentHousehold,
    members,
    instruments,
    targetMaps,
    allMemberCategories,
    loadedHouseholdFields,
    householdNotes,
    loadedHouseholdNotes,
    isStencil,
    showTargetMaps,
    professionals,
    hasLegal,
    legals,
    legalCategories,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    members: state.households.currentHousehold.allHouseholdMembers,
    instruments: state.households.currentHousehold.instruments,
    targetMaps: state.targetMaps.targetMaps,
    allMemberCategories: state.configs.allMemberCategories,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
    householdNotes: state.households.householdNotes,
    loadedHouseholdNotes: state.households.loadedHouseholdNotes,
    isStencil: state.households.isStencil,
    showTargetMaps: state.user.showTargetMaps,
    professionals: state.households.currentHousehold.householdprofessionals,
    hasLegal: state.user.hasLegal,
    legals: state.households.currentHousehold.householdlegals,
    legalCategories: state.configs.legalCategories,
  }));
  const [memberNotes, setMemberNotes] = useState([]);
  const [instrumentNotes, setInstrumentNotes] = useState([]);
  const [targetMapNotes, setTargetMapNotes] = useState([]);
  const [loadedMemberNotes, setLoadedMemberNotes] = useState(false);
  const [loadedInstrumentNotes, setLoadedInstrumentNotes] = useState(false);
  const [loadedTargetMapNotes, setLoadedTargetMapNotes] = useState(false);
  const [legalNotes, setLegalNotes] = useState([]);
  const [loadedLegalNotes, setLoadedLegalNotes] = useState(false);
  const [professionalIds, setProfessionalIds] = useState([]);

  useEffect(() => {
    if (
      householdNotes &&
      members &&
      instruments &&
      loadedHouseholdFields &&
      professionals
    ) {
      const memberNotes = householdNotes.member.filter(
        (note) => note?.content !== ''
      );
      const matchedMembers = memberNotes.reduce((acc, note) => {
        const member = members.find((mem) => mem.id === note.id);
        if (member) {
          note.member = member;
          note.member.name = HtmlToString(member?.name);
          return [...acc, note];
        }
        return acc;
      }, []);

      const proNotes = householdNotes?.professional?.filter(
        (note) => note?.content !== ''
      );
      const matchedProfessionals = proNotes?.reduce((acc, note) => {
        const pro = professionals.find((pro) => pro.id === note.id);
        if (pro) {
          note.member = pro;
          note.member.name = HtmlToString(
            pro?.business_name
          );
          return [...acc, note];
        }
        return acc;
      }, []);

      setMemberNotes([...matchedMembers, ...(matchedProfessionals || [])]);
      setLoadedMemberNotes(true);

      const pros = professionals.map((pro) => pro.id);
      setProfessionalIds(pros);

      const instrumentNotes = householdNotes.instrument.filter(
        (note) => note?.content !== ''
      );
      const matchedInstruments = instrumentNotes.reduce((acc, note) => {
        const instrument = instruments.find(
          (instrument) => instrument.id === note.id
        );
        if (
          instrument &&
          !instrument?.is_hidden
          // && instrument?.status === 'current'
        ) {
          note.instrument = instrument;
          return [...acc, note];
        }
        return acc;
      }, []);
      setInstrumentNotes(matchedInstruments);
      setLoadedInstrumentNotes(true);
    }
  }, [
    householdNotes,
    members,
    instruments,
    loadedHouseholdFields,
    professionals,
  ]);

  useEffect(() => {
    if (householdNotes && legals && hasLegal) {
      const legalNotes = householdNotes.legal.filter(
        (note) => note?.content !== ''
      );
      const matchedLegals = legalNotes.reduce((acc, note) => {
        const legal = legals.find((legal) => legal.id === note.id);
        if (legal) {
          note.legal = legal;
          return [...acc, note];
        }
        return acc;
      }, []);
      setLegalNotes(matchedLegals);
      setLoadedLegalNotes(true);
    }
  }, [householdNotes, legals, hasLegal]);

  useEffect(() => {
    if (householdNotes && showTargetMaps && targetMaps) {
      const targetMapNotes = householdNotes?.targetmap.filter(
        (note) => note?.content !== ''
      );

      const matchedTargetMaps = targetMapNotes.reduce((acc, note) => {
        const targetMap = targetMaps.find(
          (targetMap) => targetMap.id === note.id
        );
        if (targetMap) {
          note.targetMap = targetMap;
          return [...acc, note];
        }
        return acc;
      }, []);

      setTargetMapNotes(matchedTargetMaps);
      setLoadedTargetMapNotes(true);
    }
  }, [householdNotes, showTargetMaps, targetMaps]);

  useEffect(() => {
    if (currentHousehold?.id) {
      dispatch(getHouseholdNotes(currentHousehold.id));
    }
  }, [currentHousehold]);

  // useEffect(() => {
  //   if (currentTargetMap && clickedNotes) {
  //     dispatch(toggleShowTargetMapNotes(true));
  //     setClickedNotes(false);
  //   }
  // }, [currentTargetMap]);

  const editItem = (item, editType) => {
    if (editType === 'instrument') {
      dispatch(getInstrumentInfo(currentHousehold.id, item.id, isConsumer));
      if (item.instrument_type === 'income') {
        return dispatch(toggleShowCashFlowModal(true, false, 'notes'));
      }
      if (item.instrument_type === 'asset') {
        return dispatch(toggleShowAssetModal(true, false, 'notes'));
      }
      if (item.instrument_type === 'liability') {
        return dispatch(toggleShowLiabilityModal(true, false, 'notes'));
      }
      if (item.instrument_type === 'insurance') {
        return dispatch(toggleShowInsuranceModal(true, false, 'notes'));
      }
    }
    if (editType === 'member') {
      if (professionalIds.includes(item?.id)) {
        dispatch(getProfessionalInfo(currentHousehold.id, item.id));
        dispatch(toggleShowProfessionalModal(true, true, 'notes'));
      } else {
        dispatch(getMemberInfo(currentHousehold.id, item.id, isConsumer));
        if (item.member_type === 'member') {
          return dispatch(
            toggleShowMemberModal(true, true, 'notes', !item.is_active)
          );
        }
        if (item.member_type === 'entity') {
          return dispatch(
            toggleShowEntityModal(true, true, 'notes', !item.is_active)
          );
        }
      }
    }
    if (editType === 'targetmap') {
      dispatch(getTargetMapInfo(currentHousehold.id, item.targetMap.id));
      dispatch(toggleShowTargetMapNotes(true, true, item.content));
    }
    if (editType === 'legal') {
      dispatch(getLegal(currentHousehold.id, item.id));
      dispatch(toggleShowLegalModal(true));
    }
  };

  const createAge = (note) => {
    if (note?.member) {
      if (note.member.member_type === 'member') {
        return (
          <MemberAge>{`(${
            note.member?.age
              ? note.member?.age
              : DateDiff(undefined, note.member?.date_of_birth)
          })`}</MemberAge>
        );
      }
    }
    return '';
  };

  const generateCategory = (category) => {
    if (allMemberCategories) {
      const matching = allMemberCategories.find(
        (cat) => cat.value === category
      );
      if (matching) {
        return matching?.label;
      }
    }
    return category;
  };

  // const generateLoadingPlaceholder = (value, isLoading) => {
  //   return (
  //     <NoteContainer>
  //       <NameContainer>
  //         <Name>{isLoading ? `Loading ${value}` : `No ${value}s`}</Name>
  //         <Category>{isLoading ? `Loading` : `None`}</Category>
  //       </NameContainer>
  //       <NoteValue>{isLoading ? `Loading Notes` : `No Notes`}</NoteValue>
  //     </NoteContainer>
  //   );
  // };

  const transformTargetMapType = (type) => {
    if (!type) {
      return 'None';
    }
    switch (type) {
      case 'death':
        return 'Loss of Life';
      case 'disability':
        return 'Long-term Disability';
      case 'ltc':
        return 'Long-term Care';
      default:
        return type.charAt(0).toUpperCase() + type.slice(1);
    }
  };

  const generateLoadingPlaceholder = () => {
    return (
      <NoteContainer>
        <NameContainer loading={'true'}>
          <Name />
          <Category />
        </NameContainer>
        <NoteValue>{`Loading...`}</NoteValue>
      </NoteContainer>
    );
  };

  const generateEmptyDisplay = () => {
    return (
      <NoteContainer>
        <NoteValue>{`No Notes`}</NoteValue>
      </NoteContainer>
    );
  };

  // const generateNoteCard = (note, index) => {
  //   return (
  //     <NoteCardContainer key={index}>
  //       <NameContainer>
  //         <Name onClick={() => editItem(note.member, 'member')}>
  //           <span
  //             dangerouslySetInnerHTML={{
  //               __html: note.member.name,
  //             }}
  //           />
  //           {createAge(note)}
  //         </Name>
  //         <Category>{generateCategory(note.member.category)}</Category>
  //       </NameContainer>
  //       <NoteValue
  //         onClick={() => editItem(note.member, 'member')}
  //         dangerouslySetInnerHTML={{ __html: note.content }}
  //       />
  //     </NoteCardContainer>
  //   );
  // };

  return (
    <PageContainer>
      <PageHeader
        content={{ ...headerContent.notes, showButton: !isStencil }}
        buttonFunction={() =>
          DownloadReport({ value: 'notes', hasChildren: false })
        }
      />
      {isStencil ? (
        <>
          <EmptyImage
            imageSrc={noReports}
            imageName="No Reports"
            text="Reports are not available for Stencil Households"
          />
        </>
      ) : (
        <PageContent>
          {/* <GroupContainer>
            <GroupHeading>Members</GroupHeading>
            {loadedHouseholdNotes && loadedMemberNotes ? (
              <NoteCardsList>
                {memberNotes && memberNotes.length !== 0
                  ? memberNotes.map((note, index) => {
                      return generateNoteCard(note, index);
                    })
                  : generateLoadingPlaceholder('Members', false)}
              </NoteCardsList>
            ) : (
              generateLoadingPlaceholder('Members', true)
            )}
          </GroupContainer> */}
          <GroupContainer>
            <GroupHeading>Members</GroupHeading>
            {loadedHouseholdNotes && loadedMemberNotes ? (
              <NotesListContainer>
                {memberNotes && memberNotes.length !== 0
                  ? memberNotes.map((note, index) => {
                      return (
                        <NoteContainer key={index}>
                          <NameContainer>
                            <Name
                              onClick={() => editItem(note.member, 'member')}
                            >
                              <span>{note?.member?.name}</span>
                              {createAge(note)}
                            </Name>
                            <Category>
                              {generateCategory(note.member.category)}
                            </Category>
                          </NameContainer>
                          <NoteValue
                            onClick={() => editItem(note.member, 'member')}
                            dangerouslySetInnerHTML={{ __html: note.content }}
                          />
                        </NoteContainer>
                      );
                    })
                  : generateEmptyDisplay()}
              </NotesListContainer>
            ) : (
              generateLoadingPlaceholder()
            )}
          </GroupContainer>
          <GroupContainer>
            <GroupHeading>Financials</GroupHeading>
            {loadedHouseholdNotes && loadedInstrumentNotes ? (
              <NotesListContainer>
                {instrumentNotes && instrumentNotes.length !== 0
                  ? instrumentNotes.map((note, index) => {
                      return (
                        <NoteContainer key={index}>
                          <NameContainer>
                            <Name
                              onClick={() =>
                                editItem(note.instrument, 'instrument')
                              }
                            >
                              {note.instrument.name}
                            </Name>
                            <Category>{note.instrument.source}</Category>
                          </NameContainer>
                          <NoteValue
                            onClick={() =>
                              editItem(note.instrument, 'instrument')
                            }
                            dangerouslySetInnerHTML={{ __html: note.content }}
                          />
                        </NoteContainer>
                      );
                    })
                  : generateEmptyDisplay()}
              </NotesListContainer>
            ) : (
              generateLoadingPlaceholder()
            )}
          </GroupContainer>
          {hasLegal && legalCategories && (
            <GroupContainer>
              <GroupHeading>Legals</GroupHeading>
              {loadedHouseholdNotes && loadedLegalNotes ? (
                <NotesListContainer>
                  {loadedLegalNotes && legalNotes.length !== 0
                    ? legalNotes.map((note, index) => {
                        return (
                          <NoteContainer key={index}>
                            <NameContainer>
                              <Name
                                onClick={() => editItem(note.legal, 'legal')}
                              >
                                {note.legal.reference_name}
                              </Name>
                              <Category>
                                {legalCategories[note.legal.category].name}
                              </Category>
                            </NameContainer>
                            <NoteValue
                              onClick={() => editItem(note.legal, 'legal')}
                              dangerouslySetInnerHTML={{ __html: note.content }}
                            />
                          </NoteContainer>
                        );
                      })
                    : generateEmptyDisplay()}
                </NotesListContainer>
              ) : (
                generateLoadingPlaceholder()
              )}
            </GroupContainer>
          )}
          {showTargetMaps && (
            <GroupContainer>
              <GroupHeading>Target-Maps</GroupHeading>
              {loadedHouseholdNotes && loadedTargetMapNotes ? (
                <NotesListContainer>
                  {targetMapNotes && targetMapNotes.length !== 0
                    ? targetMapNotes.map((note, index) => {
                        return (
                          <NoteContainer key={index}>
                            <NameContainer>
                              {/* <Name onClick={() => editItem(note, 'targetmap')}>
                                {note.targetMap.name}
                              </Name> */}
                              <NameLink
                                to={`../targetmap/${note?.targetMap.id}`}
                              >
                                {note.targetMap.name}
                              </NameLink>
                              <Category>
                                {transformTargetMapType(note.targetMap?.type)}
                              </Category>
                            </NameContainer>
                            <NoteValue
                              onClick={() => editItem(note, 'targetmap')}
                              dangerouslySetInnerHTML={{ __html: note.content }}
                            />
                          </NoteContainer>
                        );
                      })
                    : generateEmptyDisplay()}
                </NotesListContainer>
              ) : (
                generateLoadingPlaceholder()
              )}
            </GroupContainer>
          )}

          {loadedHouseholdNotes &&
            loadedMemberNotes &&
            loadedInstrumentNotes &&
            memberNotes.length === 0 &&
            instrumentNotes.length === 0 && (
              <NoNotesContainer>
                <div>
                  <h4>Looking to Add Notes?</h4>
                  <ol>
                    <li>Click on a member or financial.</li>
                    <li>Navigate to the Notes tab.</li>
                    <li>Type notes in the text box.</li>
                    <li>Click Update.</li>
                  </ol>
                </div>
                <NoNotesImages
                  src={notePad}
                  alt="notes"
                  data-image="no-notes"
                />
              </NoNotesContainer>
            )}
        </PageContent>
      )}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const NoNotesContainer = styled.div`
  @media ${maxDevice.tablet} {
    padding: 15px 20px;
  }
  background: ${colors.lighterGrey};
  border: 1px solid ${colors.lightGrey};
  border-radius: 5px;
  padding: 20px 25px;
  max-width: 380px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  h4 {
    @media ${maxDevice.mobileL} {
      font-size: 14px;
    }
    font-weight: ${fonts.semiBold};
    font-size: 16px;
    margin-bottom: 5px;
  }
  ol {
    list-style-type: decimal;
    li {
      @media ${maxDevice.mobileL} {
        font-size: 12px;
      }
      margin-left: 20px;
      padding: 2px 5px;
      font-size: 14px;
    }
  }
`;

const NoNotesImages = styled.img`
  @media ${maxDevice.sideNav} {
    display: none;
  }
  max-width: 90px;
  max-height: 90px;
  margin-left: 40px;
`;

const GroupContainer = styled.div`
  margin-bottom: 50px;
`;

const GroupHeading = styled.h3`
  font-weight: ${fonts.semiBold};
  font-size: 18px;
  width: 180px;
  text-align: left;
`;

const NotesListContainer = styled.div`
  margin-top: 5px;
`;

const NoteContainer = styled.div`
  @media ${maxDevice.laptopAlt} {
    font-size: 14px;
  }
  @media ${maxDevice.tablet} {
    font-size: 13px;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding: 10px 0;
  }
  margin-bottom: 20px;
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px 0;
  /* border-bottom: 1px solid ${colors.lightGrey}; */
`;

// const NoteCardsList = styled.div`
//   margin-top: 15px;
//   display: flex;
//   flex-wrap: wrap;
//   align-content: center;
//   align-items: flex-start;
//   justify-content: flex-start;
// `;

const Name = styled.p`
  color: ${colors.lightBlue};
  overflow-wrap: break-word;
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
  cursor: pointer;
  &:hover {
    color: ${colors.blue};
  }
`;

const NameLink = styled(Link)`
  color: ${colors.lightBlue};
  overflow-wrap: break-word;
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
  cursor: pointer;
  &:hover {
    color: ${colors.blue};
  }
`;

const MemberAge = styled.span`
  margin-left: 5px;
  font-size: 12px;
  font-weight: ${fonts.light};
  color: ${colors.paleGrey};
`;

const Category = styled.p`
  color: ${colors.paleGrey};
  overflow-wrap: break-word;
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
  text-transform: capitalize;
  font-size: 14px;
`;

const NameContainer = styled.div`
  /* @media ${maxDevice.laptopAlt} {
    width: 120px;
  } */
  @media ${maxDevice.tablet} {
    margin-bottom: 10px;
    margin-right: 0;
    width: 100%;
    order: 1;
  }
  margin-right: 30px;
  width: 180px;
  flex: 0 0 auto;
  text-align: left;
  ${Name} {
    width: ${(props) => (props.loading ? ' 140px' : null)};
    height: ${(props) => (props.loading ? '20px' : null)};
    background: ${(props) => (props.loading ? colors.lightGrey : null)};
  }
  ${Category} {
    width: ${(props) => (props.loading ? ' 80px' : null)};
    height: ${(props) => (props.loading ? '18px' : null)};
    margin-top: ${(props) => (props.loading ? '4px' : null)};
    background: ${(props) => (props.loading ? colors.lightGrey : null)};
  }
`;

const NoteValue = styled.div`
  @media ${maxDevice.laptopAlt} {
    max-width: 500px;
    font-size: 14px;
  }
  @media ${maxDevice.tablet} {
    max-width: 400px;
    font-size: 13px;
    order: 2;
  }
  flex: 0 0 auto;
  background: ${colors.lightGrey};
  padding: 8px 12px;
  border-radius: 3px;
  position: relative;
  max-width: 800px;
  cursor: pointer;
  b,
  strong {
    font-weight: ${fonts.semiBold};
  }
  i {
    font-style: italic;
  }
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: ${colors.lightBlue};
    &:hover {
      color: ${colors.blue};
    }
  }
  &:after {
    @media ${maxDevice.tablet} {
      content: '';
      height: 0;
      position: absolute;
      z-index: 1;
      width: 0;
      top: -5.5px;
      left: 7px;
      border-radius: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid ${colors.lightGrey};
      border-top: none;
    }
    content: '';
    height: 0;
    position: absolute;
    z-index: 1;
    width: 0;
    bottom: -5.5px;
    left: 7px;
    border-radius: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid ${colors.lightGrey};
  }
`;

// const NoteCardContainer = styled.div`
//   margin-right: 15px;
//   margin-bottom: 15px;
//   padding: 15px;
//   box-shadow: ${boxShadows.boxShadowSoft};
//   border: 1px solid ${colors.lightGrey};
//   border-radius: 6px;
//   min-width: 200px;
//   flex: 1 1 auto;
//   max-width: 220px;
//   ${NameContainer} {
//     @media ${maxDevice.laptopAlt} {
//       width: 100%;
//     }
//     @media ${maxDevice.tablet} {
//       width: 100%;
//       order: 1;
//     }
//     width: 100%;
//     flex: 1 1 auto;
//     text-align: left;
//     margin-bottom: 10px;
//   }
// `;

HouseholdNotes.propTypes = {
  isConsumer: PropTypes.bool,
};

export default HouseholdNotes;
