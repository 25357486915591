/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import Cookies from 'js-cookie';
import { closeAllDropdowns } from '../store/actions';
import { logo } from '../assets';
import { colors, heights } from '../styles/variables';
import {
  HouseholdContainer,
  HouseholdName,
  HouseholdLocation,
  City,
  State,
  AdvisorContainer,
  AdvisorContainerCustom,
  AdvisorEmail,
  AdvisorName,
  IconButtonSVG,
} from '../styles/library';
import { APP_NAME, CONSUMER_BASENAME } from './constants';
import { DateFormatUTC, DateFromNow } from './';

const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_LOCAL_API_URL,
  REACT_APP_DEV_API_URL,
  REACT_APP_INT_API_URL,
  REACT_APP_GLOBAL_API_URL,
  REACT_APP_PROD_API_URL,
  NODE_ENV,
} = process.env;

//CHECK FOR LOCAL DEVELOP USING PORT 3000
const isLocalDev =
  NODE_ENV === 'development' &&
  window.location.origin === 'http://localhost:3000';

//DETERMINES BASE URL TO USE FOR API CALLS IN THIS FILE
let url;
switch (REACT_APP_ENVIRONMENT) {
  case 'dev':
    url = REACT_APP_DEV_API_URL;
    break;
  case 'int':
    url = REACT_APP_INT_API_URL;
    break;
  case 'global':
    url = REACT_APP_GLOBAL_API_URL;
    break;
  case 'prod':
    url = REACT_APP_PROD_API_URL;
    break;
  default:
    window.location.origin === 'http://localhost:3000'
      ? (url = REACT_APP_LOCAL_API_URL)
      : (url = window.location.origin + '/api/v3');
}

axios.defaults.baseURL = url || '';
axios.defaults.headers.common['Content-Type'] = 'application/json';

//UPDATE PAGE TITLE
export const UpdatePageTitle = (pageName) => {
  useEffect(() => {
    document.title = `${pageName} - ${APP_NAME}`;
  }, [pageName]);
};

//CLOSE ALL NAV DROPDOWNS
export const CloseDropdowns = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(closeAllDropdowns());
  }, [dispatch]);
};

//DETECT ENTER KEY - DEPRECATED USE DETECT MODAL KEYS
// export const DetectEnter = (func, state) => {
//   useEffect(() => {
//     const listener = (event) => {
//       if (event.code === 'Enter' || event.code === 'NumpadEnter') {
//         func(state);
//       }
//     };
//     document.addEventListener('keydown', listener, { capture: true });
//     return () => {
//       document.removeEventListener('keydown', listener);
//     };
//   }, [func, state]);
// };

//DETECT KEY PRESS
export const DetectKeyPress = (keyCode, func, state) => {
  useEffect(() => {
    const listener = (event) => {
      if (event.code === keyCode) {
        func(state);
      }
    };
    document.addEventListener('keydown', listener, { capture: true });
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [keyCode, func, state]);
};

//DETECTS DOUBLE CLICK
export const UseDoubleClick = ({
  ref,
  latency = 300,
  onSingleClick = () => null,
  onDoubleClick = () => null,
}) => {
  useEffect(() => {
    const clickRef = ref.current;
    let clickCount = 0;
    const handleClick = (e) => {
      clickCount += 1;

      setTimeout(() => {
        if (clickCount === 1) onSingleClick(e);
        else if (clickCount === 2) onDoubleClick(e);

        clickCount = 0;
      }, latency);
    };

    clickRef.addEventListener('click', handleClick, { capture: true });

    return () => {
      clickRef.removeEventListener('click', handleClick);
    };
  }, []);
};

//CHECKS IF KEYPRESS VALUE IN INPUT IS ENTER, IF IT IS RUNS ONENTER FUNCTION
export const DetectEnterKeyPress = (e, onEnter) => {
  if (e.key === 'Enter' || e.key === 'NumpadEnter') {
    e.preventDefault();
    onEnter();
  }
};

//CHECKS IF VALUE IS NEGATIVE
export const IsNegative = (value) => {
  let signVal = Math.sign(value);
  return signVal === -1 ? true : false;
};

//CHECKS IF VALUE IS OBJECT
export const CheckIsObject = (value) => {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

//CHECKS URL TO SEE IF USING CONNECT INSTEAD OF AMPUX
export const CheckIsConnectURL = () => {
  const { pathname } = window.location;
  const splitPathName = pathname.split('/');
  const appName = splitPathName[1];
  return appName === CONSUMER_BASENAME;
};

//REMOVES LOCAL STORAGE VALUE BASED OFF NAME <array>
export const RemoveLocalStorage = (
  storage = ['household_id', 'member_id', 'member_name', 'preview_url']
) => {
  storage.forEach((item) => {
    localStorage.removeItem(item);
  });
};

//CHECKS TO SEE IF USER HAS COOKIE AND DETERMINES DISPLAY BASED OFF VALUE
export const GetValueFromCookie = (cookieName, value) => {
  const hasToken = Cookies.get(cookieName);
  if (hasToken) {
    if (value !== undefined) {
      if (value) {
        Cookies.set(cookieName, '1');
      } else {
        Cookies.set(cookieName, '0');
      }
    }
  } else {
    Cookies.set(cookieName, '1');
  }
  return Cookies.get(cookieName) === '1' ? true : false;
};

//REMOVES COOKIE VALUE BASED OFF NAME <array>
export const RemoveCookies = (
  cookies = ['loggedIn', 'isConsumer', 'token', 'isPreview']
) => {
  cookies.forEach((cookie) => {
    Cookies.remove(cookie);
  });
};

//ADDS LOGGED IN AND TOKEN COOKIES
export const AddAuthCookies = (token, expiration, prod) => {
  if (isLocalDev && !prod) {
    Cookies.set('token', token, { expires: expiration / 86400 });
  } else {
    Cookies.set('loggedIn', true);
  }
};

//CHECKS IF USER HAS COOKIE VALUE - TOKEN IS USED FOR PORT 3000
export const CheckIsAuthenticated = () => {
  if (isLocalDev) {
    return HasCookie('token');
  } else {
    return HasCookie('loggedIn');
  }
};

//IF USER HAS VALUE THEN IT WILL SET COOKIE AS TRUE IF NOT IT REMOVES IT
export const ToggleCookie = (name, value) => {
  value ? Cookies.set(name, true) : Cookies.remove(name);
};

//CHECKS IF USER HAS COOKIE AND RETURNS BOOLEAN
export const HasCookie = (name) => {
  return Cookies.get(name) ? true : false;
};

//CHECKS LOCAL STORAGE FOR REMEMBER ME IF NOT SETS FALSE
export const CheckRememberMe = () => {
  let rememberMe = localStorage.getItem('rememberMe');
  if (rememberMe === null) {
    localStorage.setItem('rememberMe', false);
  }
  return JSON.parse(rememberMe);
};

//CHECKS LOCAL STORAGE TO CHECK IF SIDE NAV IS OPEN AND RETURNS BOOLEAN
//IF PASSED checkWidth IT WILL RETURN SIDE NAV WIDTH VALUE
export const GetNavExpandedValue = (checkWidth = false) => {
  const isSideNavExpanded = localStorage.getItem('sideNavExpanded');
  if (checkWidth) {
    return isSideNavExpanded === 'true' ? '220px' : '58px';
  } else {
    if (isSideNavExpanded === null || isSideNavExpanded === undefined) {
      localStorage.setItem('sideNavExpanded', false);
    }
    return isSideNavExpanded === 'true';
  }
};

//CHECKS TO SEE IF USER HAS CUSTOM COLOR SAVED IN LOCAL STORAGE IF NOT USES BLUE
export const GetCustomPenColor = () => {
  let color = localStorage.getItem('customPenColor');
  if (color === null) {
    localStorage.setItem('customPenColor', colors.blue);
    return colors.blue;
  }
  return color;
};

//SETS NEW CUSTOM PEN COLOR IN LOCAL STORAGE - HEX VALUE <string>
export const UpdateCustomPenColor = (color) => {
  localStorage.setItem('customPenColor', color);
  return color;
};

//GETS LOGO VALUE FROM LOCAL STORAGE IF NOT CUSTOM THEM USES OUR LOGO
export const GetLogoValue = () => {
  const savedLogo = localStorage.getItem('logo');
  if (savedLogo) {
    return savedLogo;
  } else {
    return logo;
  }
};

//UPDATES LOGO VALUE IN LOCAL STORAGE
export const UpdateLogoValue = (value) => {
  if (value) {
    localStorage.setItem('logo', value);
    return value;
  } else {
    localStorage.setItem('logo', logo);
    return logo;
  }
};

//PASS IN MIN AND MAX VALUES TO RETURN RANDOM NUMBER
export const GenerateRandomInteger = (max, min) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

//CHECKS IF FIELD HAS SETS ERROR DEPENDING ON RESULT
export const CheckHasValue = (field, isObj = true) => {
  if (isObj) {
    if (
      field.value === undefined ||
      field.value === '' ||
      field.value === null
    ) {
      field.error = true;
    } else {
      field.error = false;
    }
    return field;
  } else {
    if (
      field?.toString()?.trim() === '' ||
      field === undefined ||
      field === null
    ) {
      return false;
    }
    return true;
  }
};

//FINDS MATCHING OBJECT FOR STATES, COUNTRIES & CURRENCIES
export const SetDefaultItem = (list, selectedItem, stateFunc) => {
  if (list && list.length >= 1) {
    let matched = list.find((item) => {
      return item.code === selectedItem;
    });
    if (matched) {
      return stateFunc({
        value: matched.code,
        label: matched.name,
      });
    }
  }
};

//FINDS MATCHING OBJECT FROM LIST OF OBJECTS
export const MatchSelectItem = (list, value, isShallow = false) => {
  if (isShallow) {
    // eslint-disable-next-line eqeqeq
    return list.find((item) => item.value == value);
  }
  return list.find((item) => item.value === value);
};

//FILTERS INSTRUMENTS BY TYPE AND OPTIONAL BY CATEGORY
export const FilterInstruments = (instruments, type, categories) => {
  if (categories) {
    return instruments.filter(
      (instrument) =>
        instrument.instrument_type === type &&
        !instrument.is_hidden &&
        categories.includes(instrument.category)
    );
  } else {
    return instruments.filter(
      (instrument) =>
        instrument.instrument_type === type && !instrument.is_hidden
    );
  }
};

//FUNCTION TO DOWNLOAD A SINGLE REPORT
export const DownloadReport = (
  report,
  amrEntityOwnership = null,
  amrOrientation = null
) => {
  let childInputs = '';
  let typeInputs = '';
  let cashFlowInputs = '';
  let targetMapCover = '';
  let assetMapInputs = '';
  let assetMapInputUX = '';
  let assetMapAttributes = '';
  let assetMapOrientation = '';
  let assetMapShowRollup = '';
  let assetMapRollupExpand = '';
  let assetMapIncludeKffs = '';
  let assetMapProposal = '';
  let entityRollup = '';
  let customInput = '';
  let compareInput = '';

  if (report.hasOptions) {
    let selectedItems = report.children.filter(
      (item) => item.selected && item?.id
    );
    selectedItems.filter((report) => report.value !== 'summary');
    childInputs = selectedItems.map((item, index) => {
      return `<input key=${index} type="hidden" name=${report.childValue} value="${item.id}" />`;
    });
  }
  if (report.hasOwnProperty('types')) {
    const selectedTypes = report.types.filter((type) => type.selected);
    typeInputs = selectedTypes.map((type, index) => {
      return `<input key=${index} type="hidden" name=${report.value}_types value="${type.value}" />`;
    });
  }
  if (report.value === 'targetmap') {
    const summaryChild = report.children.find(
      (child) => child.value === 'summary'
    );
    if (summaryChild && summaryChild.selected) {
      targetMapCover = `<input type="hidden" name="targetmap_cover" value="${summaryChild.selected}" />`;
    }
    const selectedCashFlows = report.children.filter((child) => child.cashFlow);
    cashFlowInputs = selectedCashFlows.map((targetMap, index) => {
      return `<input key=${index} type="hidden" name="cashflows" value="${targetMap.value}" />`;
    });
  }
  if (report.value === 'assetmap') {
    assetMapInputUX = '<input type="hidden" name="assetmap" value="1" />';

    if (report.members) {
      if (Array.isArray(report.members)) {
        assetMapInputs = `<input type="hidden" name="assetmap_choices" value="${report.members.join(
          ','
        )}" />`;
      } else {
        assetMapInputs = `<input type="hidden" name="assetmap_choices" value="${report.members}" />`;
      }
    }
    if (report.toggleValues) {
      for (const [key, value] of Object.entries(report.toggleValues)) {
        assetMapAttributes += `<input type="hidden" name="${key}" value="${
          value ? '1' : '0'
        }" />`;
      }
    }
    if (report.rollup_expand) {
      entityRollup = `<input type="hidden" name="rollup_expand" value="${report.rollup_expand}" />`;
    }
  }
  if (report.value.includes('custom') === true) {
    customInput = `<input type="hidden" name="customs" value="${report.value}"/>`;
  }
  if (report.type === 'targetmap_compare') {
    compareInput = `<input type="hidden" name="targetmap_compare" value="true"/>`;
  }
  if (amrEntityOwnership) {
    let amrShowRollup = 1;
    let amrRollupExpand = '';

    if (amrEntityOwnership === 'off') {
      amrShowRollup = 0;
    }

    if (amrEntityOwnership === 'expanded') {
      amrRollupExpand = '*';
    }

    assetMapShowRollup = `<input type="hidden" name="show_rollup" value="${amrShowRollup}" />`;
    assetMapRollupExpand = `<input type="hidden" name="rollup_expand" value="${amrRollupExpand}" />`;
  }
  if (amrOrientation) {
    assetMapOrientation = `<input type="hidden" name="orientation" value="${amrOrientation}" />`;
  }
  if (report.includeKffs) {
    assetMapIncludeKffs = `<input type="hidden" name="include_amr_kffs" value="1" />`;
  }
  if (report.proposalId) {
    assetMapProposal = `<input type="hidden" name="proposal_id" value="${report.proposalId}" />`;
  }

  const formContent = `
      <input type="hidden" name="csrfmiddlewaretoken" value="${Cookies.get(
        'csrftoken'
      )}" />
      <input type="hidden" name="internal" value="1" />
      <input type="hidden" name="${report.value}" value="1" />
      <input type="hidden" name="type" value="${report.value}" />
      <input type="hidden" name="orientation" value="${amrOrientation}" />
      ${childInputs}
      ${typeInputs}
      ${cashFlowInputs}
      ${targetMapCover}
      ${assetMapInputUX}
      ${assetMapInputs}
      ${assetMapAttributes}
      ${assetMapOrientation}
      ${assetMapShowRollup}
      ${assetMapRollupExpand}
      ${assetMapIncludeKffs}
      ${assetMapProposal}
      ${entityRollup}
      ${customInput}
      ${compareInput}
    `;

  console.log('formContent', formContent);
  document.getElementById('generate_pdf_form').innerHTML = formContent;
  document.getElementById('generate_pdf_form').submit();
  return;
};

const getSelectedChildren = (
  selectedReports,
  value,
  filterValue = 'selected'
) => {
  const matchedReport = selectedReports.find(
    (report) => report.value === value
  );
  if (matchedReport?.children) {
    return matchedReport.children.filter((item) => item[filterValue]);
  }
};

//FUNCTION TO DOWNLOAD A COMPREHENSIVE REPORT
export const DownloadComprehensiveReport = (
  reports,
  reportOrder,
  amrEntityOwnership = null,
  amrOrientation = null
) => {
  let inputs = '';
  let customs = '';
  let assetMapShowRollup = '';
  let assetMapRollupExpand = '';
  let assetMapOrientation = '';
  for (const selectedReport of reports) {
    if (selectedReport.value.includes('custom') === true) {
      customs += `${selectedReport.value},`;
    } else {
      inputs += `<input type="hidden" name="${selectedReport.value}" value="1"/>\n`;
    }
    // items with children checkboxes
    const selectedItems = getSelectedChildren(reports, selectedReport.value);
    if (selectedItems) {
      if (selectedReport.value === 'assetmap') {
        inputs += selectedItems.map((item, index) => {
          return `<input key=${index} type="hidden" name="assetmaps" value=${item.id} />`;
        });
        if (selectedReport.includeKffs) {
          inputs += `<input type="hidden" name="include_amr_kffs" value="1" />`;
        }
      }

      if (
        selectedReport.value === 'beneficiary' ||
        selectedReport.value === 'balancesheet'
      ) {
        inputs += selectedItems.map((item, index) => {
          return `<input key=${index} type="hidden" name="${selectedReport.value}_members" value=${item.id} />`;
        });
      }

      if (selectedReport.value === 'targetmap') {
        const selectedRTms = selectedItems.filter(
          (tm) => tm.value !== 'summary'
        );
        inputs += selectedRTms.map((item, index) => {
          return `<input key=${index} type="hidden" name="targetmaps" value=${item.value} />`;
        });

        const selectedCashflows = getSelectedChildren(
          reports,
          'targetmap',
          'cashFlow'
        );
        if (selectedCashflows && selectedCashflows.length !== 0) {
          inputs += selectedCashflows.map((targetMap, index) => {
            return `<input key=${index} type="hidden" name="cashflows" value="${targetMap.value}" />`;
          });
        }
        const summaryChild = selectedItems.find(
          (selected) => selected.value === 'summary'
        );
        if (summaryChild && summaryChild?.selected) {
          inputs += `<input type="hidden" name="targetmap_cover" value="${summaryChild.selected}" />`;
        }
      }

      if (selectedReport.value === 'balancesheet') {
        inputs += selectedItems.map((item, index) => {
          return `<input key=${index} type="hidden" name="balancesheet_members" value="${item.id}" />`;
        });
      }

      if (selectedReport.value === 'policysummary') {
        inputs += selectedItems.map((item, index) => {
          return `<input key=${index} type="hidden" name="policysummary_members" value="${item.id}" />`;
        });
        const matchedReport = reports.find(
          (report) => report.value === 'policysummary'
        );
        const selectedTypes = matchedReport.types.filter(
          (type) => type.selected
        );
        inputs += selectedTypes.map((type, index) => {
          return `<input key=${index} type="hidden" name="policysummary_types" value="${type.value}" />`;
        });
      }
    }
  }
  if (customs !== '') {
    inputs += `<input type="hidden" name="customs" value="${customs}"/>\n`;
  }

  if (amrEntityOwnership) {
    let amrShowRollup = 1;
    let amrRollupExpand = '';

    if (amrEntityOwnership === 'off') {
      amrShowRollup = 0;
    }

    if (amrEntityOwnership === 'expanded') {
      amrRollupExpand = '*';
    }

    assetMapShowRollup = `<input type="hidden" name="show_rollup" value="${amrShowRollup}" />`;
    assetMapRollupExpand = `<input type="hidden" name="rollup_expand" value="${amrRollupExpand}" />`;
  }

  if (amrOrientation) {
    assetMapOrientation = `<input type="hidden" name="orientation" value="${amrOrientation}" />`;
  }

  const formContent = `
      <input type="hidden" name="csrfmiddlewaretoken" value="${Cookies.get(
        'csrftoken'
      )}" />
      <input type="hidden" name="type" value="comprep" />
	    <input type="hidden" name="return" value=${document.URL}" />
      ${inputs}
      ${assetMapShowRollup}
      ${assetMapRollupExpand}
      ${assetMapOrientation}
      <input type="hidden" name="order" value=${reportOrder} />
    `;
  document.getElementById('generate_pdf_form').innerHTML = formContent;
  document.getElementById('generate_pdf_form').submit();
};

//DETECT OUTSIDE CLICK OF ELEMENT
export const UseOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    let clickId = e.srcElement.id;
    let clickClass = e.srcElement.className;
    let isLabel = e.srcElement.localName === 'label';
    if (clickId === 'select-dropdown') {
      return;
    }
    if (clickId === 'remove-button' || clickId === 'remove-icon') {
      return;
    }
    if (e.srcElement.localName === 'button') {
      return;
    }
    if (clickId === 'delete-icon') {
      return;
    }
    if (isLabel) {
      return;
    }
    if (
      clickClass instanceof String &&
      clickClass.includes('react-datepicker')
    ) {
      return;
    }
    if (clickClass !== '' && clickClass === 'select-dropdown-span') {
      return;
    }
    if (clickId.includes('react-select')) {
      return;
    }
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, { capture: true });

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

//CUSTOM HOOK TO CREATE RIGHT CLICK CONTEXT MENU
export const UseContextMenu = (
  sideNavWidth,
  containerId,
  showMenu,
  setShowMenu,
  menuWidth = 150,
  subMenuWidth = 140,
  menu
) => {
  const [xPos, setXPos] = useState('0px');
  const [yPos, setYPos] = useState('0px');
  const [invertSubMenuX, setInvertSubMenuX] = useState(false);
  const [invertSubMenuY, setInvertSubMenuY] = useState(false);

  const handleContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      const sideNav = parseInt(sideNavWidth);
      const navBar = parseInt(heights.navBar);
      const containerElement = document.getElementById(containerId);
      const containerWidth = containerElement?.offsetWidth;
      const xMenuConstraint = containerWidth + sideNav - menuWidth;
      const xSubMenuConstraint = xMenuConstraint - subMenuWidth;
      let menuXPos = e.pageX;

      const containerHeight = containerElement?.offsetHeight;
      const itemHeight = 33.5;
      const menuHeight = (menu?.length + 1) * itemHeight;
      const childrenMenuLengths = menu.reduce((acc, curr) => {
        if (curr?.children) {
          return [...acc, curr?.children.length];
        }
        return acc;
      }, []);
      let largestMenuLength = 0;
      if (childrenMenuLengths.length !== 0) {
        largestMenuLength = Math.max(...childrenMenuLengths);
      }
      const yMenuConstraint = containerHeight + navBar - menuHeight;
      const subMenuHeight = (largestMenuLength - 1) * itemHeight;
      const ySubMenuConstraint = yMenuConstraint - subMenuHeight;
      let menuYPos = e.pageY;

      if (menuXPos > xMenuConstraint) {
        menuXPos = xMenuConstraint;
      }
      if (menuYPos > yMenuConstraint) {
        menuYPos = yMenuConstraint;
        menuXPos += 5;
      }
      setInvertSubMenuX(menuXPos > xSubMenuConstraint);
      setInvertSubMenuY(menuYPos > ySubMenuConstraint);

      setXPos(`${menuXPos - sideNav}px`);
      setYPos(`${menuYPos - navBar}px`);
      setShowMenu(true);
    },
    [setXPos, setYPos, sideNavWidth]
  );

  const handleClick = useCallback(() => {
    showMenu && setShowMenu(false);
  }, [showMenu]);

  useEffect(() => {
    const containerElement = document.getElementById(containerId);
    containerElement.addEventListener('click', handleClick, { capture: true });
    containerElement.addEventListener('contextmenu', handleContextMenu);
    return () => {
      containerElement.addEventListener('click', handleClick, {
        capture: true,
      });
      containerElement.removeEventListener('contextmenu', handleContextMenu);
    };
  });

  return { xPos, yPos, showMenu, invertSubMenuX, invertSubMenuY };
};

//DETECT IF CAPS LOCK IS ACTIVE
export const CapsLockActive = (props) => {
  const EVENT_KEY_DOWN = 'keydown';
  const EVENT_KEY_UP = 'keyup';
  const [isCapsLockActive, setIsCapsLockActive] = useState(false);

  const wasCapsLockActivated = useCallback(
    (event) => {
      if (
        event.getModifierState &&
        event.getModifierState('CapsLock') &&
        isCapsLockActive === false
      ) {
        setIsCapsLockActive(true);
      }
    },
    [isCapsLockActive]
  );

  const wasCapsLockDeactivated = useCallback(
    (event) => {
      if (
        event.getModifierState &&
        !event.getModifierState('CapsLock') &&
        isCapsLockActive === true
      ) {
        setIsCapsLockActive(false);
      }
    },
    [isCapsLockActive]
  );

  useEffect(() => {
    document.addEventListener(EVENT_KEY_DOWN, wasCapsLockActivated, {
      capture: true,
    });
    document.addEventListener(EVENT_KEY_UP, wasCapsLockDeactivated, {
      capture: true,
    });
  }, [isCapsLockActive, wasCapsLockActivated, wasCapsLockDeactivated]);

  useEffect(() => {
    return () => {
      document.removeEventListener(EVENT_KEY_DOWN, wasCapsLockActivated);
      document.removeEventListener(EVENT_KEY_UP, wasCapsLockDeactivated);
    };
  }, [wasCapsLockActivated, wasCapsLockDeactivated]);

  return <>{props.children(isCapsLockActive)}</>;
};

CapsLockActive.propTypes = {
  children: PropTypes.func.isRequired,
};

//GENERATES TRACKING SOURCE KEYWORD BASED OFF URL PATH
export const GenerateSourceKeyword = (path) => {
  switch (true) {
    case path.includes('member/dashboard'):
    case path.includes('dashboard'):
      return 'member_dashboard';
    case path.includes('member/preferences?panel=user'):
    case path.includes('user/preferences?panel=user'):
      return 'member_preferences';
    case path.includes('member/preferences?panel=security'):
    case path.includes('user/preferences?panel=security'):
      return 'member_preferences_security';
    case path.includes('member/preferences?panel=custom'):
      return 'member_preferences_custom';
    case path.includes('member/preferences'):
    case path.includes('user/preferences'):
      return 'member_preferences';
    case path.includes('member/households'):
      return 'household_index';
    case path.includes('household/new/manual'):
      return 'new_household_manual';
    case path.includes('household/new'):
      return 'new_household';
    case path.includes('household/integrations'):
      return 'integration_households';
    case path.includes('integration'):
      return 'integration_member';
    case path.includes('partners'):
      return 'partner';
    case path.includes('/summary'):
      return 'household_summary';
    case path.includes('/assetmap'):
      return 'household_amr';
    case path.includes('/members'):
      return 'household_members';
    case path.includes('/financials'):
      return 'household_financials';
    case path.includes('/signals'):
      return 'household_signals';
    case path.includes('/targetmap/compare'):
      return 'household_targetmap_compare';
    case path.includes('/targetmap/'):
      return 'household_targetmap';
    case path.includes('/targetmap'):
      return 'household_targetmaps';
    case path.includes('/notes'):
      return 'household_notes';
    case path.includes('/balance_sheet'):
      return 'household_balance_sheet';
    case path.includes('/policy_summary'):
      return 'household_policy_summary';
    case path.includes('/beneficiaries'):
      return 'household_beneficiaries';
    case path.includes('/reports'):
      return 'household_reports';
    case path.includes('/milestones'):
      return 'household_milestones';
    case path.includes('/kffs'):
      return 'household_kffs';
    case path.includes('/info?panel=details'):
      return 'household_details';
    case path.includes('/info?panel=investor'):
      return 'household_profile';
    case path.includes('/info?panel='):
      return 'household_integration';
    case path.includes('manager/team?panel=membership'):
      return 'manager_membership';
    case path.includes('manager/team?panel=activity'):
      return 'manager_activity';
    case path.includes('manager/team?panel=signals'):
      return 'manager_signals';
    case path.includes('manager/team?panel=discovery'):
      return 'manager_discovery';
    case path.includes('manager/team?panel=stencils'):
      return 'manager_stencils';
    case path.includes('manager/team'):
    case path.includes('manager/team?panel=account'):
      return 'manager_account';
    case path.includes('discovery'):
      return 'discovery';
    default:
      return 'other';
  }
};

/*
  API CALL THAT SENDS TRACKING EVENT
  - invocation <string>: unique uuid that is generated for user when app loads
  - type <string>: one of the following 'page'(route change), 'action'(generic action taken), 'click'(user click)
  - keyword <string>: value that is used for tracking, consult spreadsheet or activity_track function in backend
  - source <string>: contains location or area event took place (uses function above to convert route to keyword)
  - payload <object>: optional additional information desired to be passed to tracking event
*/
export const SendTrackingEvent = (
  invocation,
  type,
  keyword,
  source,
  payload
) => {
  const CSRFToken = Cookies.get('csrftoken');
  axios.defaults.headers.common['X-CSRFToken'] = CSRFToken;
  const search = window.location.search;
  const path = `${window.location.pathname}${search}`;
  let household = false;
  let targetmap = false;
  let integration = false;
  if (
    path.includes('/household/') &&
    !path.includes('/new') &&
    !path.includes('/household/integrations')
  ) {
    const splitRoute = path.split('/');
    household = splitRoute[3];
    if (path.includes('/targetmap/compare')) {
      targetmap = [splitRoute[6], splitRoute[7], splitRoute[8]].filter(
        (i) => i
      );
    } else if (path.includes('/targetmap/')) {
      targetmap = splitRoute[5];
    }
  }
  if (search) {
    if (
      (path.includes('/info?panel=') ||
        path.includes('/integrations?panel=')) &&
      !path.includes('/info?panel=details') &&
      !path.includes('/info?panel=investor')
    ) {
      integration = search.split('=')[1];
    }
  }
  const object = {
    invocation,
    type,
    path,
  };
  try {
    if (household) object.payload = { ...object.payload, household };
    if (targetmap) object.payload = { ...object.payload, targetmap };
    if (integration) object.payload = { ...object.payload, integration };
    if (payload) object.payload = { ...object.payload, ...payload };
    if (type === 'page') {
      object.keyword = GenerateSourceKeyword(path);
      object.source = GenerateSourceKeyword(path);
    } else {
      object.keyword = keyword;
      if (!source) {
        object.source = GenerateSourceKeyword(path);
      } else {
        object.source = source;
      }
    }
  } catch (e) {
    console.log('e', e);
    object.error = true;
    object.message = e;
  }

  axios.post(`${url}/activity/track`, object);
};

//FUNCTION TO SEARCH HOUSEHOLDS AND RETURN CUSTOM DISPLAY TO DROPDOWN
export const HouseholdSearch = (input, onClick, householdStartPage) => {
  const CSRFToken = Cookies.get('csrftoken');
  axios.defaults.headers.common['X-CSRFToken'] = CSRFToken;
  return new Promise((resolve) => {
    axios
      .post(`${url}/household/list`, {
        query: input,
      })
      .then((res) => {
        let mapped = res.data.results.map((item) => {
          let obj = {};
          obj.value = item.id;
          obj.label = (
            <HouseholdContainer
              to={`/household/${item.id}/${householdStartPage}`}
              onClick={() => onClick()}
            >
              <HouseholdName>{item.name}</HouseholdName>
              {item.city ||
              (item.state && item.city !== 'Unknown') ||
              item.state !== 'UK' ? (
                <HouseholdLocation>
                  {item.city === 'Unknown' || item.city === '' ? (
                    null || item.city === null
                  ) : (
                    <City>{item.city}</City>
                  )}
                  {item.state === 'UK' ||
                  item.state === '' ||
                  item.state === null ? null : (
                    <State>
                      {item.city === 'Unknown' ||
                      item.city === '' ||
                      item.city === null ? null : (
                        <span>, </span>
                      )}
                      {item.state}
                    </State>
                  )}
                </HouseholdLocation>
              ) : null}
            </HouseholdContainer>
          );
          return obj;
        });
        return resolve(mapped);
      });
  });
};

//FUNCTION TO SEARCH ADVISORS AND RETURN CUSTOM DISPLAY TO DROPDOWN
export const AdvisorSearch = (input, householdId) => {
  const CSRFToken = Cookies.get('csrftoken');
  axios.defaults.headers.common['X-CSRFToken'] = CSRFToken;
  let path;
  input ? (path = `?query=${input}`) : (path = '');
  return new Promise((resolve) => {
    axios
      .get(`${url}/household/${householdId}/advisor_search${path}`)
      .then((res) => {
        let mapped = res.data.map((item) => {
          let obj = {};
          obj.value = item.id;
          obj.name = item.name;
          obj.customer_name = item.customer_name;
          obj.label = (
            <AdvisorContainer id="advisor-select">
              <AdvisorName>
                {item.name}
                {item.customer_name && <span>{item.customer_name}</span>}
              </AdvisorName>
              {item.email && <AdvisorEmail>{item.email}</AdvisorEmail>}
            </AdvisorContainer>
          );
          return obj;
        });
        return resolve(mapped);
      });
  });
};

//FUNCTION TO SEARCH ADVISORS AND RETURN CUSTOM DISPLAY TO DROPDOWN
export const AdvisorCustomSearch = (customerId, query) => {
  const CSRFToken = Cookies.get('csrftoken');
  axios.defaults.headers.common['X-CSRFToken'] = CSRFToken;
  return new Promise((resolve) => {
    axios
      .get(`${url}/customer/${customerId}/advisor_search/${query}`)
      .then((res) => {
        let mapped = res.data.map((item) => {
          let obj = { ...item };
          obj.name = item.label;
          obj.label = (
            <AdvisorContainerCustom id="advisor-select">
              <AdvisorName>{obj.name}</AdvisorName>
              {item?.email && <AdvisorEmail>{item?.email}</AdvisorEmail>}
            </AdvisorContainerCustom>
          );
          return obj;
        });
        return resolve(mapped);
      });
  });
};

//FUNCTION TO SEARCH USERS AND RETURN CUSTOM DISPLAY TO DROPDOWN
export const ActivitySearch = (input, customerId) => {
  const CSRFToken = Cookies.get('csrftoken');
  axios.defaults.headers.common['X-CSRFToken'] = CSRFToken;
  let path;
  input ? (path = `?q=${input}`) : (path = '');
  return new Promise((resolve) => {
    axios
      .get(`${url}/customer/${customerId}/member_list${path}`)
      .then((res) => {
        let mapped = res.data.results.map((item) => {
          let obj = {};
          obj.value = item.id;
          obj.name = item.name;
          obj.label = (
            <AdvisorContainer id="advisor-select">
              <AdvisorName>
                {item.name} <span>{item.email}</span>
              </AdvisorName>
            </AdvisorContainer>
          );
          return obj;
        });
        return resolve(mapped);
      });
  });
};

//CREATES CUSTOM COLORED MARKER SVG IN MARKER DROPDOWN
export const CreateCustomMarker = (item) => {
  return (
    <IconButtonSVG
      width="200px"
      height="200px"
      viewBox="0 0 200 200"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      alt={item.label}
      onClick={item.function}
      style={
        item.isSelected
          ? {
              background: colors.lightGrey,
              borderRadius: item.label === 'black_pen' ? '0 0 0 6px' : null,
            }
          : { cursor: 'pointer' }
      }
    >
      <title>{item.label}</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M164.609343,0 C155.558585,0 146.511733,3.45504438 139.609407,10.3612269 L137.156288,12.814328 L128.003968,3.66207361 C123.121168,-1.2206912 115.211032,-1.2206912 110.328232,3.66207361 L59.5158628,54.485796 C58.297116,55.7045341 58.297116,57.6849835 59.5158628,58.9037216 L63.9338202,63.3216472 C65.1525671,64.5403853 67.1330307,64.5403853 68.3517776,63.3216472 L119.168053,12.501831 L128.316467,21.6540854 L36.695609,113.293815 C16.1920342,133.802977 3.27607693,160.677508 0.0707032438,189.500103 L0.0589845239,189.589945 C-0.566013868,195.203172 3.859756,200 9.35192937,200 C9.69958473,200 10.0472401,199.980469 10.4027079,199.941407 C39.2573938,196.752732 66.1649591,183.829479 86.6915742,163.30114 L189.609278,60.3685511 C211.929533,38.0406441 195.839731,0 164.609343,0 Z M77.8556594,154.461382 C60.2814859,172.039336 37.2659201,183.527505 12.9847325,187.035283 C16.5511296,162.566772 28.0198501,139.645121 45.5315238,122.129667 L76.2775385,91.379967 L108.601674,123.711683 L77.8556594,154.461382 L77.8556594,154.461382 Z M180.769457,51.5287937 L117.437589,114.871925 L85.1134532,82.5402096 L148.445322,19.1970782 C152.761717,14.8807141 158.503889,12.501831 164.609343,12.501831 C177.23431,12.501831 187.468659,22.7204812 187.468659,35.364889 C187.464752,41.4702981 185.089759,47.2124296 180.769457,51.5287937 Z"
          fill={item.color}
          fillRule="nonzero"
        ></path>
      </g>
    </IconButtonSVG>
  );
};

/*
  CHECKS THAT USER HAS AT LEAST ONE OF THE PASSED IN ENTITLEMENTS
    - entitlements <array> - list of string entitlements user has
    - permissionsList <array> - list of string entitlements to check
 */
export const CheckHasAtLeastOneEntitlement = (
  entitlements,
  permissionsList
) => {
  return entitlements.some((entitlement) =>
    permissionsList.includes(entitlement)
  );
};

//FORMATS ERROR RESPONSE DURING LOGIN/AUTH PROCESS <object>
export const GenerateAuthResponseError = (error) => {
  if (error.status === 401) {
    return {
      showError: true,
      message: 'Incorrect password or username',
    };
  }
  if (error.status === 400) {
    console.log('error', error);
    if (error.data.errors === 'authorization-delay') {
      let endDate = DateFormatUTC(
        error.data.available,
        'YYYY-MM-DD HH:mm:ss',
        true
      );
      let diffAlt = DateFromNow(endDate);
      return {
        showError: true,
        message: `Too many attempts try again in ${diffAlt}.`,
      };
    } else if (error?.data?.details === 'user-migrated') {
      return {
        showError: true,
        message:
          'You account has been migrated. Please login at <a href="https://global.asset-map.com">https://global.asset-map.com</a>.',
      };
    } else {
      return {
        showError: true,
        message:
          'User is locked. Contact <a href="mailto:support@asset-map.com">support@asset-map.com</a>.',
      };
    }
  }
  return {};
};

//UPDATES UPSCOPE INTEGRATION FOR SUPPORT
export const UpdateUpscope = (user) => {
  try {
    window.Upscope('updateConnection', {
      uniqueId: user?.uuid,
      identities: [user?.name, user?.email],
    });
  } catch (err) {
    console.log('Upscope update error', err);
  }
  return null;
};

export const DetermineIcon = (item) => {
    
}