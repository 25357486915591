/* eslint-disable react-hooks/exhaustive-deps */
import ReactDOM from 'react-dom';
import { useRef, useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  ModalButtons,
  //   ModalTabs,
  DetectModalKeys,
  GenerateInput,
  Error,
  LoadingAnimation,
} from '..';
import {
  timesWhite,
  chevronRight,
  greyCirclePlus,
  arrowBack,
} from '../../assets';
import {
  ModalBackground,
  ModalOverlay,
  ModalContainer,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ModalLoadingOverlayContainer,
  LoadingOverlayContainer,
  LoadingOverlay,
} from '../../styles/library/modalStyles';
import {
  LegalApplicableMemberOption,
  LegalEmpoweredMemberOption,
  UseHandleInstrumentError,
  CheckHasError,
  GenerateError,
  RemoveError,
  DateFormat,
  BeneficiaryOption,
  UseGenerateMembersList,
  FilterMembersByContext,
  TransformApplicables,
  TransformEmpowered,
  TransformLegalBeneficiaries,
  GenerateAvatarMemberIconOld,
} from '../../utils';
import {
  createLegal,
  clearLegal,
  updateHouseholdStoreValue,
  updateLegal,
  getLegal,
  deleteLegal,
} from '../../store/actions/households';
import styled from 'styled-components';
import { colors, colorsRGB } from '../../styles/variables';
import { ButtonThemes } from '../../styles/themes';

const legalReducer = (legal, action) => {
  legal[action.type] = action.payload;
  return legal;
};

const LegalModal = ({ isNew, isList, hide, householdId }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const {
    legalCategories,
    currentHousehold,
    legalError,
    preferences,
    legal,
    clearedLegal,
    membersLegals,
  } = useSelector((state) => ({
    legalCategories: state.configs.legalCategories,
    currentHousehold: state.households.currentHousehold,
    legalError: state.households.legalError,
    preferences: state.user.user.preferences,
    legal: state.households.legal,
    clearedLegal: state.households.clearedLegal,
    membersLegals: state.households.membersLegals,
  }));

  const initLegalState = {
    category: '',
    applicable_members: [],
    reference_name: '',
    location: '',
    empowered_members: [],
    beneficiary_members: [],
    date_created: '',
    date_collected: '',
    notes: '',
  };
  const [legalState, dispatchLegal] = useReducer(legalReducer, initLegalState);

  const [typeOptions, setTypeOptions] = useState();
  const [membersList, setMembersList] = useState();
  const [hasMembersList, setHasMembersList] = useState(false);
  const [membersOptions, setMembersOptions] = useState();
  const [beneficiariesOptions, setBeneficiariesOptions] = useState();
  const [legalType, setLegalType] = useState();
  const [hasApplicables, setHasApplicables] = useState(false);
  const [hasEmpowered, setHasEmpowered] = useState(false);
  const [hasBeneficiaries, setHasBeneficiaries] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadedData, setLoadedData] = useState(false);
  const [modalError, setModalError] = useState('');
  const [modalErrors, setModalErrors] = useState();
  const [errorTitle, setErrorTitle] = useState();
  const [hasLegal, setHasLegal] = useState(false);
  const [categoryMap, setCategoryMap] = useState();
  const [modalHeader, setModalHeader] = useState('');
  const [hasLegals, setHasLegals] = useState(false);
  const [legalsList, setLegalsList] = useState([]);
  const [hasCategories, setHasCategories] = useState(false);
  const [modalState, setModalState] = useState();
  const [showBackButton, setShowBackButton] = useState(false);
  //   const [modalTabOptions, setModalTabOptions] = useState([
  //     { label: 'Instrument', value: 'instrument' },
  //   ]);
  //   const [activeTab, setActiveTab] = useState();

  useEffect(() => {
    return () => {
      dispatch(clearLegal());
    };
  }, []);

  useEffect(() => {
    if (clearedLegal) {
      setTypeOptions();
      setMembersList();
      setMembersOptions();
      setHasMembersList(false);
      setLegalType();
      setHasApplicables(false);
      setHasEmpowered(false);
      setBeneficiariesOptions();
      setHasBeneficiaries(false);
      setLoadedData(false);
      setModalError('');
      setModalErrors();
      setErrorTitle();
      setHasLegal(false);
      setCategoryMap();
      setHasCategories(false);
      dispatch(updateHouseholdStoreValue('clearedLegal', false));
      setHasLegals(false);
      setLegalsList([]);
      setShowBackButton(false);
      //   setActiveTab();
      //   setModalTabOptions();
    }
  }, [clearedLegal]);

  // Modal State Management
  useEffect(() => {
    if (isList) {
      //   setActiveTab('instrument');
      setModalState('list');
    } else {
      if (isNew) {
        setModalState('add');
        setLoadedData(true);
      } else {
        setModalState('edit');
      }
    }
  }, [isNew, isList]);

  useEffect(() => {
    if (modalState === 'list') {
      setModalHeader('Legal Instruments');
    }
    if (modalState === 'add') {
      setModalHeader('Add Legal');
    }
    if (modalState === 'edit') {
      setModalHeader('Edit Legal');
    }
  }, [modalState]);

  useEffect(() => {
    if (legal) {
      setHasLegal(true);
    }
  }, [legal]);

  useEffect(() => {
    if (membersLegals) {
      setHasLegals(true);
    }
  }, [membersLegals]);

  useEffect(() => {
    if (legalCategories) {
      const optionsArray = Object.entries(legalCategories);
      const sortedOptions = optionsArray.sort(
        (a, b) => a[1].priority - b[1].priority
      );
      const categoryMapper = {};
      const sortedData = sortedOptions.map((option) => {
        categoryMapper[option[0]] = {
          value: option[1].key,
          label: option[1].name,
          index: option[1].priority - 1,
        };
        return categoryMapper[option[0]];
      });
      setCategoryMap(categoryMapper);
      setLegalType(sortedData[0]);
      dispatchLegal({ type: 'category', payload: sortedData[0].value });
      setTypeOptions(sortedData);
      setHasCategories(true);
    }
  }, [legalCategories]);

  useEffect(() => {
    if (hasMembersList) {
      const beneficiariesOptions = FilterMembersByContext(
        membersList,
        'instrument_beneficiary'
      );
      setBeneficiariesOptions(beneficiariesOptions);
      const membersOptions = FilterMembersByContext(
        membersList,
        'instrument_owner'
      );
      setMembersOptions(membersOptions);
    }
  }, [hasMembersList]);

  UseGenerateMembersList(
    currentHousehold,
    setMembersList,
    true,
    setHasMembersList
  );

  UseHandleInstrumentError(
    legalError,
    setButtonLoading,
    setModalError,
    setModalErrors,
    setErrorTitle,
    setLoadedData
  );

  const updateLegalType = (e) => {
    if (typeof e === 'string') e = categoryMap[e];
    setLegalType(e);
    dispatchLegal({ type: 'category', payload: e?.value || e });
  };

  const updateApplicableMembers = (e, overwrite) => {
    let values = [
      ...legalState.applicable_members,
      ...(Array.isArray(e) ? e : [e]),
    ];
    if (overwrite) {
      values = [...(Array.isArray(e) ? e : [e])];
    }
    // select or deselect?
    const match = legalState.applicable_members.some((am) => am.id === e.id);
    if (match) {
      values = legalState.applicable_members.filter((am) => am.id !== e.id);
      // update the membersList
      setMembersList(
        membersList.map((member) => {
          if (member.id === e.id) {
            member.isApplicable = false;
          }
          return member;
        })
      );
    }
    dispatchLegal({
      type: 'applicable_members',
      payload: TransformApplicables(
        FilterMembersByContext(membersList, 'instrument_owner'),
        values
      ).filter((member) => member.isApplicable === true),
    });
    setHasApplicables(true);
  };

  const updateEmpoweredMembers = (e, overwrite) => {
    let values = [
      ...legalState.empowered_members,
      ...(Array.isArray(e) ? e : [e]),
    ];
    if (overwrite) {
      values = [...(Array.isArray(e) ? e : [e])];
    }
    // select or deselect?
    const match = legalState.empowered_members.some((am) => am.id === e.id);
    if (match) {
      values = legalState.empowered_members.filter((am) => am.id !== e.id);
      // update the membersList
      setMembersList(
        membersList.map((member) => {
          if (member.id === e.id) {
            member.isEmpowered = false;
          }
          return member;
        })
      );
    }
    dispatchLegal({
      type: 'empowered_members',
      payload: TransformEmpowered(
        FilterMembersByContext(membersList, 'instrument_owner'),
        values
      ).filter((member) => member.isEmpowered === true),
    });
    setHasEmpowered(true);
  };

  const updateBeneficiaries = (e, overwrite) => {
    let values = [
      ...legalState.beneficiary_members,
      ...(Array.isArray(e) ? e : [e]),
    ];
    if (overwrite) {
      values = [...(Array.isArray(e) ? e : [e])];
    }
    // select or deselect?
    const match = legalState.beneficiary_members.some((am) => am.id === e.id);
    if (match) {
      values = legalState.beneficiary_members.filter((am) => am.id !== e.id);
      // update the membersList
      setMembersList(
        membersList.map((member) => {
          if (member.id === e.id) {
            member.isBeneficiary = false;
          }
          return member;
        })
      );
    }
    dispatchLegal({
      type: 'beneficiary_members',
      payload: TransformLegalBeneficiaries(
        FilterMembersByContext(membersList, 'instrument_beneficiary'),
        values
      ).filter((member) => member.isBeneficiary === true),
    });
    setHasBeneficiaries(true);
  };

  const changeValue = (e, field, overwrite = false) => {
    switch (field) {
      case 'category':
        updateLegalType(e);
        break;
      case 'applicable_members':
        updateApplicableMembers(e, overwrite);
        if (CheckHasError(modalErrors, 'applicable_members')) {
          setModalErrors(RemoveError(modalErrors, 'applicable_members'));
        }
        break;
      case 'reference_name':
      case 'location':
        dispatchLegal({
          type: field,
          payload:
            e?.target?.value !== null && e?.target?.value !== undefined
              ? e?.target?.value
              : e,
        });
        break;
      case 'empowered_members':
        updateEmpoweredMembers(e, overwrite);
        break;
      case 'beneficiary_members':
        updateBeneficiaries(e, overwrite);
        break;
      case 'date_created':
      case 'date_collected':
        dispatchLegal({
          type: field,
          payload: e !== '' ? DateFormat(e, preferences.date_format) : '',
        });
        break;
      default: // notes
        dispatchLegal({ type: field, payload: e });
        break;
    }
  };

  useEffect(() => {
    if (modalErrors) {
      if (!modalErrors || Object.entries(modalErrors).length === 0) {
        setModalError('');
      }
    }
  }, [modalErrors]);

  const validationChecker = (name) => {
    if (CheckHasError(modalErrors, name)) {
      const updatedErrors = RemoveError(modalErrors, name);
      setModalErrors(updatedErrors);
    }
  };

  const legalInputs = [
    {
      type: 'select',
      label: 'Type',
      name: 'category',
      required: true,
      placeholder: 'Type',
      value: legalType,
      options: typeOptions,
      width: '100%',
      isVisible: true,
      onChange: (e) => changeValue(e, 'category'),
      hasError: CheckHasError(modalErrors, 'category'),
      errorMessage: GenerateError(modalErrors, 'category'),
    },
    {
      type: 'select',
      label: 'Applicable Members',
      name: 'applicable_members',
      required: true,
      placeholder: 'Applicable members',
      customSelect: true,
      customComponent: LegalApplicableMemberOption,
      width: '100%',
      isVisible: true,
      value: { label: 'Select Members', value: '' },
      options: membersOptions,
      onChange: (e) => changeValue(e, 'applicable_members'),
      selectedOptions: legalState.applicable_members,
      showSelected: hasApplicables,
      hasSelected: true,
      tooltip: 'On whose behalf; e.g. Principal, Grantor, Testator',
      hasError: CheckHasError(modalErrors, 'applicable_members'),
      errorMessage: GenerateError(modalErrors, 'applicable_members'),
    },
    {
      type: 'text',
      label: 'Reference Name',
      name: 'reference_name',
      required: true,
      placeholder: 'e.g. Will, Trust, POA',
      value: legalState.reference_name,
      onChange: (e) => changeValue(e, 'reference_name'),
      isVisible: true,
      width: '100%',
      hasError: CheckHasError(modalErrors, 'reference_name'),
      errorMessage: GenerateError(modalErrors, 'reference_name'),
      onFocus: () => validationChecker('reference_name'),
    },
    {
      type: 'text',
      label: 'Location',
      name: 'location',
      required: true,
      placeholder: "e.g. Institution, Attorney's Office",
      value: legalState.location,
      onChange: (e) => changeValue(e, 'location'),
      isVisible: true,
      width: '100%',
      hasError: CheckHasError(modalErrors, 'location'),
      errorMessage: GenerateError(modalErrors, 'location'),
      onFocus: () => validationChecker('location'),
    },
    {
      type: 'select',
      label: 'Empowered Members',
      name: 'empowered_members',
      placeholder: 'Empowered members',
      customSelect: true,
      customComponent: LegalEmpoweredMemberOption,
      width: '100%',
      isVisible: true,
      value: { label: 'Select Members', value: '' },
      options: membersOptions,
      onChange: (e) => changeValue(e, 'empowered_members'),
      selectedOptions: legalState.empowered_members,
      showSelected: hasEmpowered,
      hasSelected: true,
      tooltip:
        'The authorized or responsible party, e.g. executor, trustee, agent, attorney-in-fact, personal representative',
      hasError: CheckHasError(modalErrors, 'empowered_members'),
      errorMessage: GenerateError(modalErrors, 'empowered_members'),
    },
    {
      type: 'select',
      label: 'Beneficiaries',
      name: 'beneficiary_members',
      placeholder: 'Beneficiaries',
      customSelect: true,
      customComponent: BeneficiaryOption,
      width: '100%',
      isVisible: true,
      value: { label: 'Select Members', value: '' },
      options: beneficiariesOptions,
      onChange: (e) => changeValue(e, 'beneficiary_members'),
      selectedOptions: legalState.beneficiary_members,
      showSelected: hasBeneficiaries,
      hasSelected: true,
      hasError: CheckHasError(modalErrors, 'beneficiary_members'),
      errorMessage: GenerateError(modalErrors, 'beneficiary_members'),
    },
    {
      type: 'date',
      label: 'Date Executed',
      name: 'date_created',
      width: '48%',
      required: false,
      placeholder: 'mm/yyyy',
      dateFormat: 'MM/yyyy',
      value: legalState.date_created,
      isVisible: true,
      onChange: (e) => changeValue(e, 'date_created'),
      hasError: CheckHasError(modalErrors, 'date_created'),
      errorMessage: GenerateError(modalErrors, 'date_created'),
    },
    {
      type: 'date',
      label: 'Data Collected',
      name: 'date_collected',
      width: '48%',
      required: false,
      placeholder: 'mm/dd/yyyy',
      dateFormat: 'MM/DD/YYYY',
      value: legalState.date_collected,
      isVisible: true,
      onChange: (e) => changeValue(e, 'date_collected'),
      hasError: CheckHasError(modalErrors, 'date_collected'),
      errorMessage: GenerateError(modalErrors, 'date_collected'),
    },
    {
      type: 'textarea',
      label: 'Notes',
      name: 'notes',
      showClear: true,
      id: 'asset',
      value: legalState.notes,
      onChange: (e) => changeValue(e, 'notes'),
      hasError: CheckHasError(modalErrors, 'notes'),
      errorMessage: GenerateError(modalErrors, 'notes'),
    },
  ];

  // EDIT
  useEffect(() => {
    if (modalState === 'edit' && hasLegal && hasMembersList) {
      for (let field in legal) {
        changeValue(legal[field], field);
      }
      setLoadedData(true);
    }
  }, [modalState, hasLegal, hasMembersList]);

  const onSaveLegal = () => {
    setButtonLoading(true);
    setModalErrors();
    if (modalState === 'add') {
      dispatch(
        createLegal(
          householdId,
          legalState,
          categoryMap[legalState.category].label
        )
      );
    } else {
      dispatch(
        updateLegal(
          householdId,
          legal.id,
          legalState,
          categoryMap[legalState.category].label
        )
      );
    }
  };

  const onDeleteLegal = () => {
    dispatch(deleteLegal(householdId, legal.id));
  };

  // LIST
  const makeLegalsList = (legals, members) => {
    const list = [...legals].map((legal) => {
      return {
        id: legal.id,
        members: [...legal.applicable_members.filter(Boolean)].map((am) => {
          return [...members].filter((member) => member.uuid === am.uuid)[0];
        }),
        name: legal?.reference_name,
        year: DateFormat(legal?.date_created, 'YYYY'),
      };
    });
    setLegalsList(list);
  };

  useEffect(() => {
    if (modalState === 'list' && hasLegals && hasMembersList && hasCategories) {
      setLoadedData(true);
      makeLegalsList(membersLegals, membersList);
    }
  }, [modalState, hasLegals, hasMembersList, hasCategories]);

  const handleClickLegal = (legal) => {
    setModalState('edit');
    setLoadedData(false);
    setHasLegal(false);
    dispatch(getLegal(currentHousehold.id, legal.id));
    setShowBackButton(true);
  };

  const handleClickCategory = (category) => {
    setModalState('add');
    setShowBackButton(true);
    updateLegalType(category.value);
  };

  const handleClickBack = () => {
    setModalState('list');
    setShowBackButton(false);
    setMembersList(
      membersList.map((member) => {
        member.isSelected = false;
        member.isApplicable = false;
        member.isEmpowered = false;
        member.isBeneficiary = false;
        delete member.ratio;
        return member;
      })
    );
    for (let field in initLegalState) {
      changeValue(initLegalState[field], field, true);
    }
  };

  const generateHeader = () => {
    if (modalState === 'list') {
      return <HeaderSection>Summary</HeaderSection>;
    }
    if (modalState !== 'list') {
      if (showBackButton) {
        return (
          <HeaderSection>
            <BackButton>
              <img
                src={arrowBack}
                alt={'back'}
                data-image="back-arrow"
                onClick={() => handleClickBack()}
              />
              Back to Summary
            </BackButton>
          </HeaderSection>
        );
      }
      return '';
    }
  };

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEsc={hide} />
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>{modalHeader}</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalLoadingOverlayContainer>
            {!loadedData && (
              <LoadingOverlayContainer>
                <LoadingOverlay>
                  <LoadingAnimation />
                  {/* <LoadingAnimation smaller={true} /> */}
                  {/* <p>Loading</p> */}
                </LoadingOverlay>
              </LoadingOverlayContainer>
            )}
            <ModalContentContainer>
              {generateHeader()}
              {modalState !== 'list' && (
                <>
                  <Section>
                    <TabContent>
                      {legalInputs.map((input, index) => {
                        return GenerateInput(input, index);
                      })}
                    </TabContent>
                  </Section>
                  <ModalButtons
                    isNew={modalState === 'add'}
                    hide={hide}
                    //   isHidden={buttonContent.isHidden}
                    saveFunction={onSaveLegal}
                    showLoading={buttonLoading}
                    loadingText={modalState === 'add' ? 'Creating' : 'Saving'}
                    deleteFunction={onDeleteLegal}
                  />
                </>
              )}
              {modalState === 'list' && (
                <>
                  {/* <ModalTabs
                  tabs={modalTabOptions}
                  activeTab={activeTab}
                  onClick={setActiveTab}
                /> */}
                  <SectionWithBottomBorder>
                    <SectionHeader>Active</SectionHeader>
                    <ActiveLegals>
                      {legalsList.map((legal, index) => {
                        return (
                          <LegalRow
                            key={index}
                            onClick={() => handleClickLegal(legal)}
                          >
                            <LegalLeft>
                              <LegalName>{legal.name}</LegalName>
                              <LegalYear>
                                {legal.year ? '(' + legal.year + ')' : ''}
                              </LegalYear>
                            </LegalLeft>
                            <LegalRight>
                              {legal.members.filter(Boolean).map((member, index) => {
                                return (
                                  <LegalMember
                                    title={member.name}
                                    key={index}
                                    src={GenerateAvatarMemberIconOld(member)}
                                    alt="user"
                                  />
                                );
                              })}
                              <ArrowButton
                                onClick={() => handleClickLegal(legal)}
                              >
                                <img
                                  src={chevronRight}
                                  alt="arrow"
                                  data-image="arrow-right"
                                />
                              </ArrowButton>
                            </LegalRight>
                          </LegalRow>
                        );
                      })}
                    </ActiveLegals>
                  </SectionWithBottomBorder>
                  {hasCategories && (
                    <Section>
                      <SectionHeader>Add</SectionHeader>
                      <ActiveAddables>
                        {Object.values(categoryMap).map((category, index) => {
                          return (
                            <LegalRow
                              key={index}
                              onClick={() => handleClickCategory(category)}
                            >
                              <LegalLeft>
                                <LegalCategory>{category.label}</LegalCategory>
                              </LegalLeft>
                              <LegalRight>
                                <PlusButton
                                  onClick={() => handleClickCategory(category)}
                                >
                                  <img
                                    src={greyCirclePlus}
                                    alt="plus"
                                    data-image="plus"
                                  />
                                </PlusButton>
                              </LegalRight>
                            </LegalRow>
                          );
                        })}
                      </ActiveAddables>
                      <ButtonContainer>
                        <Button
                          text={'Close'}
                          onClick={() => hide()}
                          alt="close"
                          data-image="close"
                          theme={ButtonThemes.primary}
                        />
                      </ButtonContainer>
                    </Section>
                  )}
                </>
              )}
            </ModalContentContainer>
          </ModalLoadingOverlayContainer>
          {modalError && modalError !== '' && (
            <Error errorMessage={modalError} title={errorTitle} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const BackButton = styled.div`
  font-size: 11px;
  text-align: left;
  align-items: center;
  display: flex;
  img {
    cursor: pointer;
    width: 12px;
    height: 12px;
    color: black;
    padding-right: 5px;
  }
`;

const HeaderSection = styled.div`
  font-size: 11px;
  text-align: left;
  font-weight: 600;
  padding: 5px 0px 15px;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const SectionHeader = styled.div`
  font-weight: 600;
`;

const SectionWithBottomBorder = styled.div`
  font-size: 11px;
  text-align: left;
  padding: 10px 0px;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const ActiveLegals = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;

const LegalRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colors.lightGrey};
  margin-bottom: 10px;
  &:hover {
    transform: scale(1.01);
    background-color: ${colors.grey};
    cursor: pointer;
  }
`;

const LegalLeft = styled.div`
  display: flex;
  padding-left: 5px;
  align-items: center;
`;

const LegalRight = styled.div`
  display: flex;
  padding-right: 5px;
  align-items: center;
`;

const LegalName = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const LegalYear = styled.div`
  color: ${colorsRGB.paleGrey};
  padding-left: 2px;
  font-size: 10px;
`;

const LegalMember = styled.img`
  max-width: 12px;
  width: 100%;
  max-height: 12px;
  height: 100%;
  margin-right: 4px;
  padding-right: 2px;
`;

const ArrowButton = styled.button`
  img {
    width: 10px;
    height: 10px;
  }
`;

const Section = styled.div`
  font-size: 11px;
  text-align: left;
  padding: 10px 0px;
`;

const ActiveAddables = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;

const LegalCategory = styled.div`
  font-size: 12px;
  color: ${colorsRGB.paleGrey};
`;

const PlusButton = styled.button`
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
`;

const ButtonContainer = styled.div`
  flex: 1 1 auto;
  text-align: right;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  button {
    width: 120px;
  }
`;

LegalModal.propTypes = {
  isNew: PropTypes.bool,
  isList: PropTypes.bool,
  hide: PropTypes.func,
  householdId: PropTypes.string,
};

export default LegalModal;
