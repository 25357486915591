/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';
import { HtmlToString, GenerateAge, FormatCurrency } from '.';

//CUSTOM FUNCTION TO GENERATE TRANSFORMED MEMBER LIST FOR MODALS
export const UseGenerateMembersList = (
  currentHousehold,
  setMembersList,
  addAge = false,
  setLoadedMembers
) => {
  useEffect(() => {
    if (currentHousehold?.allHouseholdMembers) {
      const householdMembersList = [...currentHousehold.allHouseholdMembers];
      const createdMemberList = [...householdMembersList].map((member) => {
        const mem = { ...member };
        const transformedName = HtmlToString(mem.name);
        mem.label = transformedName;
        mem.value = mem.id;

        if (!mem.hasOwnProperty('ratio')) {
          if (currentHousehold.allHouseholdMembers.length === 1) {
            mem.isSelected = true;
          } else {
            mem.isSelected = false;
          }
        }
        if (!mem.hasOwnProperty('isApplicable')) {
          mem.isApplicable = false;
        }
        if (!mem.hasOwnProperty('isBeneficiary')) {
          mem.isBeneficiary = false;
        }
        if (!mem.hasOwnProperty('isContingentBeneficiary')) {
          mem.isContingentBeneficiary = false;
        }
        if (!mem.hasOwnProperty('isEmpowered')) {
          mem.isEmpowered = false;
        }
        if (!mem.hasOwnProperty('ratio')) {
          mem.ratio = 0;
        }
        if (!mem.hasOwnProperty('beneficiary_ratio')) {
          mem.beneficiary_ratio = 0;
        }
        if (!mem.hasOwnProperty('contingent_beneficiary_ratio')) {
          mem.contingent_beneficiary_ratio = 0;
        }
        if (mem.date_of_birth) {
          mem.calculated_age = GenerateAge(mem.date_of_birth);
        } else {
          mem.calculated_age = false;
        }
        if (mem.member_type === 'member') {
          mem.age = mem.calculated_age;
        } else {
          mem.age = false;
        }
        if (addAge) {
          if ((mem.age || mem.age === 0) && mem?.member_type === 'member') {
            if (mem.date_of_birth_estimated) {
              mem.label = `${transformedName} (${mem.age}e)`;
            } else {
              mem.label = `${transformedName} (${mem.age})`;
            }
          }
        }
        return mem;
      });
      setMembersList([...createdMemberList]);
      setLoadedMembers(true);
    }
  }, [currentHousehold?.allHouseholdMembers]);
};

//CUSTOM FUNCTION TO GENERATE TRANSFORMED MEMBER LIST FOR MODALS
export const UseGenerateAssetsList = (
  currentHousehold,
  setAssetList,
  currency,
  includeCashValues = false
) => {
  useEffect(() => {
    if (currentHousehold?.instruments) {
      let assets = currentHousehold.instruments.filter(
        (instrument) => instrument.instrument_type === 'asset'
      );
      assets = assets.map((assetIns) => {
        const asset = { ...assetIns };
        asset.label = asset.name;
        asset.value = asset.id;
        if (currency) {
          asset.formattedValue = FormatCurrency(asset.amount, currency);
        }
        return asset;
      });
      let cashValueInsurances = [];
      if (includeCashValues) {
        cashValueInsurances = currentHousehold.instruments.reduce(
          (acc, curr) => {
            if (curr.instrument_type === 'insurance' && curr.cash_value) {
              return [...acc, { label: `${curr.name} (cv)`, value: curr.id }];
            }
            return acc;
          },
          []
        );
      }
      const assetList = [...assets, ...cashValueInsurances];
      if (currency) {
        setAssetList([...assetList]);
      } else {
        setAssetList([{ label: 'None', value: null }, ...assetList]);
      }
    }
  }, [currentHousehold?.instruments]);
};

//CUSTOM HOOK TO TRANSFORM ANY RATIO LIST AND RATIO PERCENT
export const UseTransformRatioList = (
  instrument,
  isNew,
  setRatioList,
  setHasList,
  setModalError,
  setModalErrors,
  keyName = 'owners',
  selectedKey = 'isSelected',
  ratioKey = 'ratio'
) => {
  useEffect(() => {
    if (instrument[keyName] && instrument[keyName].length !== 0) {
      const hasMatched = instrument[keyName].some((item) => item.isSelected);
      const matchingItems = instrument[keyName].filter(
        (item) => item[selectedKey]
      );
      if (isNew) {
        const matchingLength = matchingItems.length;
        if (hasMatched) {
          const updatedList = matchingItems.map((item) => {
            item[ratioKey] = Math.round(100 / matchingLength);
            return item;
          });
          setRatioList(updatedList);
        }
      } else {
        setRatioList(matchingItems);
      }

      setHasList(hasMatched);
      setModalError('');
      setModalErrors();
    }
  }, [instrument[keyName]]);
};

//CUSTOM HOOK TO TRANSFORM OWNERS LIST AND RATIO PERCENT
export const UseTransformOwnersList = (
  instrument,
  isNew,
  setOwnersList,
  setHasOwners,
  setModalError,
  setModalErrors
) => {
  useEffect(() => {
    if (instrument.owners && instrument.owners.length !== 0) {
      const hasMatched = instrument.owners.some((member) => member.isSelected);
      let selectedOwners = instrument.owners.filter(
        (owner) => owner.isSelected
      );
      selectedOwners = selectedOwners.map((owner) => {
        owner.name = HtmlToString(owner.name);
        return owner;
      });
      if (isNew) {
        const ownerLength = selectedOwners.length;
        if (hasMatched) {
          const updatedOwners = selectedOwners.map((owner) => {
            owner.ratio = Math.round(100 / ownerLength);
            return owner;
          });
          setOwnersList(updatedOwners);
        }
      } else {
        const existingOwners = instrument.owners.filter(
          (owner) => owner.owner_existing
        );
        const newMembers = selectedOwners.filter((mem) => {
          return !mem.owner_existing;
        });
        if (newMembers && newMembers.length !== 0) {
          if (newMembers.length === 1 && selectedOwners.length === 1) {
            const updatedNew = newMembers.map((owner) => {
              owner.ratio = 100;
              return owner;
            });
            setOwnersList([...existingOwners, ...updatedNew]);
          } else {
            const existingTotal = existingOwners.reduce((acc, curr) => {
              return parseInt(acc) + parseInt(curr.ratio);
            }, 0);
            const remainingTotal = 100 - existingTotal;
            const updatedNew = newMembers.map((owner) => {
              owner.ratio = Math.round(remainingTotal / newMembers.length);
              return owner;
            });
            setOwnersList([...existingOwners, ...updatedNew]);
          }
        }
        setOwnersList(selectedOwners);
      }

      setHasOwners(hasMatched);
      setModalError('');
      setModalErrors();
    }
  }, [instrument.owners]);
};

//CUSTOM HOOK TO TRANSFORM BENEFICIARY LIST AND RATIO PERCENT
export const UseTransformBeneficiaryList = (
  instrument,
  isNew,
  setBeneficiaryList,
  setHasBeneficiary,
  setModalError,
  setModalErrors
) => {
  useEffect(() => {
    if (instrument.beneficiaries && instrument.beneficiaries.length !== 0) {
      const hasMatched = instrument.beneficiaries.some(
        (member) => member.isBeneficiary
      );
      let selectedBeneficiaries = instrument.beneficiaries.filter(
        (member) => member.isBeneficiary
      );
      selectedBeneficiaries = selectedBeneficiaries.map((member) => {
        member.name = HtmlToString(member.name);
        return member;
      });

      if (isNew) {
        const beneficiaryLength = selectedBeneficiaries.length;
        if (hasMatched) {
          const updatedBeneficiaries = selectedBeneficiaries.map(
            (beneficiary) => {
              beneficiary.beneficiary_ratio = Math.round(
                100 / beneficiaryLength
              );
              return beneficiary;
            }
          );
          setBeneficiaryList(updatedBeneficiaries);
        }
      } else {
        const existingBeneficiaries = instrument.beneficiaries.filter(
          (bene) => bene.beneficiary_existing
        );
        const newMembers = selectedBeneficiaries.filter((mem) => {
          return !mem.beneficiary_existing;
        });
        if (newMembers && newMembers.length !== 0) {
          const existingTotal = existingBeneficiaries.reduce((acc, curr) => {
            return parseInt(acc) + parseInt(curr.beneficiary_ratio);
          }, 0);
          const remainingTotal = 100 - existingTotal;
          const updatedNew = newMembers.map((bene) => {
            bene.beneficiary_ratio = Math.round(
              remainingTotal / newMembers.length
            );
            return bene;
          });
          setBeneficiaryList([...existingBeneficiaries, ...updatedNew]);
        }
        setBeneficiaryList(selectedBeneficiaries);
      }

      setHasBeneficiary(hasMatched);
      setModalError('');
      setModalErrors();
    }
  }, [instrument.beneficiaries]);
};

//CUSTOM HOOK TO TRANSFORM CONTINGENT BENEFICIARY LIST AND RATIO PERCENT
export const UseTransformContingentBeneficiaryList = (
  instrument,
  isNew,
  setBeneficiaryList,
  setHasBeneficiary,
  setModalError,
  setModalErrors
) => {
  useEffect(() => {
    if (
      instrument.contingent_beneficiaries &&
      instrument.contingent_beneficiaries.length !== 0
    ) {
      const hasMatched = instrument.contingent_beneficiaries.some(
        (member) => member.isContingentBeneficiary
      );
      let selectedBeneficiaries = instrument.contingent_beneficiaries.filter(
        (member) => member.isContingentBeneficiary
      );
      selectedBeneficiaries = selectedBeneficiaries.map((member) => {
        member.name = HtmlToString(member.name);
        return member;
      });

      if (isNew) {
        const beneficiaryLength = selectedBeneficiaries.length;
        if (hasMatched) {
          const updatedBeneficiaries = selectedBeneficiaries.map(
            (beneficiary) => {
              beneficiary.contingent_beneficiary_ratio = Math.round(
                100 / beneficiaryLength
              );
              return beneficiary;
            }
          );
          setBeneficiaryList(updatedBeneficiaries);
        }
      } else {
        const existingBeneficiaries =
          instrument.contingent_beneficiaries.filter(
            (bene) => bene.contingent_beneficiary_existing
          );
        const newMembers = selectedBeneficiaries.filter((mem) => {
          return !mem.contingent_beneficiary_existing;
        });
        if (newMembers && newMembers.length !== 0) {
          const existingTotal = existingBeneficiaries.reduce((acc, curr) => {
            return parseInt(acc) + parseInt(curr.contingent_beneficiary_ratio);
          }, 0);
          const remainingTotal = 100 - existingTotal;
          const updatedNew = newMembers.map((bene) => {
            bene.contingent_beneficiary_ratio = Math.round(
              remainingTotal / newMembers.length
            );
            return bene;
          });
          setBeneficiaryList([...existingBeneficiaries, ...updatedNew]);
        }
        setBeneficiaryList(selectedBeneficiaries);
      }

      setHasBeneficiary(hasMatched);
      setModalError('');
      setModalErrors();
    }
  }, [instrument.contingent_beneficiaries]);
};

export const UseSyncIntegrationContent = (
  activeTab,
  setShowSyncButton,
  integrations,
  setLastHouseholdSync
) => {
  useEffect(() => {
    if (activeTab || integrations) {
      let showSyncButton = false;
      if (!integrations?.available) {
        return;
      }
      for (const [partner, integration] of Object.entries(
        integrations.partners
      )) {
        if (
          partner === activeTab &&
          integration.enabled &&
          integration.sync.available
        ) {
          showSyncButton = true;
          setLastHouseholdSync(integration.sync.last);
        }
      }
      setShowSyncButton(showSyncButton);
    }
  }, [activeTab, integrations]);
};

//CUSTOM HOOK TO MAKE API CALL TO FETCH INTEGRATION CONTENT
export const UseFetchInstrumentIntegrationContent = (
  modalTabs,
  activeTab,
  integrationOptions,
  setSelectedIntegration,
  setLoadingIntegrationContent,
  apiCall,
  setIntegrationContent
) => {
  useEffect(() => {
    if (activeTab) {
      if (!modalTabs.map((tab) => tab.value).includes(activeTab)) {
        let matchedIntegration = integrationOptions.find(
          (int) => int.id === activeTab
        );
        if (matchedIntegration) {
          setSelectedIntegration(matchedIntegration);
          setLoadingIntegrationContent(true);
          return apiCall();
        }
      } else {
        return setIntegrationContent();
      }
    }
  }, [activeTab]);
};

//CUSTOM HOOK TO SET INTEGRATION CONTENT RETURNED FROM API CALL
export const UseSetInstrumentIntegration = (
  instrumentIntegration,
  setLoadingIntegrationContent,
  setIntegrationContent
) => {
  useEffect(() => {
    if (instrumentIntegration) {
      setLoadingIntegrationContent(false);
      setIntegrationContent(instrumentIntegration);
    }
  }, [instrumentIntegration]);
};

//CUSTOM HOOK TO HANDLE ERROR DISPLAY
export const UseHandleInstrumentError = (
  instrumentError,
  setButtonLoading,
  setModalError,
  setModalErrors,
  setErrorTitle,
  setLoadedInstrument
) => {
  useEffect(() => {
    if (instrumentError) {
      setButtonLoading(false);
      if (setLoadedInstrument) {
        setLoadedInstrument(true);
      }
      if (instrumentError?.data) {
        if (instrumentError.data?.message) {
          return setModalError(instrumentError.data?.message);
        }
        setModalErrors(instrumentError?.data);
        const objectKeys = Object.keys(instrumentError?.data);
        const errorTitle = `Error Fields: ${objectKeys.toString()}`;
        setErrorTitle(errorTitle);
        const length = Object.keys(instrumentError?.data).length;
        if (length === 1) {
          return setModalError(
            instrumentError.data[Object.keys(instrumentError.data)[0]]
          );
        }
      }
      return setModalError('Please fix errors and try saving again.');
    } else {
      return setModalError('');
    }
  }, [instrumentError]);
};

//CUSTOM HOOK TO SET INSTRUMENT INTEGRATION ERROR
export const UseHandleInstrumentIntegrationError = (
  instrumentIntegrationError,
  setLoadingIntegrationContent,
  setIntegrationError
) => {
  useEffect(() => {
    if (instrumentIntegrationError) {
      setLoadingIntegrationContent(false);
      setIntegrationError(instrumentIntegrationError);
    } else {
      setIntegrationError();
    }
  }, [instrumentIntegrationError]);
};

// //HELPER FUNCTION TO CREATE GROUPS FOR CASHFLOW SELECT DROPDOWN
// const generateCashflowTypes = (instrumentTypes, setList) => {
//   const filteredTypes = instrumentTypes.reduce((acc, curr) => {
//     let split = curr.value.split('.');
//     curr.group = split[1];
//     if (split.length === 3) {
//       return [...acc, curr];
//     }
//     return acc;
//   }, []);
//   console.log('filteredTypes', filteredTypes);
//   let groupedOptions = [
//     {
//       label: 'Earned',
//       options: filteredTypes.filter((item) => item.group === 'earned'),
//     },
//     {
//       label: 'Unearned',
//       options: filteredTypes.filter((item) => item.group === 'unearned'),
//     },
//     {
//       label: 'Expense',
//       options: filteredTypes.filter((item) => item.group === 'expense'),
//     },
//   ];
//   setList(groupedOptions);
// };

// //CUSTOM HOOK THAT TAKES INSTRUMENT TYPES FROM CONFIGS AND FORMATS THEM FOR SELECT DROPDOWNS
// const UseFormatInstrumentTypes = (instrumentTypes, instrumentType, setList) => {
//   useEffect(() => {
//     if (instrumentTypes) {
//       if (instrumentType === 'income') {
//         return generateCashflowTypes(instrumentTypes, setList);
//       }
//       let filtered = instrumentTypes.filter(
//         (type) => type.type === instrumentType
//       );
//       let mapped = filtered.map((type) => {
//         type.label = type.name;
//         type.value = type.key;
//         return type;
//       });
//       return setList(mapped);
//     }
//   }, [instrumentTypes]);
// };

//CHECKS IF CERTAIN FIELD HAS ERROR
export const CheckHasError = (modalErrors, name) => {
  if (modalErrors) {
    if (modalErrors[name]) {
      return true;
    }
  }
  return false;
};

//RETURNS ERROR TEXT FROM SPECIFIED KEY NAME
export const GenerateError = (modalErrors, name) => {
  if (modalErrors) {
    if (modalErrors[name]) {
      return modalErrors[name][0];
    }
  }
  return 'Error';
};

export const RemoveError = (modalErrors, name) => {
  let newModalErrors = modalErrors;
  if (modalErrors) {
    newModalErrors = {};
    Object.entries(modalErrors).forEach(([key, errs]) => {
      if (key !== name) {
        newModalErrors[key] = errs;
      }
    });
  }
  return newModalErrors;
};

//TRANSFORMS HOUSEHOLD MEMBERS LIST TO OWNERS LIST
export const TransformOwners = (
  membersList,
  selectedOwners,
  isEntity = false
) => {
  return membersList.map((member) => {
    selectedOwners.map((owner) => {
      if (isEntity) {
        if (owner.id === member.id) {
          member.ratio = parseFloat(owner.ratio);
          member.isSelected = true;
          member.type = 'owner';
          member.owner_existing = true;
        }
        return owner;
      }
      if (owner.relation === 'owner') {
        if (owner.id === member.id) {
          member.ratio = owner.ratio;
          member.isSelected = true;
          member.type = 'owner';
          member.owner_existing = true;
        }
        return owner;
      }
    });
    return member;
  });
};

export const TransformApplicables = (membersList, selectedApplicables) => {
  return membersList.map((member) => {
    selectedApplicables.map((applicable) => {
      if (applicable.uuid === member.uuid) {
        member.isApplicable = true;
      }
      return applicable;
    });
    return member;
  });
};

export const TransformEmpowered = (membersList, selectedEmpowered) => {
  return membersList.map((member) => {
    selectedEmpowered.map((empowered) => {
      if (empowered.uuid === member.uuid) {
        member.isEmpowered = true;
      }
      return empowered;
    });
    return member;
  });
};

export const TransformLegalBeneficiaries = (membersList, selectedMembers) => {
  return membersList.map((member) => {
    selectedMembers.map((mem) => {
      if (mem.uuid === member.uuid) {
        member.isBeneficiary = true;
      }
      return mem;
    });
    return member;
  });
};

//TRANSFORMS HOUSEHOLD MEMBERS LIST TO BENEFICIARY LIST
export const TransformBeneficiaries = (membersList, selectedMembers) => {
  return membersList.map((member) => {
    selectedMembers.map((mem) => {
      if (mem.relation === 'beneficiary') {
        if (mem.id === member.id) {
          member.beneficiary_ratio = mem.ratio;
          member.isBeneficiary = true;
          member.type = 'beneficiary';
          member.beneficiary_existing = true;
        }
        return mem;
      }
    });
    return member;
  });
};

//TRANSFORMS HOUSEHOLD MEMBERS LIST TO CONTINGENT BENEFICIARY LIST
export const TransformContingentBeneficiaries = (
  membersList,
  selectedMembers
) => {
  return membersList.map((member) => {
    selectedMembers.map((mem) => {
      if (mem.relation === 'contingent_beneficiary') {
        if (mem.id === member.id) {
          member.contingent_beneficiary_ratio = mem.ratio;
          member.isContingentBeneficiary = true;
          member.type = 'contingent_beneficiary';
          member.contingent_beneficiary_existing = true;
        }
        return mem;
      }
    });
    return member;
  });
};

export const TransformInsured = (membersList, selectedMembers) => {
  return membersList.map((member) => {
    selectedMembers.map((mem) => {
      if (mem.relation === 'insured') {
        if (mem.id === member.id) {
          member.insured_ratio = 0;
          member.isInsured = true;
          member.type = 'insured';
        }
        return mem;
      }
    });
    return member;
  });
};

//RETURNS MATCHING STATE OBJECT FROM LIST GIVEN STATE ID
export const MatchState = (id, states, setMatch) => {
  const matched = states.find((state) => state.code === id);
  if (matched) {
    return setMatch({
      label: matched.name,
      value: matched.code,
    });
  }
};

//RETURNS MATCHING COUNTRY OBJECT FROM LIST GIVEN COUNTRY ID
export const MatchCountry = (id, countries, setMatch) => {
  const matched = countries.find((country) => country.value === id);
  if (matched) {
    return setMatch(matched);
  }
};
