/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Select, Error, DetectModalKeys, TextInput } from '..';
import {
  getAssetMapLayouts,
  createAssetMapLayout,
  toggleShowDeleteModal,
  updateLayoutMembers,
} from '../../store/actions';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ButtonsRowContainer,
  ButtonContainer,
} from '../../styles/library/modalStyles';
import { timesWhite } from '../../assets';
import {
  AMRLayoutsOption,
  DetectEnterKeyPress,
  HtmlToString,
} from '../../utils';
import { ButtonThemes } from '../../styles/themes';
import { colors, fonts } from '../../styles/variables';

const LayoutsModal = ({ hide, householdId, selectedMembers, isConsumer }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { assetMapLayouts, householdmembers, assetmapLayoutError } =
    useSelector((state) => ({
      assetMapLayouts: state.households.assetMapLayouts,
      householdmembers: state.households.currentHousehold.allHouseholdMembers,
      assetmapLayoutError: state.households.assetmapLayoutError,
    }));
  const [layoutOptions, setLayoutOptions] = useState([]);
  const [selectedLayout, setSelectedLayout] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [createNewLayout, setCreateNewLayout] = useState(false);
  const [layoutName, setLayoutName] = useState('');
  const [layoutError, setLayoutError] = useState('');

  useEffect(() => {
    dispatch(getAssetMapLayouts(householdId, isConsumer));
  }, []);

  useEffect(() => {
    if (assetMapLayouts && householdmembers) {
      setLayoutOptions(
        assetMapLayouts.map((layout) => {
          const membersList = layout?.members.map((member) => {
            const matchingMem = householdmembers.find(
              (mem) => mem.id === member
            );
            member = matchingMem ? HtmlToString(matchingMem?.name) : 'Unknown';
            return member;
          });

          let description = '';
          if (membersList.length > 2) {
            description = `${membersList.length} Members`;
          } else {
            description = membersList.join(', ');
          }
          layout.label = `${layout.name} (${description})`;
          layout.value = layout.id;
          layout.description = description;
          return layout;
        })
      );
    }
  }, [assetMapLayouts, householdmembers]);

  useEffect(() => {
    if (layoutOptions.length !== 0) {
      const defaultLayout = layoutOptions.find((layout) => layout.is_default);
      setSelectedLayout(defaultLayout);
    }
  }, [layoutOptions]);

  useEffect(() => {
    if (assetmapLayoutError) {
      setLayoutError(assetmapLayoutError?.data || 'Error updating layout.');
    }
  }, [assetmapLayoutError]);

  const applyLayout = () => {
    if (!buttonLoading) {
      if (createNewLayout) {
        if (
          layoutName === '' ||
          layoutName === null ||
          layoutName === undefined
        ) {
          return setLayoutError('Please provide a layout name.');
        }
        setLayoutError('');
        const layoutObj = {
          is_default: false,
          members: selectedMembers.map((mem) => mem.id),
          name: layoutName,
          source: 'assetmap',
          target: '',
        };
        setButtonLoading(true);
        dispatch(createAssetMapLayout(householdId, layoutObj, isConsumer));
      } else {
        if (selectedLayout && selectedLayout?.value) {
          setButtonLoading(true);
          dispatch(updateLayoutMembers(selectedLayout.members));
          return hide();
        }
      }
    }
  };

  const deleteLayout = (layout) => {
    return dispatch(
      toggleShowDeleteModal(true, 'amrLayout', layout.label, layout.value)
    );
  };

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEnter={applyLayout} onEsc={hide} />
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>Layouts</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <ModalText htmlFor="existing">
              <input
                type="radio"
                id="existing"
                name="layout"
                value="existing"
                checked={!createNewLayout}
                onChange={() => {
                  setCreateNewLayout(false);
                  setLayoutError('');
                }}
              />
              Select an existing Layout
            </ModalText>
            <TabContent>
              {!createNewLayout && (
                <SelectContainer>
                  <Select
                    selectedOption={selectedLayout}
                    onChange={(e) =>
                      setSelectedLayout({
                        label: e.label,
                        value: e.value,
                        members: e.members,
                      })
                    }
                    components={{
                      Option: (e) => AMRLayoutsOption(e, deleteLayout),
                    }}
                    options={layoutOptions}
                    isSearchable={true}
                    placeholder={'Select a Layout'}
                    autoFocus={true}
                    isDisabled={false}
                    closeMenuOnSelect={true}
                    onEnter={applyLayout}
                  />
                </SelectContainer>
              )}
            </TabContent>
            <SubText>OR</SubText>
            <ModalText htmlFor="new">
              <input
                type="radio"
                id="new"
                name="layout"
                value="new"
                checked={createNewLayout}
                onChange={() => setCreateNewLayout(true)}
              />
              Save Current Layout
            </ModalText>
            {createNewLayout && (
              <>
                <TextInputContainer>
                  <TextInput
                    type={'text'}
                    name={'name'}
                    maxLength={'100'}
                    hasError={layoutError !== ''}
                    placeholder={'Layout Name'}
                    value={layoutName}
                    onKeyPress={(e) => DetectEnterKeyPress(e, applyLayout)}
                    onChange={(e) => {
                      setLayoutError('');
                      setLayoutName(e.currentTarget.value);
                    }}
                  />
                </TextInputContainer>
                <SelectedMembers>
                  {selectedMembers.map((member, index) => {
                    return (
                      <LinkedLabel key={index}>
                        <span>{member.name}</span>
                        {member.member_type === 'member' && (
                          <MemberAge
                            style={
                              member.date_of_birth_estimated
                                ? { fontStyle: 'italic' }
                                : null
                            }
                          >
                            {member.age}
                            {member.date_of_birth_estimated && 'e'}
                          </MemberAge>
                        )}
                      </LinkedLabel>
                    );
                  })}
                </SelectedMembers>
              </>
            )}
            <ButtonsRowContainer>
              <ButtonContainer>
                <Button
                  text={'Cancel'}
                  onClick={() => hide()}
                  theme={ButtonThemes.cancel}
                />
                <Button
                  text={createNewLayout ? 'Create Layout' : 'Apply Layout'}
                  onClick={() => applyLayout()}
                  showLoading={buttonLoading}
                  loadingText={createNewLayout ? 'Creating' : 'Applying'}
                />
              </ButtonContainer>
            </ButtonsRowContainer>
          </ModalContentContainer>
          {layoutError && layoutError !== '' && (
            <Error errorMessage={layoutError} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const SelectedMembers = styled.div`
  text-align: left;
`;

const LinkedLabel = styled.span`
  font-size: 11px;
  display: inline-block;
  background: ${colors.lightGrey};
  margin-right: 5px;
  margin-bottom: 3px;
  padding: 3px 5px;
  border-radius: 2px;
`;

const MemberAge = styled.span`
  margin-left: 4px;
  color: ${colors.paleGrey};
  font-size: 10px;
`;

const SubText = styled.p`
  margin: -5px 0 5px 0;
  font-size: 12px;
  color: ${colors.paleGrey};
  font-weight: ${fonts.light};
`;

const ModalText = styled.label`
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  color: ${colors.darkGrey};
  text-align: left;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  input {
    margin: 0 8px 0 0;
  }
`;

const TextInputContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  input {
    font-size: 13px;
  }
`;

const SelectContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  text-align: left;
  flex-direction: column;
  width: 100%;
  div {
    div {
      div {
        font-size: 13px;
      }
    }
  }
`;

export const SelectName = styled.span`
  font-weight: ${fonts.semiBold};
  color: ${colors.darkGrey};
`;

export const SelectDescription = styled.span`
  font-weight: ${fonts.regular};
  margin-left: 8px;
  font-size: 11px;
  color: ${colors.paleGrey};
  max-width: 180px;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

LayoutsModal.propTypes = {
  hide: PropTypes.func,
  householdId: PropTypes.string,
  selectedMembers: PropTypes.array,
  isConsumer: PropTypes.bool,
};

export default LayoutsModal;
