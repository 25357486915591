import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

/*
  Route takes in desired component to render, permission boolean and household id
  - Checks if user is logged in, if not they are redirect to /
  - Checks if user has passed in permission, if they do they can proceed to route
  - Checks if user is consumer and passed to component
  - If they don't have permission they are redirected to provided path or else to household summary
*/
const HouseholdPermissionRoute = ({
  children,
  hasPermission,
  redirectPath = null,
}) => {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const params = useParams();

  return hasPermission !== null ? (
    loggedIn && hasPermission ? (
      children
    ) : (
      <Navigate
        to={redirectPath ? redirectPath : `/household/${params.id}/summary`}
      />
    )
  ) : (
    !loggedIn && <Navigate to="/" />
  );
};

HouseholdPermissionRoute.propTypes = {
  children: PropTypes.object,
  hasPermission: PropTypes.bool,
  redirectPath: PropTypes.string,
};

export default HouseholdPermissionRoute;
