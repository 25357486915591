/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { InstrumentModal } from '../../components';
import {
  createInstrument,
  createProposalInstrument,
  updateInstrument,
  cloneInstrument,
  toggleShowDeleteModal,
  getInstrumentIntegrationInfo,
  updateProposalInstrument,
  syncHouseholdModal,
  clearInstrumentInfo,
  updateInstrumentStoreValue,
} from '../../store/actions';
import {
  modalTabs,
  CheckHasError,
  GenerateError,
  integrationOptions,
  MatchSelectItem,
  UseTransformOwnersList,
  UseTransformBeneficiaryList,
  UseTransformContingentBeneficiaryList,
  UseHandleInstrumentError,
  UseSetInstrumentIntegration,
  UseHandleInstrumentIntegrationError,
  UseGenerateMembersList,
  UseGenerateAssetsList,
  TransformOwners,
  TransformBeneficiaries,
  TransformContingentBeneficiaries,
  PruneObjectNulls,
  MemberOption,
  BeneficiaryOption,
  InsuredOption,
  AssetsOption,
  DetectEnterKeyPress,
  ContingentBeneficiaryOption,
  UseFetchInstrumentIntegrationContent,
  UseSyncIntegrationContent,
  DateNow,
  DateFormat,
  FilterMembersByContext,
  TransformInsured,
} from '../../utils';

const InsuranceModal = ({
  viewOnly,
  hide,
  isNew,
  householdId,
  showProposalMode,
  proposalId,
  defaultTab,
  currency,
  invisible,
  isConsumer,
}) => {
  const dispatch = useDispatch();
  const {
    instrument,
    loadedInstrumentFromAPI,
    instrumentIntegration,
    clonedInstrument,
    clearedInstrumentInfo,
    instrumentIntegrationError,
    instrumentError,
    currentHousehold,
    loadedHouseholdFields,
    insuranceCategories,
    insuranceAmountPeriod,
    insuranceBenefitPeriodTypes,
    insurancePlannedPremiumIntervals,
    insuranceSubcategories,
    assetRiskPreferences,
    preferences,
    memberIntegrationsInfo,
    householdIntegrationsInfo,
    justSynced,
    modalState,
  } = useSelector((state) => ({
    instrument: state.instruments.instrument,
    loadedInstrumentFromAPI: state.instruments.loadedInstrumentFromAPI,
    instrumentIntegration: state.instruments.instrumentIntegration,
    clonedInstrument: state.instruments.clonedInstrument,
    clearedInstrumentInfo: state.instruments.clearedInstrumentInfo,
    instrumentIntegrationError: state.instruments.instrumentIntegrationError,
    instrumentError: state.instruments.instrumentError,
    currentHousehold: state.households.currentHousehold,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
    insuranceCategories: state.configs.insuranceCategories,
    insuranceAmountPeriod: state.configs.insuranceAmountPeriod,
    insuranceBenefitPeriodTypes: state.configs.insuranceBenefitPeriodTypes,
    insurancePlannedPremiumIntervals:
      state.configs.insurancePlannedPremiumIntervals,
    insuranceSubcategories: state.configs.insuranceSubcategories,
    assetRiskPreferences: state.configs.assetRiskPreferences,
    preferences: state.user.user.preferences,
    memberIntegrationsInfo: state.user?.user?.integrations,
    householdIntegrationsInfo: state.households.currentHousehold.integrations,
    justSynced: state.households.justSynced,
    modalState: state.households.modalState,
  }));
  const [activeTab, setActiveTab] = useState('essentials');
  const [modalTabOptions, setModalTabOptions] = useState(modalTabs);
  const [loadedInstrument, setLoadedInstrument] = useState(false);
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [hasInsured, setHasInsured] = useState(false);
  const [insuredList, setInsuredList] = useState([]);
  const [hasOwners, setHasOwners] = useState(false);
  const [ownersList, setOwnersList] = useState([]);
  const [hasAssets, setHasAssets] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [hasBeneficiary, setHasBeneficiary] = useState(false);
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [hasContingentBeneficiary, setHasContingentBeneficiary] =
    useState(false);
  const [contingentBeneficiaryList, setContingentBeneficiaryList] = useState(
    []
  );
  const [isHidden, setIsHidden] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [integrationContent, setIntegrationContent] = useState();
  const [integrationError, setIntegrationError] = useState();
  const [loadingIntegrationContent, setLoadingIntegrationContent] =
    useState(true);
  const defaultInsurance = {
    owners: [],
    beneficiaries: [],
    contingent_beneficiaries: [],
    insured: [],
    name: '',
    source: '',
    amount: '',
    deductible: '',
    term_of_coverage: '',
    cash_value: '',
    surrender_value: '',
    amount_period: '',
    is_joint: false,
    is_managed: false,
    is_hsa_eligible: false,
    is_tax_qualified: false,
    as_of_date: '',
    nickname: '',
    is_future_value: false,
    planned_premium: '',
    risk_preference: { label: '0: Unspecified', value: '0' },
    policy_date: '',
    contribution_date: '',
    planned_premium_interval: '',
    conversion_date: '',
    end_date: '',
    notes: '',
  };
  const [currentInsurance, setCurrentInsurance] = useState(defaultInsurance);
  const [membersList, setMembersList] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [disabledFields, setDisabledFields] = useState([]);
  const [modalError, setModalError] = useState('');
  const [modalErrors, setModalErrors] = useState();
  const [errorTitle, setErrorTitle] = useState();
  const [showSyncButton, setShowSyncButton] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [lastHouseholdSync, setLastHouseholdSync] = useState();
  const [loadedMembers, setLoadedMembers] = useState(false);

  const getIntegrationInfo = () => {
    return dispatch(
      getInstrumentIntegrationInfo(
        currentHousehold.id,
        currentInsurance.id,
        activeTab
      )
    );
  };

  useEffect(() => {
    if (clearedInstrumentInfo) {
      resetInstrument();
      dispatch(updateInstrumentStoreValue('clearedInstrumentInfo', false));
    }
  }, [clearedInstrumentInfo]);

  useEffect(() => {
    if (insuranceCategories) {
      setInsuranceTypes(insuranceCategories);
    }
  }, [insuranceCategories]);

  useEffect(() => {
    if (isNew && loadedHouseholdFields) {
      setLoadedInstrument(true);
      setSelectedType(insuranceTypes[0]);
      if (membersList && membersList.length !== 0) {
        setCurrentInsurance({
          ...defaultInsurance,
          owners: [
            ...FilterMembersByContext(membersList, 'instrument_policy_owner'),
          ],
          beneficiaries: [
            ...FilterMembersByContext(membersList, 'instrument_beneficiary'),
          ],
          contingent_beneficiaries: [
            ...FilterMembersByContext(
              membersList,
              'instrument_contingent_beneficiary'
            ),
          ],
          insured: [
            ...FilterMembersByContext(membersList, 'instrument_insured'),
          ],
          benefit_period_type: insuranceBenefitPeriodTypes[0],
          amount_period: MatchSelectItem(insuranceAmountPeriod, '0', true),
          subcategory: insuranceSubcategories[0],
          planned_premium_interval: insurancePlannedPremiumIntervals[0],
          risk_preference: assetRiskPreferences[0],
        });
      }
    }
  }, [isNew, membersList, loadedHouseholdFields, clearedInstrumentInfo]);

  useEffect(() => {
    if (selectedType && selectedType.hasOwnProperty('value') && isNew) {
      if (selectedType.value === 'disability' || selectedType.value === 'ltc') {
        setCurrentInsurance({
          ...currentInsurance,
          amount_period: MatchSelectItem(insuranceAmountPeriod, '12', true),
        });
      }
      if (
        selectedType.value === 'medical' ||
        selectedType.value === 'otherinsurance'
      ) {
        setCurrentInsurance({
          ...currentInsurance,
          amount_period: MatchSelectItem(insuranceAmountPeriod, '0', true),
        });
      }
    }
  }, [selectedType]);

  useEffect(() => {
    if (currentInsurance.insured && currentInsurance.insured.length !== 0) {
      const hasMatched = currentInsurance.insured.some(
        (member) => member.isInsured
      );
      const selectedInsured = currentInsurance.insured.filter(
        (member) => member.isInsured
      );
      setInsuredList(selectedInsured);
      setHasInsured(hasMatched);
      setModalError('');
      setModalErrors();
    }
  }, [currentInsurance.insured]);

  useEffect(() => {
    if (assetsList && assetsList.length !== 0) {
      let hasMatched = assetsList.some((asset) => asset.isSelected);
      setSelectedAssets(assetsList.filter((asset) => asset.isSelected));
      setHasAssets(hasMatched);
    }
  }, [assetsList]);

  const transformAssets = (assets) => {
    return assetsList.map((asset) => {
      assets.map((item) => {
        if (item === asset.id) {
          asset.isSelected = true;
        }
        return item;
      });
      return asset;
    });
  };

  useEffect(() => {
    if (!isNew && loadedMembers && instrument) {
      if (instrument && instrument.hasOwnProperty('id')) {
        if (instrument?.is_proposal) {
          setModalTabOptions([
            ...modalTabs,
            { label: 'Drafts', value: 'proposals' },
          ]);
        } else if (
          instrument.hasOwnProperty('integrations') &&
          !showProposalMode
        ) {
          if (instrument.integrations.hasOwnProperty('available')) {
            const householdIntegrations = instrument.integrations.available;
            let integrationTabs = [];
            if (householdIntegrations.length !== 0 && memberIntegrationsInfo) {
              integrationTabs = householdIntegrations.reduce((acc, curr) => {
                if (memberIntegrationsInfo[curr]) {
                  let integrationName = curr;
                  let matchedIntegration = integrationOptions.find(
                    (int) => int.id === curr
                  );
                  if (matchedIntegration) {
                    integrationName = matchedIntegration.name;
                    setSelectedIntegration(matchedIntegration);
                  }
                  return [
                    ...acc,
                    {
                      label: integrationName,
                      value: curr,
                      icon: matchedIntegration?.icon
                        ? matchedIntegration?.icon
                        : false,
                    },
                  ];
                }
                return acc;
              }, []);
            }
            setModalTabOptions([...modalTabs, ...integrationTabs]);
          }
          if (
            instrument.integrations.hasOwnProperty('read_only_fields') &&
            instrument.integrations.read_only_fields.length !== 0
          ) {
            setDisabledFields(instrument.integrations.read_only_fields);
          } else {
            setDisabledFields([]);
          }
        }
        setCurrentInsurance({
          ...PruneObjectNulls(instrument),
          benefit_period_type: MatchSelectItem(
            insuranceBenefitPeriodTypes,
            instrument.benefit_period_type
          ),
          amount_period: MatchSelectItem(
            insuranceAmountPeriod,
            instrument.amount_period,
            true
          ),
          subcategory: MatchSelectItem(
            insuranceSubcategories,
            instrument.subcategory
          ),
          planned_premium_interval: MatchSelectItem(
            insurancePlannedPremiumIntervals,
            instrument.planned_premium_interval,
            true
          ),
          risk_preference: MatchSelectItem(
            assetRiskPreferences,
            instrument.risk_preference
          ),
          owners: TransformOwners(
            FilterMembersByContext(membersList, 'instrument_policy_owner'),
            instrument.members
          ),
          beneficiaries: TransformBeneficiaries(
            FilterMembersByContext(membersList, 'instrument_beneficiary'),
            instrument.members
          ),
          contingent_beneficiaries: TransformContingentBeneficiaries(
            [
              ...FilterMembersByContext(
                membersList,
                'instrument_contingent_beneficiary'
              ),
            ],
            [...instrument.members]
          ),
          insured: TransformInsured(
            FilterMembersByContext(membersList, 'instrument_insured'),
            instrument.members
          ),
        });
        setAssetsList(transformAssets(instrument.assets));
        setSelectedType(MatchSelectItem(insuranceTypes, instrument.category));
        setIsHidden(instrument.is_hidden);
        setLoadedInstrument(loadedInstrumentFromAPI);
        if (defaultTab) {
          setActiveTab(defaultTab);
        }
      }
    }
  }, [instrument, memberIntegrationsInfo, loadedMembers]);

  const resetInstrument = () => {
    setCurrentInsurance(defaultInsurance);
    setActiveTab('essentials');
    setIsHidden(false);
    setSelectedType();
    setModalError('');
    setModalErrors();
    setModalTabOptions(modalTabs);
    setDisabledFields([]);
    setLoadedInstrument(false);
    setHasInsured(false);
    setHasOwners(false);
    setHasBeneficiary(false);
    setHasContingentBeneficiary(false);
    setHasAssets(false);
    setMembersList(
      membersList.map((member) => {
        member.isSelected = false;
        member.isBeneficiary = false;
        member.isContingentBeneficiary = false;
        member.isInsured = false;
        delete member.ratio;
        return member;
      })
    );
    setOwnersList([]);
    setInsuredList([]);
    setBeneficiaryList([]);
    setAssetsList(
      assetsList.map((asset) => {
        asset.isSelected = false;
        return asset;
      })
    );
    setIntegrationContent();
    // setSelectedIntegration();
    // setIntegrationError();
    // setLoadingIntegrationContent(true);
    // setSelectedAssets([]);
    // setInsuranceTypes([]);
    // setButtonLoading(false);
    // setErrorTitle();
    // setShowSyncButton(false);
    // setLastHouseholdSync();
    // setIsSyncing(false);
  };

  UseGenerateMembersList(
    currentHousehold,
    setMembersList,
    true,
    setLoadedMembers
  );
  UseTransformOwnersList(
    currentInsurance,
    isNew,
    setOwnersList,
    setHasOwners,
    setModalError,
    setModalErrors
  );
  UseTransformBeneficiaryList(
    currentInsurance,
    isNew,
    setBeneficiaryList,
    setHasBeneficiary,
    setModalError,
    setModalErrors
  );
  UseTransformContingentBeneficiaryList(
    currentInsurance,
    isNew,
    setContingentBeneficiaryList,
    setHasContingentBeneficiary,
    setModalError,
    setModalErrors
  );
  UseHandleInstrumentError(
    instrumentError,
    setButtonLoading,
    setModalError,
    setModalErrors,
    setErrorTitle,
    setLoadedInstrument
  );
  UseSetInstrumentIntegration(
    instrumentIntegration,
    setLoadingIntegrationContent,
    setIntegrationContent
  );
  UseFetchInstrumentIntegrationContent(
    modalTabs,
    activeTab,
    integrationOptions,
    setSelectedIntegration,
    setLoadingIntegrationContent,
    getIntegrationInfo,
    setIntegrationContent
  );
  UseHandleInstrumentIntegrationError(
    instrumentIntegrationError,
    setLoadingIntegrationContent,
    setIntegrationError
  );
  UseGenerateAssetsList(currentHousehold, setAssetsList, currency);
  UseSyncIntegrationContent(
    activeTab,
    setShowSyncButton,
    householdIntegrationsInfo,
    setLastHouseholdSync
  );

  useEffect(() => {
    return () => {
      dispatch(clearInstrumentInfo());
    };
  }, []);

  useEffect(() => {
    if (justSynced) {
      setIsSyncing(false);
    }
  }, [justSynced]);

  const saveInsurance = () => {
    setErrorTitle();
    if (selectedType === undefined) {
      setModalErrors({ category: ['Please select a Insurance Type.'] });
      return setModalError('Please select a Insurance Type.');
    }
    let isJoint = currentInsurance?.is_joint;
    let members = [];
    let insured = currentInsurance?.insured.filter(
      (member) => member.isInsured
    );
    if (insured.length === 1) {
      isJoint = false;
    }
    let mappedInsured = insured.map((owner) => {
      return {
        id: owner?.id,
        ratio: 0,
        relation: 'insured',
      };
    });
    if (mappedInsured.length === 0) {
      setModalErrors({
        insured: ['Please select an Insured Member.'],
      });
      return setModalError('Please enter a Insured Member.');
    }

    let as_of_date = DateFormat(undefined, 'YYYY-MM-DD');
    if (currentInsurance.as_of_date !== '') {
      as_of_date = DateFormat(currentInsurance.as_of_date, 'YYYY-MM-DD');
    }
    let subcategory = '';
    if (
      currentInsurance.subcategory &&
      currentInsurance.subcategory.hasOwnProperty('value')
    ) {
      subcategory = currentInsurance.subcategory.value;
    }

    let policy_date = null;
    if (
      currentInsurance.policy_date !== '' &&
      currentInsurance.policy_date !== null
    ) {
      policy_date = DateFormat(currentInsurance.policy_date, 'YYYY-MM-DD');
    }
    let conversion_date = null;
    if (
      currentInsurance.conversion_date !== '' &&
      currentInsurance.conversion_date !== null
    ) {
      conversion_date = DateFormat(
        currentInsurance.conversion_date,
        'YYYY-MM-DD'
      );
    }
    let end_date = null;
    if (
      currentInsurance.end_date !== '' &&
      currentInsurance.end_date !== null
    ) {
      end_date = DateFormat(currentInsurance.end_date, 'YYYY-MM-DD');
    }
    let benefit_period_type;
    if (
      currentInsurance.benefit_period_type &&
      currentInsurance.benefit_period_type.hasOwnProperty('value')
    ) {
      benefit_period_type = currentInsurance.benefit_period_type.value;
    }
    let benefit_period = null;
    if (currentInsurance.benefit_period !== '') {
      benefit_period = parseInt(currentInsurance.benefit_period);
    }
    let amount_period;
    if (
      currentInsurance.amount_period &&
      currentInsurance.amount_period.hasOwnProperty('value')
    ) {
      amount_period = currentInsurance.amount_period.value;
    }
    let assets = [];
    let selectedAssets = assetsList.filter((asset) => asset.isSelected);
    if (selectedAssets.length !== 0) {
      let mappedAssets = selectedAssets.map((asset) => asset.id);
      assets = [...mappedAssets];
    }
    let planned_premium;
    if (currentInsurance.planned_premium !== '') {
      planned_premium = parseInt(currentInsurance.planned_premium);
    }
    let cola;
    if (currentInsurance.cola !== '') {
      cola = parseInt(currentInsurance.cola);
    }
    let elimination_days;
    if (currentInsurance.elimination_days !== '') {
      elimination_days = parseInt(currentInsurance.elimination_days);
    }

    let owners = currentInsurance.owners.filter((member) => member.isSelected);
    let mappedOwners = owners.map((owner) => {
      return {
        id: owner.id,
        ratio: owner.ratio === '' ? 0 : owner.ratio,
        relation: 'owner',
      };
    });
    let mappedBeneficiaries = [];
    let mappedContingentBeneficiaries = [];
    //Only save beneficiary members if visible
    if (checkVisibility('beneficiaries')) {
      const beneficiaries = currentInsurance.beneficiaries.filter(
        (member) => member.isBeneficiary
      );
      mappedBeneficiaries = beneficiaries.map((member) => {
        return {
          id: member.id,
          ratio: member.beneficiary_ratio === '' ? 0 : member.beneficiary_ratio,
          relation: 'beneficiary',
        };
      });
      if (mappedBeneficiaries.length > 0) {
        const beneficiaryRatioTotal = mappedBeneficiaries.reduce(
          (acc, curr) => {
            return acc + parseInt(curr.ratio);
          },
          0
        );
        if (beneficiaryRatioTotal > 100) {
          setModalErrors({
            beneficiaries: ['Percentage cannot be greater than 100.'],
          });
          return setModalError('Percentage cannot be greater than 100.');
        }
      }
      const contingentBeneficiaries =
        currentInsurance.contingent_beneficiaries.filter(
          (member) => member.isContingentBeneficiary
        );
      mappedContingentBeneficiaries = contingentBeneficiaries.map((member) => {
        return {
          id: member.id,
          ratio:
            member.contingent_beneficiary_ratio === ''
              ? 0
              : member.contingent_beneficiary_ratio,
          relation: 'contingent_beneficiary',
        };
      });
      if (mappedContingentBeneficiaries.length > 0) {
        const contingentBeneficiaryRatioTotal =
          mappedContingentBeneficiaries.reduce((acc, curr) => {
            return acc + parseInt(curr.ratio);
          }, 0);
        if (contingentBeneficiaryRatioTotal > 100) {
          setModalErrors({
            contingent_beneficiaries: [
              'Percentage cannot be greater than 100.',
            ],
          });
          return setModalError('Percentage cannot be greater than 100.');
        }
      }
    }
    members = [
      ...mappedInsured,
      ...mappedOwners,
      ...mappedBeneficiaries,
      ...mappedContingentBeneficiaries,
    ];

    let cashValue = currentInsurance.cash_value;
    if (isNaN(currentInsurance.cash_value)) {
      cashValue = null;
    }
    let surrenderValue = currentInsurance.surrender_value;
    if (isNaN(currentInsurance.surrender_value)) {
      surrenderValue = null;
    }
    let deductible = currentInsurance.deductible;
    if (checkVisibility('deductible')) {
      if (isNaN(deductible) || deductible === '') {
        deductible = null;
      }
    } else {
      deductible = null;
    }
    let out_of_pocket_maximum = currentInsurance.out_of_pocket_maximum;
    if (checkVisibility('out_of_pocket_maximum')) {
      if (isNaN(out_of_pocket_maximum) || out_of_pocket_maximum === '') {
        out_of_pocket_maximum = null;
      }
    } else {
      out_of_pocket_maximum = null;
    }

    const insuranceObject = {
      instrument_type: 'insurance',
      category: selectedType.value,
      members,
      assets,
      is_joint: isJoint,
      name: currentInsurance.name,
      source: currentInsurance.source,
      amount: currentInsurance.amount,
      deductible,
      term_of_coverage: currentInsurance.term_of_coverage,
      cash_value: cashValue,
      surrender_value: surrenderValue,
      amount_period,
      benefit_period_type,
      benefit_period,
      is_managed: currentInsurance.is_managed,
      is_hsa_eligible: currentInsurance.is_hsa_eligible,
      is_tax_qualified: currentInsurance.is_tax_qualified,
      planned_premium,
      planned_premium_interval:
        currentInsurance?.planned_premium_interval?.value,
      as_of_date,
      is_future_value: currentInsurance.is_future_value,
      nickname: currentInsurance.nickname,
      risk_preference: currentInsurance?.risk_preference?.value,
      is_hidden: isHidden,
      out_of_pocket_maximum,
      policy_date,
      conversion_date,
      cola,
      elimination_days,
      end_date,
      subcategory,
      notes: currentInsurance.notes,
    };
    setButtonLoading(true);
    if (isNew) {
      return dispatch(
        showProposalMode
          ? createProposalInstrument(
              currentHousehold?.uuid,
              proposalId,
              insuranceObject,
              'Insurance'
            )
          : createInstrument(
              householdId,
              insuranceObject,
              'Insurance',
              isConsumer
            )
      );
    } else {
      return dispatch(
        showProposalMode
          ? updateProposalInstrument(
              currentHousehold?.uuid,
              proposalId,
              insuranceObject,
              instrument?.uuid,
              'Insurance',
              instrument?.proposal_action_data?.id
            )
          : updateInstrument(
              householdId,
              instrument.id,
              insuranceObject,
              'Insurance',
              isConsumer
            )
      );
    }
  };

  const saveOnEnter = () => {
    if (buttonLoading || activeTab === 'notes') {
      return;
    }
    return saveInsurance();
  };

  const copyInstrument = () => {
    dispatch(cloneInstrument(currentHousehold.id, instrument.id, 'Insurance'));
  };

  const syncInstrument = () => {
    setIsSyncing(true);
    dispatch(
      syncHouseholdModal(integrationContent.sync_url, currentHousehold.id)
    );
  };

  const openDeleteInsurance = () => {
    dispatch(
      toggleShowDeleteModal(
        true,
        'insurance',
        instrument.name,
        instrument.id,
        true,
        instrument
      )
    );
  };

  const ratioChange = (e, member, type) => {
    setModalError('');
    setModalErrors();
    let value = e.currentTarget.value;
    if (type === 'owner') {
      member.ratio = parseFloat(value);
    }
    if (type === 'beneficiary') {
      member.beneficiary_ratio = parseFloat(value);
    }
    if (type === 'contingent_beneficiary') {
      member.contingent_beneficiary_ratio = parseFloat(value);
    }
    setCurrentInsurance({ ...currentInsurance });
  };

  const updateInsuredMembers = (e) => {
    setCurrentInsurance({
      ...currentInsurance,
      insured: currentInsurance.insured.map((member) => {
        if (e.id === member.id) {
          member.isInsured = !member.isInsured;
        }
        return member;
      }),
    });
  };

  const updateSelectedAssets = (e) => {
    setAssetsList(
      assetsList.map((asset) => {
        if (e.id === asset.id) {
          asset.isSelected = !asset.isSelected;
        }
        return asset;
      })
    );
  };

  const updateLinkedMembers = (e) => {
    setCurrentInsurance({
      ...currentInsurance,
      owners: currentInsurance.owners.map((member) => {
        if (e.id === member.id) {
          member.isSelected = !member.isSelected;
        }
        return member;
      }),
    });
  };

  const updateBeneficiaryMembers = (
    e,
    objKey = 'beneficiaries',
    memberKey = 'isBeneficiary'
  ) => {
    setCurrentInsurance({
      ...currentInsurance,
      [objKey]: currentInsurance[objKey].map((member) => {
        if (e.id === member.id) {
          member[memberKey] = !member[memberKey];
        }
        return member;
      }),
    });
  };

  const updateCurrentInsurance = (e, isSelect = false, fieldName = null) => {
    setModalError('');
    setModalErrors();
    if (isSelect) {
      setCurrentInsurance({
        ...currentInsurance,
        [fieldName]: e,
      });
    } else {
      setCurrentInsurance({
        ...currentInsurance,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const generateLabel = (field) => {
    if (field === 'amount') {
      if (selectedType) {
        switch (selectedType.value) {
          case 'lifeterm':
          case 'lifeul':
          case 'lifewhole':
          case 'lifevul':
            return 'Death Benefit';
          case 'disability':
          case 'ltc':
          case 'medical':
          case 'otherinsurance':
            return 'Benefit';
          case 'propcas':
            return 'Coverage Amount';
          default:
            return 'Death Benefit';
        }
      }
    }
    return 'Death Benefit';
  };

  const generateWidth = (field) => {
    const insuranceType = selectedType?.value;
    if (field === 'amount') {
      if (insuranceType) {
        if (
          insuranceType === 'lifeterm' ||
          insuranceType === 'ltc' ||
          insuranceType === 'disability' ||
          insuranceType === 'medical' ||
          insuranceType === 'otherinsurance'
        )
          return '48%';
      }
    }
    if (field === 'policy_date') {
      if (insuranceType) {
        if (
          insuranceType === 'lifeterm' ||
          insuranceType === 'lifewhole' ||
          insuranceType === 'lifeul' ||
          insuranceType === 'lifevul' ||
          insuranceType === 'medical' ||
          insuranceType === 'otherinsurance'
        )
          return '48%';
      }
    }
    if (field === 'deductible') {
      if (insuranceType) {
        if (insuranceType === 'medical' || insuranceType === 'otherinsurance')
          return '48%';
      }
    }
    if (field === 'amount_period') {
      if (insuranceType) {
        if (
          insuranceType === 'ltc' ||
          insuranceType === 'disability' ||
          insuranceType === 'medical' ||
          insuranceType === 'otherinsurance'
        ) {
          return '48%';
        } else {
          return '36%';
        }
      }
    }
    if (field === 'benefit_period_type') {
      if (insuranceType) {
        if (insuranceType === 'ltc' || insuranceType === 'disability') {
          return '48%';
        } else {
          return '26%';
        }
      }
    }
    if (field === 'benefit_period') {
      if (insuranceType) {
        if (insuranceType === 'ltc' || insuranceType === 'disability') {
          return '48%';
        } else {
          return '32%';
        }
      }
    }
    return '100%';
  };

  const checkVisibility = (field) => {
    const insuranceType = selectedType?.value;
    if (insuranceType) {
      if (
        insuranceType === 'lifeterm' ||
        insuranceType === 'lifeul' ||
        insuranceType === 'lifewhole' ||
        insuranceType === 'lifevul' ||
        insuranceType === 'propcas'
      ) {
        if (field === 'members') {
          return true;
        }
        if (field === 'is_joint') {
          if (currentInsurance.insured && currentInsurance.insured.length > 1) {
            let selected = currentInsurance.insured.filter((owner) => {
              return owner.isInsured;
            });
            if (selected.length > 1) {
              return true;
            }
          }
        }
      }
      if (
        field === 'benefit_period_type' ||
        field === 'amount_period' ||
        field === 'benefit_period' ||
        field === 'cola' ||
        field === 'elimination_days'
      ) {
        if (insuranceType === 'disability' || insuranceType === 'ltc') {
          return true;
        }
      }
      if (
        field === 'cash_value' ||
        field === 'surrender_value' ||
        field === 'conversion_date'
      ) {
        if (
          insuranceType === 'lifeul' ||
          insuranceType === 'lifewhole' ||
          insuranceType === 'lifevul'
        ) {
          return true;
        }
      }
      if (field === 'term_of_coverage' || field === 'conversion_date') {
        if (insuranceType === 'lifeterm') {
          return true;
        }
      }
      if (field === 'assets') {
        if (insuranceType === 'propcas') {
          return true;
        }
      }
      if (field === 'beneficiaries') {
        if (
          insuranceType !== 'propcas' &&
          insuranceType !== 'medical' &&
          insuranceType !== 'otherinsurance'
        ) {
          return true;
        }
      }
      if (field === 'risk_preference') {
        if (insuranceType === 'lifevul') {
          return true;
        }
      }
      if (field === 'is_tax_qualified') {
        if (insuranceType === 'ltc') {
          return true;
        }
      }
      if (field === 'end_date') {
        if (
          insuranceType === 'lifeterm' ||
          insuranceType === 'lifewhole' ||
          insuranceType === 'lifeul'
        ) {
          return true;
        }
      }
      if (field === 'deductible') {
        if (
          insuranceType === 'propcas' ||
          insuranceType === 'medical' ||
          insuranceType === 'otherinsurance'
        ) {
          return true;
        }
      }
      if (field === 'nickname') {
        if (insuranceType !== 'medical' && insuranceType !== 'otherinsurance') {
          return true;
        }
      }
      if (field === 'is_future_value') {
        if (insuranceType !== 'medical' && insuranceType !== 'otherinsurance') {
          return true;
        }
      }
      if (field === 'amount_period') {
        if (insuranceType === 'medical' || insuranceType === 'otherinsurance') {
          return true;
        }
      }
      if (field === 'out_of_pocket_maximum') {
        if (insuranceType === 'medical' || insuranceType === 'otherinsurance') {
          return true;
        }
      }
      if (field === 'is_hsa_eligible') {
        if (insuranceType === 'medical') {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  };

  const isDisabled = (input) => {
    if (viewOnly) {
      return true;
    } else if (!isNew && disabledFields.length !== 0) {
      return disabledFields.includes(input);
    }
    return false;
  };

  const checkRequired = (input) => {
    const insuranceType = selectedType?.value;
    if (input === 'benefit_period' && insuranceType === 'disability') {
      return true;
    }
    if (input === 'amount') {
      if (insuranceType !== 'medical' && insuranceType !== 'otherinsurance') {
        return true;
      }
    }
    return false;
  };

  const formatFunction = (values, input) => {
    setModalError('');
    setModalErrors();
    let setValue;
    if (values.hasOwnProperty('floatValue')) {
      setValue = values.floatValue;
    } else if (values.currentTarget?.value) {
      setValue = values.currentTarget.value;
    }
    if (setValue === 'NaN') {
      setValue = '';
    }
    setCurrentInsurance({
      ...currentInsurance,
      [input.name]: setValue,
      as_of_date:
        input.name === 'amount' ? DateNow() : currentInsurance.as_of_date,
    });
  };

  // ESSENTIALS - WIDTHS
  // Out-of-pocket maximum

  const essentialInputs = [
    {
      type: 'select',
      label: 'Insurance Type',
      name: 'insurance_type',
      required: true,
      placeholder: 'Insurance Type',
      value: selectedType,
      options: insuranceTypes,
      width: '100%',
      isVisible: true,
      disabled: isDisabled('insurance_type'),
      onChange: (e) => setSelectedType(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'category'),
      errorMessage: GenerateError(modalErrors, 'category'),
    },
    {
      type: 'select',
      label: 'Insured Members',
      customSelect: true,
      customComponent: InsuredOption,
      name: 'insured',
      required: true,
      placeholder: 'Insured members',
      value: { label: 'Select Members', value: '' },
      options: currentInsurance.insured,
      showSelected: hasInsured,
      hasSelected: true,
      width: '100%',
      isVisible: true,
      memberType: 'insured',
      selectedOptions: insuredList,
      disabled: isDisabled('owner'),
      onChange: (e) => updateInsuredMembers(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'insured'),
      errorMessage: GenerateError(modalErrors, 'insured'),
    },
    {
      type: 'select',
      label: 'Covered Asset(s)',
      customSelect: true,
      customComponent: AssetsOption,
      name: 'assets',
      required: false,
      hasSelected: true,
      placeholder: 'Covered Asset(s)',
      value: { label: 'Select Assets', value: '' },
      options: assetsList,
      showSelected: hasAssets,
      width: '100%',
      isVisible: checkVisibility('assets'),
      disabled: isDisabled('assets'),
      memberType: 'assets',
      selectedOptions: selectedAssets,
      onChange: (e) => updateSelectedAssets(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'assets'),
      errorMessage: GenerateError(modalErrors, 'assets'),
    },
    {
      type: 'checkbox',
      label: 'Joint Ownership',
      name: 'is_joint',
      required: false,
      width: '100%',
      value: currentInsurance.is_joint,
      isVisible: checkVisibility('is_joint'),
      disabled: isDisabled('is_joint'),
      onChange: () =>
        setCurrentInsurance({
          ...currentInsurance,
          is_joint: !currentInsurance.is_joint,
        }),
    },
    {
      type: 'text',
      label: 'Reference Name',
      maxLength: '80',
      name: 'name',
      width: '48%',
      required: true,
      placeholder: 'e.g. Life Term 20',
      isVisible: true,
      disabled: isDisabled('name'),
      value: currentInsurance.name,
      onChange: (e) => updateCurrentInsurance(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'name'),
      errorMessage: GenerateError(modalErrors, 'name'),
    },
    {
      type: 'text',
      label: 'Insurance Carrier',
      maxLength: '80',
      name: 'source',
      width: '48%',
      required: true,
      placeholder: 'e.g. InsurCo, LLC.',
      isVisible: true,
      disabled: isDisabled('source'),
      value: currentInsurance.source,
      onChange: (e) => updateCurrentInsurance(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'source'),
      errorMessage: GenerateError(modalErrors, 'source'),
    },
    {
      type: 'calculatorCurrency',
      label: generateLabel('amount'),
      maxLength: '80',
      name: 'amount',
      width: generateWidth('amount'),
      required: checkRequired('amount'),
      placeholder: generateLabel('amount'),
      value: currentInsurance.amount,
      isVisible: true,
      disabled: isDisabled('amount'),
      onChange: (e, input) => formatFunction(e, input),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'amount'),
      errorMessage: GenerateError(modalErrors, 'amount'),
    },
    {
      type: 'select',
      label: 'Benefit interval',
      name: 'amount_period',
      required: false,
      placeholder: 'Benefit Interval',
      value: currentInsurance.amount_period,
      options: insuranceAmountPeriod,
      width: generateWidth('amount_period'),
      isVisible: checkVisibility('amount_period'),
      disabled: isDisabled('amount_period'),
      onChange: (e) => updateCurrentInsurance(e, true, 'amount_period'),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'amount_period'),
      errorMessage: GenerateError(modalErrors, 'amount_period'),
    },
    {
      type: 'calculatorCurrency',
      label: 'Deductible',
      maxLength: '80',
      name: 'deductible',
      width: generateWidth('deductible'),
      required: false,
      placeholder: 'Deductible',
      value: currentInsurance.deductible,
      isVisible: checkVisibility('deductible'),
      disabled: isDisabled('deductible'),
      onChange: (e, input) => formatFunction(e, input),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'deductible'),
      errorMessage: GenerateError(modalErrors, 'deductible'),
    },
    {
      type: 'text',
      label: 'Term of Coverage',
      maxLength: '80',
      name: 'term_of_coverage',
      width: '48%',
      required: false,
      placeholder: 'Term of Coverage',
      value: currentInsurance.term_of_coverage,
      isVisible: checkVisibility('term_of_coverage'),
      disabled: isDisabled('term_of_coverage'),
      onChange: (e) => updateCurrentInsurance(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'term_of_coverage'),
      errorMessage: GenerateError(modalErrors, 'term_of_coverage'),
    },
    {
      type: 'select',
      label: 'Policy Owner(s)',
      maxLength: '80',
      customSelect: true,
      customComponent: MemberOption,
      name: 'members',
      width: '100%',
      required: false,
      placeholder: 'Policy Owner(s)',
      value: { label: 'Select Members', value: '' },
      options: currentInsurance.owners,
      showSelected: hasOwners,
      hasSelected: true,
      ratioKey: 'ratio',
      selectedOptions: ownersList,
      memberType: 'owner',
      isVisible: checkVisibility('members'),
      disabled: isDisabled('members'),
      optionOnChange: ratioChange,
      onChange: (e) => updateLinkedMembers(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'owners'),
      errorMessage: GenerateError(modalErrors, 'owners'),
    },
    {
      type: 'calculatorCurrency',
      label: 'Cash Value',
      maxLength: '80',
      name: 'cash_value',
      width: '48%',
      required: false,
      placeholder: 'Cash Value',
      value: currentInsurance.cash_value,
      isVisible: checkVisibility('cash_value'),
      disabled: isDisabled('cash_value'),
      onChange: (e, input) => formatFunction(e, input),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'cash_value'),
      errorMessage: GenerateError(modalErrors, 'cash_value'),
    },
    {
      type: 'calculatorCurrency',
      label: 'Surrender Value',
      maxLength: '80',
      name: 'surrender_value',
      width: '48%',
      required: false,
      placeholder: 'Surrender Value',
      value: currentInsurance.surrender_value,
      isVisible: checkVisibility('surrender_value'),
      disabled: isDisabled('surrender_value'),
      onChange: (e, input) => formatFunction(e, input),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'surrender_value'),
      errorMessage: GenerateError(modalErrors, 'surrender_value'),
    },
    {
      type: 'select',
      label: 'Benefit Period',
      name: 'benefit_period_type',
      required: false,
      placeholder: 'Benefit Period',
      value: currentInsurance.benefit_period_type,
      options: insuranceBenefitPeriodTypes,
      width: generateWidth('benefit_period_type'),
      isVisible: checkVisibility('benefit_period_type'),
      disabled: isDisabled('benefit_period_type'),
      onChange: (e) => updateCurrentInsurance(e, true, 'benefit_period_type'),
      hasError: CheckHasError(modalErrors, 'benefit_period_type'),
      onEnter: saveOnEnter,
      errorMessage: GenerateError(modalErrors, 'benefit_period_type'),
    },
    {
      type: 'number',
      label: 'Age/Years',
      maxLength: '80',
      name: 'benefit_period',
      width: generateWidth('benefit_period'),
      required: checkRequired('benefit_period'),
      placeholder: 'Age/Years',
      value: currentInsurance.benefit_period,
      isVisible: checkVisibility('benefit_period'),
      disabled: isDisabled('benefit_period'),
      onChange: (e) => updateCurrentInsurance(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'benefit_period'),
      errorMessage: GenerateError(modalErrors, 'benefit_period'),
    },
    {
      type: 'calculatorCurrency',
      label: 'Out-of-Pocket Maximum',
      maxLength: '80',
      name: 'out_of_pocket_maximum',
      width: '48%',
      required: false,
      placeholder: 'Out-of-Pocket Maximum',
      value: currentInsurance.out_of_pocket_maximum,
      isVisible: checkVisibility('out_of_pocket_maximum'),
      disabled: isDisabled('out_of_pocket_maximum'),
      onChange: (e, input) => formatFunction(e, input),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'out_of_pocket_maximum'),
      errorMessage: GenerateError(modalErrors, 'out_of_pocket_maximum'),
    },
    {
      type: 'checkbox',
      label: 'Under My Management',
      name: 'is_managed',
      required: false,
      width: '48%',
      value: currentInsurance.is_managed,
      isVisible: true,
      disabled: isDisabled('is_managed'),
      onChange: () =>
        setCurrentInsurance({
          ...currentInsurance,
          is_managed: !currentInsurance.is_managed,
        }),
      // hasError: layoutNameFieldError,
    },
    {
      type: 'checkbox',
      label: 'HSA Eligible',
      name: 'is_hsa_eligible',
      required: false,
      width: '48%',
      value: currentInsurance.is_hsa_eligible,
      isVisible: checkVisibility('is_hsa_eligible'),
      disabled: isDisabled('is_hsa_eligible'),
      onChange: () =>
        setCurrentInsurance({
          ...currentInsurance,
          is_hsa_eligible: !currentInsurance.is_hsa_eligible,
        }),
    },
    {
      type: 'checkbox',
      label: 'Tax Qualified',
      name: 'is_tax_qualified',
      required: false,
      width: '48%',
      value: currentInsurance.is_tax_qualified,
      isVisible: checkVisibility('is_tax_qualified'),
      disabled: isDisabled('is_tax_qualified'),
      onChange: () =>
        setCurrentInsurance({
          ...currentInsurance,
          is_tax_qualified: !currentInsurance.is_tax_qualified,
        }),
      // hasError: layoutNameFieldError,
    },
  ];

  const detailInputs = [
    {
      type: 'date',
      label: 'Data Collected',
      name: 'as_of_date',
      width: '100%',
      required: false,
      placeholder: preferences?.date_format.toLowerCase(),
      dateFormat: preferences?.date_format,
      value: currentInsurance.as_of_date,
      isVisible: true,
      disabled: isDisabled('as_of_date'),
      onChange: (e) => updateCurrentInsurance(e, true, 'as_of_date'),
      hasError: CheckHasError(modalErrors, 'as_of_date'),
      errorMessage: GenerateError(modalErrors, 'as_of_date'),
    },
    {
      type: 'text',
      label: 'Account Nickname',
      name: 'nickname',
      width: '48%',
      required: false,
      placeholder: 'Account Nickname',
      isVisible: checkVisibility('nickname'),
      value: currentInsurance.nickname,
      onChange: (e) => updateCurrentInsurance(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'nickname'),
      errorMessage: GenerateError(modalErrors, 'nickname'),
    },
    {
      type: 'select',
      label: 'Taxable Benefit',
      name: 'subcategory',
      required: false,
      placeholder: 'Taxable Benefit',
      value: currentInsurance.subcategory,
      options: insuranceSubcategories,
      width: '48%',
      isVisible: true,
      onChange: (e) => updateCurrentInsurance(e, true, 'subcategory'),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'subcategory'),
      errorMessage: GenerateError(modalErrors, 'subcategory'),
    },
    {
      type: 'select',
      label: 'Beneficiary',
      name: 'beneficiaries',
      required: false,
      placeholder: 'Beneficiary',
      value: { label: 'Select Members', value: '' },
      options: currentInsurance.beneficiaries,
      width: '100%',
      customSelect: true,
      hasSelected: true,
      customComponent: BeneficiaryOption,
      showSelected: hasBeneficiary,
      optionOnChange: ratioChange,
      ratioKey: 'beneficiary_ratio',
      memberType: 'beneficiary',
      selectedOptions: beneficiaryList,
      isVisible: checkVisibility('beneficiaries'),
      onChange: (e) => updateBeneficiaryMembers(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'beneficiaries'),
      errorMessage: GenerateError(modalErrors, 'beneficiaries'),
    },
    {
      type: 'select',
      label: 'Contingent Beneficiary',
      name: 'contingent_beneficiaries',
      required: false,
      placeholder: 'Contingent Beneficiary',
      value: { label: 'Select Members', value: '' },
      options: currentInsurance.contingent_beneficiaries,
      width: '100%',
      customSelect: true,
      hasSelected: true,
      customComponent: ContingentBeneficiaryOption,
      showSelected: hasContingentBeneficiary,
      optionOnChange: ratioChange,
      ratioKey: 'contingent_beneficiary_ratio',
      memberType: 'contingent_beneficiary',
      selectedOptions: contingentBeneficiaryList,
      isVisible: checkVisibility('beneficiaries'),
      onChange: (e) =>
        updateBeneficiaryMembers(
          e,
          'contingent_beneficiaries',
          'isContingentBeneficiary'
        ),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'contingent_beneficiaries'),
      errorMessage: GenerateError(modalErrors, 'contingent_beneficiaries'),
    },
    {
      type: 'calculatorCurrency',
      label: 'Planned Premium',
      name: 'planned_premium',
      width: '48%',
      required: false,
      placeholder: 'Planned Premium',
      value: currentInsurance.planned_premium,
      isVisible: true,
      onChange: (e, input) => formatFunction(e, input),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'planned_premium'),
      errorMessage: GenerateError(modalErrors, 'planned_premium'),
    },
    {
      type: 'select',
      label: 'Planned Premium Interval',
      name: 'planned_premium_interval',
      required: false,
      placeholder: 'Taxable Benefit',
      value: currentInsurance.planned_premium_interval,
      options: insurancePlannedPremiumIntervals,
      width: '48%',
      isVisible: true,
      onChange: (e) =>
        updateCurrentInsurance(e, true, 'planned_premium_interval'),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'planned_premium_interval'),
      errorMessage: GenerateError(modalErrors, 'planned_premium_interval'),
    },
    {
      type: 'date',
      label: 'Policy Date',
      name: 'policy_date',
      width: generateWidth('policy_date'),
      required: false,
      placeholder: preferences?.date_format.toLowerCase(),
      dateFormat: preferences?.date_format,
      value: currentInsurance.policy_date,
      isVisible: true,
      onChange: (e) => updateCurrentInsurance(e, true, 'policy_date'),
      hasError: CheckHasError(modalErrors, 'policy_date'),
      errorMessage: GenerateError(modalErrors, 'policy_date'),
    },
    {
      type: 'date',
      label: 'Conversion Date',
      name: 'conversion_date',
      width: '48%',
      required: false,
      placeholder: preferences?.date_format.toLowerCase(),
      dateFormat: preferences?.date_format,
      value: currentInsurance.conversion_date,
      isVisible: checkVisibility('conversion_date'),
      onChange: (e) => updateCurrentInsurance(e, true, 'conversion_date'),
      hasError: CheckHasError(modalErrors, 'conversion_date'),
      errorMessage: GenerateError(modalErrors, 'conversion_date'),
    },
    {
      type: 'number',
      label: 'COLA',
      name: 'cola',
      width: '48%',
      required: false,
      placeholder: 'COLA',
      hasSuffix: true,
      suffixValue: '%',
      value: currentInsurance.cola,
      isVisible: checkVisibility('cola'),
      onChange: (e) => updateCurrentInsurance(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'cola'),
      errorMessage: GenerateError(modalErrors, 'cola'),
    },
    {
      type: 'number',
      label: 'Elimination Period in Days',
      name: 'elimination_days',
      width: '48%',
      required: false,
      placeholder: 'Elimination Period',
      hasSuffix: true,
      suffixValue: 'days',
      value: currentInsurance.elimination_days,
      isVisible: checkVisibility('elimination_days'),
      onChange: (e) => updateCurrentInsurance(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'elimination_days'),
      errorMessage: GenerateError(modalErrors, 'elimination_days'),
    },
    {
      type: 'select',
      label: 'Risk Profile',
      name: 'risk_preference',
      required: false,
      placeholder: 'Risk Profile',
      value: currentInsurance.risk_preference,
      options: assetRiskPreferences,
      width: '100%',
      isVisible: checkVisibility('risk_preference'),
      onChange: (e) => updateCurrentInsurance(e, true, 'risk_preference'),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'risk_preference'),
      errorMessage: GenerateError(modalErrors, 'risk_preference'),
    },
    {
      type: 'date',
      label: 'End Date',
      name: 'end_date',
      width: '48%',
      required: false,
      placeholder: preferences?.date_format.toLowerCase(),
      dateFormat: preferences?.date_format,
      value: currentInsurance.end_date,
      isVisible: checkVisibility('end_date'),
      onChange: (e) => updateCurrentInsurance(e, true, 'end_date'),
      hasError: CheckHasError(modalErrors, 'end_date'),
      errorMessage: GenerateError(modalErrors, 'end_date'),
    },
    {
      type: 'checkbox',
      label: 'Future Valued Benefit',
      name: 'is_future_value',
      required: false,
      width: '48%',
      value: currentInsurance.is_future_value,
      isVisible: checkVisibility('is_future_value'),
      onChange: () =>
        setCurrentInsurance({
          ...currentInsurance,
          is_future_value: !currentInsurance.is_future_value,
        }),
      // hasError: layoutNameFieldError,
    },
  ];

  const notesInput = {
    type: 'textarea',
    label: 'Notes',
    name: 'notes',
    showClear: true,
    id: 'insurance',
    value: currentInsurance.notes,
    onChange: (e) => updateCurrentInsurance(e, true, 'notes'),
  };

  return (
    <InstrumentModal
      name="Insurance"
      viewOnly={viewOnly}
      isLoading={!loadedInstrument}
      invisible={invisible}
      hide={hide}
      isNew={isNew}
      showProposalMode={showProposalMode}
      modalTabOptions={modalTabOptions}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      currency={currency}
      household={currentHousehold}
      essentialInputs={essentialInputs}
      detailInputs={detailInputs}
      notesInput={notesInput}
      onEnter={saveOnEnter}
      buttonContent={{
        isHidden: isHidden,
        justCopied: clonedInstrument,
        updateHidden: setIsHidden,
        deleteFunction: openDeleteInsurance,
        copyFunction: copyInstrument,
        saveFunction: saveInsurance,
        showLoading: buttonLoading,
        syncFunction: syncInstrument,
        showSync: showSyncButton,
        isSyncing: isSyncing,
      }}
      modalError={modalError}
      errorTitle={errorTitle}
      proposalContent={{
        ...instrument?.proposal_action_data,
        name: instrument?.name,
        type: 'insurance',
        membersList: membersList,
        assetList: assetsList,
      }}
      integrationContent={{
        content: integrationContent,
        loading: loadingIntegrationContent,
        integration: selectedIntegration,
        instrument: currentInsurance,
        error: integrationError,
        lastHouseholdSync: lastHouseholdSync,
      }}
      mode={modalState}
    />
  );
};

InsuranceModal.propTypes = {
  viewOnly: PropTypes.bool,
  hide: PropTypes.func,
  isNew: PropTypes.bool,
  householdId: PropTypes.string,
  showProposalMode: PropTypes.bool,
  proposalId: PropTypes.string,
  defaultTab: PropTypes.string,
  currency: PropTypes.object,
  invisible: PropTypes.bool,
  isConsumer: PropTypes.bool,
};

export default InsuranceModal;
